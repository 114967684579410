<div class="main-holder">
  <h1>BLUEASSET FS AFRICA PRIVACY POLICY</h1>

  <i
    >Your continued use of this website indicates that you have both read and
    agree to the terms of this Privacy Policy. Unfortunately, you cannot use
    this website if you do not accept this Privacy Policy.</i
  >
  <br />
  <h4>1. INTRODUCTION</h4>
  <p>
    1.1. This Privacy Policy is to be read as specifically incorporated into the
    Terms of Service, in terms of which BLUEASSET FS AFRICA (Pty) Ltd ("BLUE
    ASSET FS AFRICA") provides services to you (“Services”) available at
    <a class="weblink" href="https://www.blueassetgroup.com"
      >www.blueassetgroup.com</a
    >.
  </p>
  <p>
    1.2. This Privacy Policy applies to your use of
    <a class="weblink" href="https://www.blueassetgroup.com"
      >www.blueassetgroup.com</a
    >. For the purposes of this Privacy Policy, references to a “Website” shall
    be a reference this website.
  </p>
  <p>
    1.3. This Privacy Policy is to be read in conjunction with any data
    processing agreement entered into between you and BLUEASSET FS AFRICA. In
    the event of any conflict between this Privacy Policy and a binding data
    processing agreement, the data processing agreement shall be preferred to
    the extent of such inconsistency.
  </p>
  <p>
    1.4. Any changes to this Privacy Policy will be notified to you via email,
    and will require your consent thereto in order that can continue to provide
    our services to you.
  </p>

  <h4>2. PRIVACY POLICY</h4>
  <p>
    2.1. For this section, Personal Information will be understood under the
    definition provided in the Protection of Personal Information Act 4 of 2013
    ("POPIA"). BLUEASSET FS AFRICA also subscribes to the principles for
    electronically collecting Personal Information outlined in POPIA, and the
    further legislation referred to therein. We endeavour to ensure the quality,
    accuracy and confidentiality of Personal Information in our possession.
  </p>
  <p>
    2.2. In adopting this Privacy Policy, we wish to balance our legitimate
    business interests and your reasonable expectation of privacy. Accordingly,
    we will take all reasonable steps to prevent unauthorised access to or
    disclosure of your Personal Information.
  </p>
  <p>
    2.3. Users will be prompted to register an account with BLUEASSET FS AFRICA.
    In so doing, users may be asked, or will otherwise be required to provide,
    the following information ("Personal Information"):
  </p>
  <ul>
    <li>2.3.1 First name;</li>
    <li>2.3.2. Surname;</li>
    <li>2.3.3. Physical Address;</li>
    <li>2.3.4. Email Address;</li>
    <li>2.3.5. Cell Phone Number;</li>
    <li>2.3.6. Date of Birth;</li>
    <li>2.3.7. IP Address;</li>
    <li>2.3.8. Identity Number;</li>
    <li>
      2.3.9. Credit Card Information (which may be collected through our
      third-party payment processor);
    </li>
    <li>2.3.10. Bank Account or credit card details;</li>
    <li>
      2.3.11. Social login account login details, which may include Facebook,
      LinkedIn, Twitter;
    </li>
    <li>2.3.12. Company name and registration number; and</li>
    <li>
      2.3.13. From time to time, what may be required under your local
      territory's KYC (Know Your Customer) or Financial Intelligence Centre Act
      (FICA) processes.
    </li>
  </ul>
  <p>
    2.4. The abovementioned Personal Information shall be processed to manage
    and administer your account, including on-boarding, Know Your Customer and
    Anti-Money Laundering processes, and fraud security process, or as otherwise
    set out in this Privacy Policy.
  </p>
  <p>
    2.5. We take our users' privacy seriously. We will attempt to limit the
    types of Personal Information we process to only that to which you consent
    (for example, in the context of online registration, newsletters, message
    boards, surveys, polls, professional announcements, SMS, MMS, and other
    mobile services), but, to the extent necessary, your agreement to this
    Privacy Policy constitutes your consent. Where necessary, we will seek to
    obtain your specific consent in future instances should we deem the same
    required by law and where your support herein might not be lawfully
    sufficient.
  </p>
  <p>
    2.6. In order to effectively render our Services to you, we may share your
    Personal Information, where necessary, with website analytics services,
    identity verification and KYC/FICA partners, exchange platform and order
    book partners, payment gateways, bulk email marketing service providers,
    regulators, authorised exchange control dealers and/or other regulatory
    authorities as required by law, as well as other service providers required
    to render our Services to you.
  </p>
  <p>
    2.7. All payment/transaction information will be conducted by electronic
    funds transfer (EFT), alternatively, specific credit and debit cards
    permitted by BLUEASSET FS AFRICA from time to time. Further, BLUEASSET FS
    AFRICA shall retain a user's designated bank account details for utilisation
    regarding the user's withdrawals as and when required.
  </p>
  <p>
    2.8. We will not collect, use or disclose sensitive / special personal
    information (such as information about racial or ethnic origins or political
    or religious beliefs, where relevant) except with your specific consent or
    in the circumstances permitted by law.
  </p>
  <p>
    2.9. As per POPIA, all Personal Information will be collected and processed
    lawfully.
  </p>
  <p>
    2.10 By agreeing to the terms contained in our Privacy Policy, you consent
    to the use of your Personal Information concerning:
  </p>
  <ul>
    <li>2.10.1 The provision and performance of the Services;</li>
    <li>
      2.10.2 Informing you of changes made to BLUEASSET FS AFRICA website;
    </li>
    <li>2.10.3 Responding to any queries or requests you may have;</li>
    <li>
      2.10.4 Developing a more direct and substantial relationship with Account
      Holders for the purposes described in this clause;
    </li>
    <li>
      2.10.5 Marketing of BLUEASSET FS AFRICA Services and other related
      offerings to you;
    </li>
    <li>2.10.6 Developing an online user profile;</li>
    <li>
      2.10.7 Understanding general user trends and patterns so that BLUEASSET FS
      AFRICA can develop and support existing and ongoing marketing strategies;
    </li>
    <li>2.10.8 For security, administrative and legal purposes; and</li>
    <li>
      2.10.9 The creation and development of market data profiles may provide
      insight into market norms, practices and trends to help BLUEASSET FS
      AFRICA improve our offering to you. Such information will be compiled and
      retained in aggregated form but shall not be used in any way that may
      compromise your identity.
    </li>
  </ul>
  <h4>3. CROSS-BORDER TRANSFERS</h4>
  <p>
    3.1. In order for BLUEASSET FS AFRICA to properly perform its services, it
    may be required for BLUEASSET FS AFRICA to transfer Personal Information to
    a third party which is situated outside of South Africa.
  </p>
  <p>3.2. In this regard, we undertake to:</p>
  <ul>
    <li>
      3.2.1. ensure the third party's compliance with this Privacy Policy
      insofar as the processing of Personal Information is concerned;
    </li>
    <li>
      3.2.2 prevent such third party from further transferring the Personal
      Information to another third party;
    </li>
    <li>
      3.2.3. use reasonable endeavours to ensure that the third party has
      implemented reasonable and appropriate technical and organisational
      security measures to safeguard the Personal Information in such
      jurisdiction.
    </li>
  </ul>
  <h4>4. LOG FILES</h4>
  <p>
    As with all websites, when you visit our Website, even if you do not create
    an account, we may collect information, such as your IP address, the name of
    your ISP (Internet Service Provider), your browser, the website from which
    you visit us, the pages on our website that you visit and in what sequence,
    the date and length of your visit, and other information concerning your
    computer's operating system, language settings, and broad demographic
    information. This information is aggregated and anonymous data and does not
    identify you specifically. However, you acknowledge that this data may be
    able to identify you if it is aggregated with other Personal Information
    that you supply to us. In this regard, however, and as recorded, only with a
    user's consent and subject strictly to the terms of this Privacy Policy will
    a user's Personal Information be transferred and/or shared, and currently
    same is only being used within BLUEASSET FS AFRICA on a need-to-know basis.
    Furthermore, any individually identifiable information related to this data
    will never be used in any way different to that stated above without your
    explicit consent.
  </p>
  <h4>5. COOKIES</h4>
  <p>
    5.1. BLUEASSET FS AFRICA and our website analytics partners use cookies. A
    cookie is a small piece of information stored on your computer or smartphone
    by the web browser. The types of cookies used on the Website are described
    below:
  </p>
  <ul>
    <li>
      5.1.1. "Session cookies": These are used to maintain a so-called 'session
      state' and only lasts for the duration of your use of the Website. A
      session cookie expires when you close your browser or if you have not
      visited the server for a certain period. Session cookies are required for
      the Platform to function optimally but are not used in any way to identify
      you personally.
    </li>
    <li>
      5.1.2. "Permanent cookies": These cookies permanently store a unique code
      on your computer or smart device hard drive in order to identify you as an
      individual user. No Personal Information is stored in permanent cookies.
      You can view permanent cookies by looking in the cookies directory of your
      browser installation. These permanent cookies are not required for the
      Website to work, but may enhance your browsing experience.
    </li>
  </ul>
  <h4>6. LINKS FROM THE WEBSITE</h4>

  <p>
    6.1. The Website, and the Services available through the Website, may
    contain links to other third- party websites ("Third Party Websites"). If
    you select a link to any Third Party Website, you may be subject to such
    Third Party Website's terms and conditions and/or other policies, which are
    not under the control, nor responsibility, of BLUEASSET FS AFRICA.
  </p>
  <p>
    6.2. Hyperlinks to Third Party Websites are provided "as is", and BLUEASSET
    FS AFRICA does not necessarily agree with, edit or sponsor the content on
    Third Party Websites.
  </p>
  <p>
    6.3. BLUEASSET FS AFRICA does not monitor or review the content of any Third
    Party Website. Opinions expressed or material appearing on such websites are
    not necessarily shared or endorsed by us and we should not be regarded as
    the publisher of such opinions or material. Please be aware that we are not
    responsible for the privacy practices, or content, of Third Party Websites,
    either.
  </p>
  <p>
    6.4. Users should evaluate the security and trustworthiness of any Third
    Party Website before disclosing any Personal Information to them. BLUEASSET
    FS AFRICA does not accept any responsibility for any loss or damage in
    whatever manner, howsoever caused, resulting from your disclosure to third
    parties of Personal Information.
  </p>
  <h4>7. YOUR RIGHTS</h4>
  <p>
    You have the following rights concerning the processing of your Personal
    Information:
  </p>
  <p>
    7.1. Enquire as to whether your Personal Information is being processed:
    <br />
    You have the right to request from BLUEASSET FS AFRICA to confirm whether we
    hold Personal Information about you and request the record itself.
  </p>
  <p>
    7.2. Request description of your Personal Information: <br />
    You have the right to request BLUEASSET FS AFRICA to confirm, free of
    charge, whether we hold Personal Information about you. You may ask
    BLUEASSET FS AFRICA to provide you with a description of the Personal
    Information held by us or by a third party for a prescribed fee that shall
    not be excessive.
  </p>
  <p>
    7.3. Right to withdraw consent: <br />
    You may withdraw your consent at any time, provided that such withdrawal
    does not affect the processing necessary for the conclusion or performance
    of our services.
  </p>
  <p>
    7.4. Right to challenge the accuracy of the Personal Information: <br />
    You have the right to request BLUEASSET FS AFRICA to correct or rectify
    Personal Information about you in our possession or under our control if the
    Personal Information is inaccurate, out of date, incomplete or misleading.
  </p>
  <p>
    7.5. Right to object to direct marketing: <br />
    You have the right to object to the processing of Personal Information for
    direct marketing purposes.
  </p>
  <p>
    7.6. Right to request deletion: <br />
    You have the right to request that BLUEASSET FS AFRICA deletes the Personal
    Information about you in our possession or under our control that is
    inaccurate, irrelevant, excessive, out of date, incomplete, misleading or
    obtained unlawfully. At the same time, you have the right to request
    BLUEASSET FS AFRICA to destroy or delete a record of Personal Information
    about you that BLUEASSET CAPITAL is no longer authorised to retain.
  </p>
  <p>
    7.7. Right to lodge a complaint: <br />
    You have the right, if we breach any of the terms of this Privacy Policy, to
    lodge a complaint with the requisite data protection authority in your
    territory. In South Africa, this is the Information Regulator whose contact
    details are set out below: Telephone: +27 (0) 10 023 5207; or Email:
<<<<<<< HEAD
    inforeg"&#64;"justice.gov.za.
=======
    inforeg&#64;justice.gov.za.
>>>>>>> main
  </p>
  <p>
    7.8. Restriction of processing: <br />
    You have the right to require us to restrict/suspend the processing of
    Personal Information to only that which is strictly necessary for BLUEASSET
    FS AFRICA to perform our services.
  </p>
  <h4>
    8. APPLICATION OF THE ELECTRONIC COMMUNICATIONS AND TRANSACTIONS ACT 25 OF
    2002 ("ECT ACT")
  </h4>
  <p>
    8.1. Data Messages (as defined in the ECT Act) will be deemed to have been
    received by BLUEASSET CAPITAL if and when BLUEASSET FS AFRICA responds to
    the Data Messages.
  </p>
  <p>
    8.2. Data Messages sent by BLUEASSET FS AFRICA to a user will be deemed to
    have been received by such user in terms of the provisions specified in
    section 23(b) of the ECT Act.
  </p>
  <p>
    8.3. Users acknowledge that electronic signatures, encryption and/or
    authentication are not required for valid electronic communications between
    users and BLUEASSET FS AFRICA.
  </p>
  <p>
    8.4. Users warrant that Data Messages sent to BLUEASSET FS AFRICA from any
    electronic device, used by such user, from time to time or owned by such
    user, were sent and or authorised by such user, personally.
  </p>
  <p>
    8.5. Information to be provided in terms of section 43(1) of the ECT Act:
  </p>
  <ul>
    <li>
      8.5.1. Address for service of legal documents: 22 Karee Street, Unit GF08,
      Block A, Southdowns Office Park, Irene (Ground Floor), Centurion, 0157,
      South Africa
    </li>
    <li>
      8.5.2. BLUEASSET FS AFRICA - located at
      <a class="weblink" href="https://www.blueassetgroup.com"
        >www.blueassetgroup.com</a
      >
    </li>
    <li>
      8.5.3. Email address:
      <a class="weblink" href="mailto:info@blueassetgroup.com"
        >info&#64;blueassetgroup.com</a
      >
    </li>
    <li>
      8.5.4. This Website is operated and owned by BLUEASSET FS AFRICA PROTECT
      (Pty) Ltd, registration number 2021/312400/07
    </li>
  </ul>
</div>
