import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ToolsService {
  private getReportingOptionsKey = 'getreportingoptions';
  private generateReportKey = 'generatereport';
  private getbinanceaccountdetailKey = 'getbinanceaccountdetails';
  private getsalesreportKey = 'getsalesreport';
  private emailsalesreportKey = 'emailsalesreport';
  private emailbulkKey = 'emailbulk';
  private approveFireblocksflowcontrollerKey =
    'approvefireblocksflowcontroller';
  private approveFireblocksflowoperatorrKey = 'approvefireblocksflowoperator';
  private queryFireblockswithdrawalsKey = 'queryfireblockswithdrawals';
  private approveFireblockswithdrawalsKey = 'approvefireblockswithdrawals';
  private querywithdrawfromplanKey = 'querywithdrawfromplan';
  private approvewithdrawfromplanKey = 'approvewithdrawfromplan';
  private marketingaddbannerKey = 'marketingaddbanner';
  private querywithdrawfrombankKey = 'querywithdrawfrombank';
  private approvewithdrawfrombankKey = 'approvewithdrawfrombank';
  private completeWithdrawFromBankKey = 'completewithdrawfrombank';

  constructor(private apiService: ApiService) {}

  getReportingOptions(data) {
    return this.apiService.post(this.getReportingOptionsKey, data);
  }

  generateReport(data) {
    return this.apiService.post(this.generateReportKey, data);
  }

  getBinanceAccountDetails(data) {
    return this.apiService.post(this.getbinanceaccountdetailKey, data);
  }

  getSalesReport(data) {
    return this.apiService.post(this.getsalesreportKey, data);
  }

  emailSalesReport(data) {
    return this.apiService.post(this.emailsalesreportKey, data);
  }

  emailBulk(data) {
    return this.apiService.post(this.emailbulkKey, data);
  }

  approveFireblocksFlowController(data) {
    return this.apiService.post(this.approveFireblocksflowcontrollerKey, data);
  }

  approveFireblocksFlowOperator(data) {
    return this.apiService.post(this.approveFireblocksflowoperatorrKey, data);
  }

  queryFireblocksWithdrawals(data) {
    return this.apiService.post(this.queryFireblockswithdrawalsKey, data);
  }

  approveFireblocksWithdrawals(data) {
    return this.apiService.post(this.approveFireblockswithdrawalsKey, data);
  }

  queryPlanWithdrawals(data) {
    return this.apiService.post(this.querywithdrawfromplanKey, data);
  }

  approvePlanWithdrawals(data) {
    return this.apiService.post(this.approvewithdrawfromplanKey, data);
  }

  marketingAddBanner(data) {
    return this.apiService.post(this.marketingaddbannerKey, data);
  }

  queryWithdrawFromBank(data) {
    return this.apiService.post(this.querywithdrawfrombankKey, data);
  }

  approveWithdrawFromBank(data) {
    return this.apiService.post(this.approvewithdrawfrombankKey, data);
  }

  completeWithdrawFromBank(data) {
    return this.apiService.post(this.completeWithdrawFromBankKey, data);
  }
}
