<div class="screen-view">
  <div class="holder">
    <div class="section-holder" *ngIf="!this.showSuccessMsg">
      <div class="item-h">
        <div>
          <form
            class="registration-form"
            [formGroup]="userDetailsForm"
            (ngSubmit)="addNewUser()"
          >
            <div class="section-item" *ngIf="!this.showSuccessMsg">
              <div class="section-item-card">
                <div class="img-holder">
                  <img
                    class="logo"
                    src="../../assets/images/blue_asset_logo_inline_white copy.png"
                    alt=""
                  />
                </div>
                <div class="statement-download-h">
                  <h3 class="form-header">Welcome to BlueAsset Group.</h3>
                </div>
                <div class="form-holder">
                  <div
                    class="form-group user-type-container"
                    *ngIf="!userDetailsForm.get('userType')?.value"
                  >
                    <label
                      class="form-control-label label-user-type"
                      for="userType"
                    >
                      Please Select Registation Type:
                    </label>
                    <div class="check-user-type-holder">
                      <br />
                      <input
                        type="radio"
                        class="btn-check"
                        id="individual"
                        formControlName="userType"
                        value="individual"
                        autocomplete="off"
                      />
                      <label class="btn check-btn" for="individual">
                        <i class="fa fa-user-o" aria-hidden="true"></i>
                        <br />
                        Individual</label
                      >
                      <input
                        type="radio"
                        class="btn-check"
                        id="business"
                        formControlName="userType"
                        value="business"
                        autocomplete="off"
                      />
                      <label class="btn check-btn" for="business">
                        <i class="fa fa-building-o" aria-hidden="true"></i>
                        <br />
                        Business</label
                      >
                    </div>
                  </div>
                  <div *ngIf="userDetailsForm.get('userType')?.value">
                    <div class="name-surname">
                      <div class="form-group">
                        <label class="form-control-label" for="name">{{
                          isIndividual ? "Name" : "Representative Name"
                        }}</label>
                        <input
                          id="name"
                          type="text"
                          placeholder="Name"
                          formControlName="name"
                          class="form-control"
                          [ngClass]="{
                            'is-invalid':
                              isSubmitted && userFormControls.name.errors
                          }"
                        />
                      </div>

                      <div class="form-group">
                        <label class="form-control-label" for="surname">{{
                          isIndividual ? "Surname" : "Representative Surname"
                        }}</label>
                        <input
                          id="surname"
                          type="text"
                          placeholder="Surname"
                          formControlName="surname"
                          class="form-control"
                          [ngClass]="{
                            'is-invalid':
                              isSubmitted && userFormControls.surname.errors
                          }"
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="form-control-label" for="email">
                        {{ isIndividual ? "Email" : "Representative Email" }}
                      </label>
                      <input
                        id="email"
                        type="email"
                        placeholder="Email"
                        formControlName="email"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid':
                            isSubmitted && userFormControls.email.errors
                        }"
                      />
                    </div>
                    <div class="name-surname">
                      <div class="form-group">
                        <label class="form-control-label" for="country">
                          {{
                            isIndividual ? "Country" : "Country of Operation"
                          }}
                        </label>
                        <select
                          id="counrty"
                          class="form-control"
                          formControlName="country"
                          [ngClass]="{
                            'is-invalid':
                              isSubmitted && userFormControls.country.errors
                          }"
                        >
                          <option value="" disabled selected>
                            -- Select Country --
                          </option>
                          <option
                            *ngFor="let item of country"
                            [value]="item.value"
                          >
                            {{ item.text }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label class="form-control-label" for="advisorCode">
                          Advisor Code
                        </label>
                        <input
                          id="advisorCode"
                          type="advisorCode"
                          placeholder="Advisor Code"
                          formControlName="advisorCode"
                          class="form-control"
                          [ngClass]="{
                            'is-invalid':
                              isSubmitted && userFormControls.advisorCode.errors
                          }"
                        />
                      </div>
                    </div>
                  </div>

                  <div *ngIf="isGenericError">
                    <div class="clearfix">
                      <p class="error-mgs">
                        {{ genericMessage }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="button-group">
              <div>
                <div class="login-nav">
                  <p>
                    By clicking submit you agree to our
                    <a class="login-text" (click)="goToTerms()"> Terms and Conditions. </a>
                    <br />
                    Already have an account?
                    <a class="login-text" (click)="goToLogin()">Login Here</a>.
                  </p>
                </div>
              </div>
              <div class="btn-holder">
                <button
                  class="btn btn-sign-up"
                  type="button"
                  (click)="cancelPreSignUp()"
                >
                  Cancel
                </button>
                <button class="btn btn-sign-up" type="submit">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="holder" *ngIf="this.showSuccessMsg">
    <div class="section-holder section-holder-success">
      <div class="section-item-card">
        <div class="img-holder">
          <img
            class="logo"
            src="../../assets/images/blue_asset_logo_inline_white copy.png"
            alt=""
          />
        </div>
        <div class="statement-download-h">
          <h3>Success</h3>
        </div>
        <div class="text-holder-success center-align">
          <div class="text text-success">
            Please check your email and click on the link to continue setting up
            your account
          </div>
        </div>
      </div>
      <div class="button-container-success">
        <button class="btn btn-sign-up btn-success" (click)="goHome()">
          Okay
        </button>
      </div>
    </div>
  </div>
</div>
