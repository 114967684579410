import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  private signupuser = 'signupuser';
  private presignupuser = 'presignupuser';
  private presignupviaintroducer = 'presignupviaintroducer';
  private submituserstep = 'submituserstep';

  constructor(private apiService: ApiService) {}

  preRegisterUser(data) {
    return this.apiService.post(this.presignupuser, data);
  }

  preSignUpViaIntroducer(data) {
    return this.apiService.post(this.presignupviaintroducer, data);
  }

  getRegisterUserToken(data) {
    return this.apiService.post(this.signupuser, data);
  }

  addToRegisterUser(data) {
    return this.apiService.post(this.submituserstep, data);
  }
}
