import { Component, HostListener, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ToolsService } from '../services/tools.service';
import { LoaderService } from '../services/loader.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  standalone: false,
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public isLoading: boolean;
  public homeBannerData: any;
  public bannerImageText: any;
  activeSection = '';

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    center: true,
    navSpeed: 500,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      405: {
        items: 2,
      },
      785: {
        items: 3,
      },
      884: {
        items: 4,
      },
      1045: {
        items: 5,
      },
    },
    nav: true,
  };

  public bottomItems = {
    item1: {
      image: 'assets/images/asset_management.png',
      show: true,
    },
    item2: {
      image: 'assets/images/asset_management_2.webp',
      show: false,
    },
    item3: {
      image: 'assets/images/asset_management_3.png',
      show: false,
    },
  };

  public number: number = 1000;

  constructor(
    private router: Router,
    private toolsService: ToolsService,
    private loaderService: LoaderService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.updateActiveSection();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.updateActiveSection();
  }

  updateActiveSection() {
    const sections = document.querySelectorAll(
      'app-partners, app-investments, app-contact-us, app-login'
    );
    let currentSection = '';

    sections.forEach((section) => {
      const rect = section.getBoundingClientRect();
      if (rect.top >= 0 && rect.top <= window.innerHeight / 2) {
        currentSection = section.id;
      }
    });

    this.activeSection = currentSection;
  }

  goToSocial(social: any) {
    switch (social) {
      case 'facebook':
        window.open(
          'https://www.facebook.com/pages/category/Currency-Exchange/BlueAsset-Group-100346091842830',
          '_blank'
        );
        break;
      case 'twitter':
        window.open('https://twitter.com/BlueAssetG', '_blank');
        break;
      case 'linkedin':
        window.open(
          'https://www.linkedin.com/company/blueasset-group/about',
          '_blank'
        );
        break;
      case 'instagram':
        window.open('https://www.instagram.com/blueassetgroup/', '_blank');
        break;
      case 'whatsapp':
        window.open('https://wa.me/27742141170', '_blank');
        break;

      default:
        break;
    }
  }

  scrollDownFromLanding(element) {
    let item = document.getElementById(element);

    element.scrollIntoView({
      top: -200,
      behavior: 'smooth',
    });
  }

  clickToShow(item: any) {
    this.bottomItems.item1.show = false;
    this.bottomItems.item2.show = false;
    this.bottomItems.item3.show = false;

    this.bottomItems[item].show = true;
  }

  goToContactUs() {
    this.router.navigate(['contact-us']);
  }

  goToInvestmentsSolutions(section: any) {
    this.router.navigate(['investment-solutions']);
  }

  goToInvestmentStrategies() {
    this.router.navigate(['investment-strategies']);
  }
}
