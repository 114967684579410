<div class="main-holder">
  <h1>BLUE ASSET FS AFRICA GENERAL TERMS OF SERVICE</h1>

  <h4>1. INTRODUCTION</h4>
  <p>
    1.1. These Terms of Service represent our agreement in respect of your use
    and/or access of the Services.<br />
    1.2. By using and/or accessing the Services, you confirm this Agreement and
    agree to be bound by it completely.<br />
    1.3. You confirm and acknowledge that through your use and/or access of the
    Services that you are aware of or have been made aware of these Terms of
    Service, and that you have read and understood them.<br />
    1.4. While these Terms of Service contain rules dealing with the
    interpretation of specific words, phrases and terms, where we refer to "you"
    in these Terms of Service we mean you, the user of the Services.<br />
    Where we refer to "we" we mean ourselves, Blueasset FS AFRICA (Pty) Ltd with
    South African company number 2016/499768/07 and any other associated
    companies.<br />
    A reference to "us" means you and/or we, as indicated by the context.
  </p>

  <h4>2. INTERPRETATION</h4>
  <p>
    The following definitions and rules of interpretation in this clause will
    apply in this Agreement:<br />
    2.1. "This Agreement" or "Terms of Service" - means these terms of service
    as a whole;<br />
    2.2. "Business Day" - a day other than a Saturday, Sunday or public holiday
    in South Africa;<br />
    2.3. "Data Protection Legislation" - South African Data Protection
    Legislation, including the Protection of Personal Information Act and its
    regulations relating to Personal Information, and all other legislation and
    regulatory requirements in force from time to time which apply to a party,
    all relating to the use of Personal Information;<br />
    2.4. "Documentation" - the documents, guides and other media made available
    to you by us online at
    <a class="weblink" href="https://www.blueassetgroup.com"
      >www.blueassetgroup.com</a
    >
    or at any other web address notified by us to you which set out a
    description of the Services and any user instructions for the Services;<br />
    2.5. "Effective Date" - the date on which you first use and/or access the
    Services;<br />
    2.6. "Normal Business Hours" - 8:00 am to 17:00 pm local South African time,
    each Business Day;<br />
    2.7. "Personal Information" - has the meaning defined in Data Protection
    Legislation;<br />
    2.8. "Services" - the cryptocurrency exchange, cryptocurrency loan products
    and other services provided or made accessible by us to you online at
    <a class="weblink" href="https://www.blueassetgroup.com"
      >www.blueassetgroup.com</a
    >
    or any other website notified by us to you;<br />
    2.9. "User Data" - the data inputted by you, or by us on your behalf for the
    purpose of using the Services or facilitating your use of the Services, and
    includes your Personal Information.
  </p>

  <h4>3. SUBSCRIPTION AND USER ACCOUNT OPERATION</h4>
  <p>
    3.1. Subject to your compliance with this Agreement we permit you to use the
    Services and the Documentation.<br />
    3.2. To be able to enter into cryptocurrency transactions, you will first
    need to open an BLUEASSET FS AFRICA user account ("user account"). This
    includes Know-your-customer (KYC) and Anti-money laundering (AML) procedure
    and checks.<br />
    3.3. You agree to provide true, current and complete information about
    yourself during the user account registration process.<br />
    3.4. We verify and record information identifying each person who opens a
    user account with us. When you open a user account, we ask that you provide
    certain documentation or information to identify yourself and to verify the
    identifying documentation and information you will have submitted to us on
    registration. This will be done according to our internal and on-going KYC
    and AML procedure and checks.<br />
    3.5. We reserve the right to refuse or reject a registration at our
    discretion or based on regulatory restrictions imposed on us and you must be
    18 years or older to be eligible for registration.<br />
    3.6. When you open a user account with us, you will be required to set a
    username and password that you will use each time you use and/or access the
    Services.<br />
    3.7. You must use all reasonable endeavours to prevent any unauthorised use
    and/or access to your user account and in the event of any unauthorised use
    and/or access, you must immediately notify us.<br />
    3.8. To fund your activities when using the Services, you must deposit with
    us a sufficient amount of fiat currency which will be used to acquire
    cryptocurrency to provide the services to you.<br />
    3.9. Deposited fiat currency will be converted to cryptocurrency
    exclusively, which will be allocated to your user account and filtered to
    our services.<br />
    3.10. We only accept cleared funds for deposits and the methods of payment
    we accept are Debit or Credit card payment, Bank transfer and cryptocurrency
    deposits.<br />
    3.11. We may, in our sole discretion, impose restrictions on the amounts of
    fiat currency that you may deposit with us at a time, or in aggregate over
    any period.<br />
    3.12. Upon verification and validation of your instructions, we execute
    withdrawal orders as soon as all necessary systems have been adhered to.<br />
    3.13. Withdrawals of cryptocurrency are paid out in Rand as the base
    currency for conversion, and we pay out funds to your personal South African
    bank account which is associated with your user account. The applicable
    rates of conversion will be those current at the time of your withdrawal
    order.<br />
    3.14. We execute and/or transmit trading orders for execution immediately on
    the receipt of your instructions through your user account or our over the
    counter (OTC) facility.
  </p>

  <h4>4. THE SERVICES</h4>
  <p>
    4.1. We will provide the Services and make available the Documentation to
    you subject to the terms of this Agreement.<br />
    4.2. We will use commercially reasonable endeavours to make the Services
    available 24 hours a day, 7 days a week, except for:<br />
    4.2.1. planned maintenance carried out during the maintenance window of
    12.00 am to 05.00 am South African time; and<br />
    4.2.2. unscheduled maintenance performed outside Normal Business Hours
    provided that we have used reasonable endeavours to give you at least 48
    Hours' notice in advance.<br />
    4.3. We will, as part of the Services provide you with our standard user
    support services during Normal Business Hours. Our standard user support
    services will be available to you through our helpdesk online at
    <a class="weblink" href="https://www.blueassetgroup.com"
      >www.blueassetgroup.com</a
    >
    or any other web address notified by us to you.<br />
    4.4. We do not give any trading, market, investment or financial advice in
    connection with the Services, through any channel or means, including the
    helpdesk.
  </p>

  <h4>5. CHARGES AND PAYMENT</h4>
  <p>
    5.1. Your use of the Services will be subject to your payment of
    transactional fees.<br />
    5.2. We will debit the transaction fees in real-time from any funds
    associated with your user account.
  </p>

  <h4>6. OUR OBLIGATIONS</h4>
  <p>
    6.1. We undertake that the Services will be provided substantially in
    accordance with the Documentation and with reasonable skill and care as
    intended. This undertaking does not apply to the extent of any
    non-conformance which is caused by your use of the Services contrary to our
    instructions, or modification or alteration of the Services by any person
    other than ourselves.<br />
    6.2. We:<br />
    6.2.1. do not warrant that your use of the Services will be uninterrupted or
    error-free; or that the Services, the Documentation and/or the information
    obtained by you through the Services will meet your requirements; and<br />
    6.2.2. are not responsible for any trading order delays, failures, or any
    other loss or damage resulting from the transfer of data over communications
    networks and facilities, including the internet, and you acknowledge that
    the Services and the Documentation may be subject to limitations, delays and
    other problems inherent in the use of communications facilities.
  </p>

  <h4>7. YOUR OBLIGATIONS</h4>
  <p>
    You will:<br />
    7.1. provide us with:<br />
    7.1.1. all necessary co-operation concerning this Agreement; and<br />
    7.1.2. all necessary access to information as may be required by us to
    provide the Services;<br />
    7.2. without affecting your other obligations in this Agreement, comply with
    all applicable laws and regulations concerning your activities in terms of
    this Agreement;<br />
    7.3. ensure that you use the Services and the Documentation in accordance
    with the terms and conditions of this Agreement;<br />
    7.4. ensure that your network and systems comply with any specifications
    provided by us to you from time to time; and<br />
    7.5. to the extent permitted by law, and except as otherwise provided in
    this Agreement, solely be responsible for acquiring, maintaining and
    securing your network connections and telecommunications links from your
    systems to ours, and all problems, conditions, delays, failures and all
    other loss or damage arising from or relating to your network connections or
    telecommunications links or caused by the internet.
  </p>

  <h4>8. TERMINATION</h4>
  <p>
    8.1. You can close your user account with us at any time on the giving of 30
    days' notice. Your notice or request for closure must and can only be made
    through your user account. Any open or pending transactions will then be
    closed at the then-current rate at the time of closure by reference to the
    daily market values published, and we will execute a withdrawal order in
    your favour in respect of the funds remaining on your user account.<br />
    8.2. We can suspend or close your user account at any time on any of the
    following grounds, and if we do so, any open or pending transactions will be
    closed at the then-current rate at the time of suspension or closure by
    reference to the daily values:<br />
    8.2.1. if there are acts of bad faith performed with or through your user
    account;<br />
    8.2.2. if you do not comply with these Terms of Service in any way;<br />
    8.2.3. if there is an abuse of the Services by yourself;<br />
    8.2.4. if the security or integrity of the Services or other users of the
    Services is compromised;<br />
    8.2.5. if you make any fraudulent misrepresentation or incorrect
    representation to us;<br />
    8.2.6. if you fail to make any payment that is due to us;<br />
    8.2.7. if we detect any unauthorised or suspicious activity on your user
    account, including money laundering activity and multiple account
    operation;<br />
    8.2.8. if our continued association with you becomes detrimental to our
    reputation;<br />
    8.2.9. if your user account is dormant for 12 consecutive months;<br />
    8.2.10. if it becomes illegal for us to continue with our business in South
    Africa or in any country where we operate;<br />
    8.2.11. if legal requirements or laws necessitate the suspension or closure
    of your user account; and<br />
    8.2.12. if it becomes necessary to suspend or close your account as a result
    of our economical, technological, structural or similar needs.<br />
    8.3. We can suspend any payments or refund due to you following the
    suspension or closure of your account, pending investigation and:<br />
    8.3.1. where the suspension or closure involves any suspected criminal or
    illegal action that may be attributed to you, or<br />
    8.3.2. where it is probable that you are likely to face civil sanctions or
    penalties.
  </p>

  <h4>9. USER DATA, DATA PRIVACY AND PROTECTION, DATA SECURITY</h4>
  <p>
    9.1. You own all right, title and interest in and to all of the User Data
    and you are responsible for the legality, reliability, integrity, accuracy
    and quality of the User Data in as much as it is inputted by you.<br />
    9.2. We will follow regular archiving procedures for User Data. In the event
    of any loss or damage to User Data, your sole and exclusive remedy against
    us will be for us to use reasonable commercial endeavours to restore the
    lost or damaged User Data from the latest back-up of the User Data
    maintained by us in accordance with our archiving procedures. We will not be
    responsible for any loss, destruction, alteration or disclosure of User Data
    caused by any third party, except those third parties sub-contracted by us
    to perform services related to User Data maintenance and back-up.<br />
    9.3. We will both comply with all applicable requirements of Data Protection
    Legislation.<br />
    9.4. You acknowledge and agree that User Data may be transferred or stored
    outside of South Africa or outside of the country where you are located for
    us to provide the Services.<br />
    9.5. We will, concerning any User Data processed in connection with the
    performance of our obligations in this Agreement:<br />
    9.5.1. only process the User Data with your prior consent. Through your use
    and/or access of the Services you consent to us processing the User Data;<br />
    9.5.2. only transfer or store User Data outside of South Africa or outside
    of the country in which you are located to or in a country which has the
    same, similar or greater legal standards of protection in respect of the
    privacy and security of Personal Information, as compared with South
    Africa;<br />
    9.5.3. notify you without undue delay on becoming aware of any User Data
    breach; and<br />
    9.5.4. at your written request, delete or return User Data and copies of it
    to you on termination of this Agreement or closure of your user account,
    unless required by law to retain the User Data or any parts of it.<br />
    9.6. We will ensure that we have in place appropriate technical or
    organisational measures, to protect against unauthorised or unlawful
    processing of User Data and accidental loss or destruction of, or damage to,
    User Data, appropriate to the harm that might result from the unauthorised
    or unlawful processing or accidental loss, destruction or damage and the
    nature of the data to be protected, having regard to the current state of
    technological development and the cost of implementing any measures.<br />
    9.7. You consent to us appointing third-party processors of User Data under
    this Agreement. Any third party processors of User Data that we appoint will
    be bound to terms which are substantially similar to those set out in this
    clause 9. As between us, we, however, will remain fully liable for all acts
    or omissions of any appointed third-party processor of User Data.
  </p>

  <h4>10. PROPRIETARY RIGHTS</h4>
  <p>
    10.1. You acknowledge and agree that we and/or our licensors own all
    intellectual property rights in the Services and the Documentation. Except
    as expressly stated in this Agreement, this Agreement does not grant you any
    rights to, under or in, any patents, copyrights, database rights, trade
    secrets, trade names, trademarks (whether registered or not), or any other
    rights or licences in respect of the Services or the Documentation.<br />
    10.2. "BLUEASSET FS AFRICA" and the "BLUEASSET FS AFRICA logo" are our
    trademarks and we reserve all rights in them. All other trademarks appearing
    on our website at
    <a class="weblink" href="https://www.blueassetgroup.com"
      >www.blueassetgroup.com</a
    >
    are the property of their respective owners.
  </p>

  <h4>11. LIMITATION OF LIABILITY</h4>
  <p>
    11.1. Except as expressly and specifically provided in this Agreement:<br />
    11.1.1. you assume sole responsibility for the results obtained from your
    use of the Services and the Documentation, and for the conclusions drawn
    from that use. We will have no liability for any damage or loss caused by
    errors or omissions in any information or instructions provided to us by you
    in connection with the Services, or any actions taken by us at your
    direction;<br />
    11.1.2. all warranties, representations, conditions, and all other terms of
    any kind whatsoever implied by statute or common law are, to the fullest
    extent permitted by applicable law, excluded from this Agreement; and<br />
    11.1.3. the Services and the Documentation are provided to you on an "as is"
    basis and we do not warrant that they will meet your requirements. The
    entire risk as to the satisfactoriness for use of the Services lies with
    you. You acknowledge that you have carried out your own research and
    investigation into the appropriateness of the Services, and you have relied
    on that to make an informed decision to choose the Services. You agree that
    you have not solely relied on any written or oral advice from us in this
    regard.<br />
    11.2. We are not liable to you or to anyone else for any direct or indirect
    damage or loss that you suffer as a result of a failure by us to meet any of
    our obligations in these Terms of Service and where the failure is due to
    events beyond our reasonable control. This extends to where the failure by
    us to meet any of our obligations can be attributed to you, any third
    parties, any data or security breach on or affecting a third party, any data
    or security breach on or affecting any cryptocurrency exchange, or a
    combination of the aforementioned.<br />
    11.3. We are not liable to you or anyone else for transactions carried out
    in error on your part and for transactions carried out on a wrong price or
    misquote due to third parties or technical faults. In the event of
    transactions carried out on a wrong price or misquote we will take all the
    necessary measures, immediately, to remedy and correct the situation, as it
    is fair and suited to each case. Remedies will include correcting prices
    according to the correct rates at the time of a transaction. We may also
    cancel or reverse any transaction which is executed wrongly for any reason.
  </p>

  <h4>12. THIRD-PARTY PROVIDERS</h4>
  <p>
    You acknowledge that the Services may enable or assist you to access the
    website content of, correspond with, and access products and services from
    third parties and that you do so solely at your own risk. We make no
    representation, guarantee, warranty or commitment and will have no liability
    or obligation whatsoever concerning the content or use of, or correspondence
    with, any such third party, or any transactions completed, and any agreement
    entered into by you, with any third party. Any agreement entered into and
    any transaction completed with any third party is between you and that third
    party, and not with us. We recommend that you refer to the third party's
    terms of service and privacy and data protection terms before using the
    concerned third party. We do not endorse or approve any third party or the
    content of any third party media made available through the Services.
  </p>

  <h4>13. FIDUCIARY AND BROKERAGE SERVICES</h4>
  <p>
    13.1. We do not offer or provide any fiduciary or brokerage services, which
    may include investment advice and portfolio management, in conjunction with
    the Services or your use of the Services.<br />
    13.2. We do not endorse or approve you or any other person operating or
    using your user account as a brokerage account in connection with any
    fiduciary or brokerage services.<br />
    13.3. By using or allowing any person, fiduciary service provider or broker
    to operate or use your user account, you do so solely at your own risk and
    we will have no liability or obligation to you whatsoever concerning such
    operation or use of your user account.
  </p>

  <h4>14. GENERAL</h4>
  <p>
    14.1. Any notice required to be given in this Agreement will be in writing
    and will be sent by email. Our chosen email address appears in clause 15.
    Your chosen email address will be the one that is associated with your user
    account on registration.<br />
    14.2. We specifically reserve the right to terminate, amend or vary these
    Terms of Service.<br />
    When we change these Terms of Service significantly, we will notify you by
    email or through your user account of the updated terms of service. At that
    point, you will have the option to withdraw from your use and/or access of
    the Services without prejudice to you.<br />
    14.3. By continuing to use and/or access the Services, you acknowledge and
    agree to the most recent version of these Terms of Service.<br />
    14.4. If we do not enforce these Terms of Service or any part of them, it
    will not be understood as consent or waiver of the right to enforce them
    later.<br />
    14.5. You cannot transfer your rights and obligations from these Terms of
    Service to another person.<br />
    14.6. The invalidity or unenforceability of any provision or part of these
    Terms of Service will not affect the validity or enforceability of any other
    provision of them.<br />
    14.7. Any invalid or unenforceable provision will be replaced or be
    considered to be replaced by a provision which is considered to be valid and
    enforceable and which's interpretation will be as close as possible to the
    purpose of the invalid provision.<br />
    14.8. These Terms of Service are subject to South African law.
  </p>

  <h4>15. INFORMATION NOTICE</h4>
  <p>
    In compliance with section 43(1) of the Electronic Communications and
    Transactions Act, we disclose the following information in respect of
    ourselves:<br />
    15.1. Full name: Blueasset FS AFRICA (Pty) Ltd<br />
    15.2. Registration number: 2016/499768/07<br />
    15.3. Registered address: 22 Karee Street, Unit GF08, Block A, Southdowns
    Office Park, Centurion, 0157<br />
    15.4. Physical address: 22 Karee Street, Unit GF08, Block A, Southdowns
    Office Park, Centurion, 0157<br />
    15.5. Telephone number: 012 880 1390<br />
    15.6. Website address:
    <a class="weblink" href="https://www.blueassetgroup.com"
      >www.blueassetgroup.com</a
    ><br />
    15.7. E-mail address:
    <a class="weblink" href="mailto:info@blueassetgroup.com"
      >info&#64;blueassetgroup.com</a
    >
  </p>
  <h4>16. Treating Customers Fairly (TCF) Compliance</h4>
  <p>
    BlueAsset FS Africa (Pty) Ltd is committed to placing clients at the center
    of our business practices. In line with the Financial Sector Conduct
    Authority (FSCA) guidelines, we ensure full compliance with the Treating
    Customers Fairly (TCF) outcomes, creating a mutually beneficial environment
    for both our clients and our organization. The six TCF outcomes, as defined
    by the FSCA and adhered to by BlueAsset FS Africa, are:
  </p>
  <ol>
    <li>
      <strong>Customer Confidence:</strong> Our clients can trust that TCF is at
      the core of our company culture.
    </li>
    <li>
      <strong>Product Design:</strong> All products and services in the retail
      market are designed to meet the needs of our identified and targeted
      customer segments.
    </li>
    <li>
      <strong>Clear Communication:</strong> We provide clear information and
      keep our customers appropriately informed before, during, and after the
      point of sale.
    </li>
    <li>
      <strong>Suitable Advice:</strong> The advice we offer is tailored to our
      customers’ individual circumstances.
    </li>
    <li>
      <strong>Quality Service:</strong> Our services are of an acceptable
      standard, and our products perform as our customers have been led to
      expect.
    </li>
    <li>
      <strong>Ease of Access:</strong> We ensure that our customers do not face
      unreasonable post-sale barriers when they wish to change a product, switch
      providers, submit a claim, or make a complaint.
    </li>
  </ol>
  <p>
    BlueAsset FS Africa (Pty) Ltd is dedicated to maintaining these standards to
    ensure the highest level of fairness and satisfaction for our clients.
  </p>

  <h4>17. Cancellation Policy</h4>
  <p>
    Any product taken-up with BlueAsset is subject to the cancellation process
    as stipulated below: <br />
    17.1 Any product cancellation can take up to a minimum of 20 working days
    noting that the timeline may be extended due to market conditions and
    settlement values of the hedge. <br />
    17.2 A cancellation fee related to the product will apply as disclosed and
    may be amended from time to time by BlueAsset without prior notice. <br />
    17.3 BlueAsset reserves the right to amend these terms and conditions as it
    deems appropriate.<br />
  </p>
</div>
