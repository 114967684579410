<div class="footer">
  <footer class="page-footer">
    <div class="row">
      <div class="footer-first-row">
        <div class="col-md-3 mt-md-0 mt-3 pl-1 pr-0 icon-small-device">
          <a routerLink="za"
            ><img
              src="../../assets/images/blue_asset_logo_inline_black.png"
              class="navbar-brand-footer"
          /></a>
        </div>

        <div class="">
          <div class="social-holder">
            <div (click)="goToInstagram()" class="social-icon-item in-section">
              <i class="fa fa-instagram fa-1x"></i>
            </div>
            <div (click)="goToFacebook()" class="social-icon-item fb-section">
              <i class="fa fa-facebook fa-1x"></i>
            </div>
            <div (click)="goToLinkedin()" class="social-icon-item li-section">
              <i class="fa fa-linkedin fa-1x"></i>
            </div>
            <div (click)="goToTwitter()" class="social-icon-item tw-section">
              <i class="fa fa-twitter fa-1x"></i>
            </div>
            <div (click)="goToWhatsapp()" class="social-icon-item wa-section">
              <i class="fa fa-whatsapp fa-1x"></i>
            </div>
          </div>
        </div>
        <div class="badge-holder">
          <div
            class="mt-md-0 mt-3 pl-1 pr-0 icon-small-device"
            (click)="openGooglePlay()"
          >
            <div class="app-badge-holder">
              <img
                src="../../../assets/icons/logo-google-playstore-svgrepo-com.svg"
                class="app-brand-footer"
              />
              <button class="badge-btn">
                <div class="badge-text-group">
                  <span class="app-badge-text text-small"> Get it on </span>
                  <span class="app-badge-text badge-logo"> Google Play </span>
                </div>
              </button>
            </div>
          </div>
          <div
            class="mt-md-0 mt-3 pl-1 pr-0 icon-small-device"
            (click)="openAppStore()"
          >
            <div class="app-badge-holder">
              <img
                src="../../../assets/icons/apple-svgrepo-com.svg"
                class="app-brand-footer apple"
              />
              <button class="badge-btn">
                <div class="badge-text-group">
                  <span class="app-badge-text text-small">Download on</span>
                  <span class="app-badge-text badge-logo"> App Store </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row footer-menu-items">
        <div class="col-md-4 mt-md-0 mt-3">
          <h5 class="text-uppercase footer-header">South Africa</h5>
          <p class="footer-addr">
            22 Karee Street,<br />
            Unit GF08, Block A,<br />
            Southdowns Office Park,<br />
            Irene (Ground Floor)<br />
            Phone:
            <a class="tel-a" href="tel:+27128801390">(+27) 012 880 1390</a>
          </p>
        </div>
        <div class="col-md-4 mt-md-0 mt-3">
          <h5 class="text-uppercase footer-header">Cayman Islands</h5>
          <p class="footer-addr">
            67 Fort Street,<br />
            Artemis House,<br />
            George Town,<br />
            Grand Cayman
          </p>
        </div>
        <div class="col-md-4 mb-md-0 mb-3">
          <h5 class="text-uppercase footer-header">Menu</h5>
          <ul class="list-unstyled">
            <li class="nav-item">
              <a routerLink="/" fragment="/" class="nav-link">Home</a>
            </li>
            <li class="nav-item">
              <a routerLink="/" fragment="products" class="nav-link"
                >Products</a
              >
            </li>
            <li class="nav-item">
              <a routerLink="/" fragment="security" class="nav-link"
                >Security</a
              >
            </li>
            <li class="nav-item">
              <a routerLink="/" fragment="partners" class="nav-link"
                >Partners</a
              >
            </li>
            <li class="nav-item">
              <a routerLink="/" fragment="testimonials" class="nav-link"
                >Testimonials</a
              >
            </li>
            <li class="nav-item">
              <a routerLink="/" fragment="contact" class="nav-link"
                >Get Started/Contact</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="terms-holder">
      <a class="footer-term-links" [routerLink]="['/privacy-policy']"
        >Privacy Policy</a
      >
      <div class="ho-line">|</div>
      <a class="footer-term-links" [routerLink]="['/terms-and-conditions']"
        >Terms & Conditions</a
      >
      <div class="ho-line">|</div>
      <a class="footer-term-links" [routerLink]="['/disclaimer']">
        Disclaimer</a
      >
      <div class="ho-line">|</div>
      <a
        class="footer-term-links"
        href="assets/documents/PAIA_Manual_BA_FS_Africa.pdf"
        target="_blank"
        >PAIA Manual</a
      >
    </div>
    <div class="footer-copyright text-center py-3">
      Copyright © {{ year }} BlueAsset Group - All rights reserved
    </div>
    <div class="footer-copyright footer-disclaimer text-center py-3">
      © Blue Asset FS Africa (Pty) Ltd (“Blue Asset”) would like to highlight
      several important considerations before you invest in crypto asset
      technology. It is essential to understand that investing in crypto assets
      carries inherent risks, including the possibility of financial loss. There
      is no assurance that investments in crypto assets will yield positive
      returns. Historical performance should not be taken as an indicator of
      future outcomes. The value of crypto assets can be volatile and is subject
      to market conditions, without any guarantees of price stability or
      appreciation. In case of any issues related to your crypto asset
      investments, please note that clients have limited recourse to the South
      African Financial Ombudsman Service or the Financial Service Conduct
      Authority. Additionally, crypto asset investments are not overseen by any
      centralized regulatory authority. The regulatory landscape for crypto
      assets in South Africa remains unclear and is subject to change. Moreover,
      client accounts associated with crypto asset investments are not protected
      by any financial services compensation scheme. By making these
      acknowledgements, you can make informed decisions about your investment in
      crypto assets. Blue Asset is committed to providing transparency and
      ensuring you are aware of the potential risks involved. <br />
      <br />
      <b>
        BlueAsset Group South Africa (Pty) Ltd (Reg No: 2021/312391/07) confirms
        that BlueAsset FS Africa Pty Ltd (Reg No: 2016/499768/07) with FSP
        Licence No: 50396 is a part of the Group.
      </b>
    </div>
  </footer>
</div>
<app-waves></app-waves>
