<!-- <app-header *ngIf="!this.isLoggedIn"></app-header> -->
<div class="screen-view">
  <div class="step-holder" *ngIf="!this.showSuccessMsg">
    <div class="step-container">
      <div
        class="step-box"
        [ngClass]="{
          active: currentStep === 'personalDetails',
        }"
      >
        <div class="step" id="1">
          <i class="fa fa-user" aria-hidden="true"></i>
        </div>
        <div>
          <p class="step-text">Step 1</p>
          <p class="info-text">Personal Details</p>
        </div>
      </div>
      <div
        class="step-box"
        [ngClass]="{
          active: currentStep === 'addressDetails',
        }"
      >
        <div class="step" id="2">
          <i class="fa fa-home" aria-hidden="true"></i>
        </div>
        <div>
          <p class="step-text">Step 2</p>
          <p class="info-text">Address</p>
        </div>
      </div>
      <div
        class="step-box"
        [ngClass]="{
          active: currentStep === 'accountDetails',
        }"
      >
        <div class="step" id="3">
          <i class="fa fa-university" aria-hidden="true"></i>
        </div>
        <div>
          <p class="step-text">Step 3</p>
          <p class="info-text">Bank Details</p>
        </div>
      </div>
      <div
        class="step-box"
        [ngClass]="{
          active: currentStep === 'ficaDetails',
        }"
      >
        <div class="step" id="4">
          <i class="fa fa-calculator" aria-hidden="true"></i>
        </div>
        <div>
          <p class="step-text">Step 4</p>
          <p class="info-text">FICA Details</p>
        </div>
      </div>
      <div
        class="step-box"
        [ngClass]="{
          active: currentStep === 'googleAuth',
        }"
      >
        <div class="step" id="5">
          <i class="fa fa-lock" aria-hidden="true"></i>
        </div>
        <div>
          <p class="step-text">Step 5</p>
          <p class="info-text">2FA</p>
        </div>
      </div>
      <div
        class="step-box"
        [ngClass]="{
          active: currentStep === 'documentUpload',
        }"
      >
        <div class="step" id="6">
          <i class="fa fa-file-text" aria-hidden="true"></i>
        </div>
        <div>
          <p class="step-text">Step 6</p>
          <p class="info-text">Documents</p>
        </div>
      </div>
      <div
        class="step-box"
        [ngClass]="{
          active: currentStep === 'kycDetails',
        }"
      >
        <div class="step" id="7">
          <i class="fa fa-id-card" aria-hidden="true"></i>
        </div>
        <div>
          <p class="step-text">Step 7</p>
          <p class="info-text">KYC Details</p>
        </div>
      </div>
      <div
        class="step-box"
        [ngClass]="{
          active: currentStep === 'agreements',
        }"
      >
        <div class="step" id="8">
          <i class="fa fa-newspaper-o" aria-hidden="true"></i>
        </div>
        <div>
          <p class="step-text">Step 8</p>
          <p class="info-text">Disclaimer</p>
        </div>
      </div>
    </div>
  </div>

  <div class="holder" *ngIf="!this.showSuccessMsg">
    <div class="section-holder">
      <div class="item-h">
        <div>
          <div>
            <div *ngIf="currentStep === 'personalDetails'">
              <form class="" [formGroup]="userDetailsForm">
                <div class="statement-download-h">
                  <h3 class="form-header">Let's Get Your Details.</h3>
                </div>
                <div class="scroll-holder">
                  <div class="form-holder">
                    <div class="section-item">
                      <div class="section-item-card">
                        <div class="form-group">
                          <label class="form-control-label" for="title">{{
                            isIndividual ? "Title" : "Representative Title"
                          }}</label>
                          <select
                            id="title"
                            class="form-control"
                            formControlName="userTitle"
                            [ngClass]="{
                              'is-invalid':
                                isUserDetailsSubmitted &&
                                userDetailsForm.controls.userTitle.errors,
                            }"
                          >
                            <option disabled value="">
                              -- Select title --
                            </option>
                            <option
                              *ngFor="let item of titles"
                              [value]="item.value"
                            >
                              {{ item.text }}
                            </option>
                          </select>
                        </div>
                        <div class="name-group" *ngIf="isIndividual">
                          <div class="form-group">
                            <label class="form-control-label" for="name"
                              >Name</label
                            >
                            <input
                              disabled
                              id="name"
                              class="form-control"
                              [value]="username"
                            />
                          </div>
                          <div class="form-group">
                            <label class="form-control-label" for="surname"
                              >Surname</label
                            >
                            <input
                              disabled
                              id="surname"
                              class="form-control"
                              [value]="usersurname"
                            />
                          </div>
                        </div>
                        <div class="cell-gender-group">
                          <div class="form-group">
                            <div>
                              <label
                                class="form-control-label"
                                for="userCell"
                                >{{
                                  isIndividual ? "Cell" : "Representative Cell"
                                }}</label
                              >
                              <ngx-intl-tel-input
                                [cssClass]="'form-control tel-input'"
                                class="form-control"
                                [preferredCountries]="preferredCountries"
                                [enableAutoCountrySelect]="true"
                                [enablePlaceholder]="true"
                                [searchCountryFlag]="true"
                                [searchCountryField]="[
                                  SearchCountryField.Iso2,
                                  SearchCountryField.Name,
                                ]"
                                [selectFirstCountry]="false"
                                [selectedCountryISO]="
                                  CountryISO[noSpaceCountry]
                                "
                                [maxLength]="15"
                                [phoneValidation]="true"
                                [separateDialCode]="separateDialCode"
                                [numberFormat]="PhoneNumberFormat.International"
                                name="userCell"
                                formControlName="userCell"
                                id="userCell"
                                [ngClass]="{
                                  'is-invalid':
                                    isUserDetailsSubmitted &&
                                    userDetailsForm.controls.userCell.errors,
                                }"
                              >
                              </ngx-intl-tel-input>
                              <div
                                *ngIf="
                                  isUserDetailsSubmitted &&
                                  userDetailsForm.controls.userCell.errors
                                "
                                class="invalid-feedback"
                              >
                                <div
                                  *ngIf="
                                    userDetailsForm.controls.userCell.invalid &&
                                    userDetailsForm.controls.userCell.value !==
                                      null
                                  "
                                >
                                  Cell is invalid!
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label
                              class="form-control-label"
                              for="userGender"
                              >{{
                                isIndividual
                                  ? "Gender"
                                  : "Representative Gender"
                              }}</label
                            >

                            <select
                              id="userGender"
                              class="form-control"
                              formControlName="userGender"
                              [ngClass]="{
                                'is-invalid':
                                  isUserDetailsSubmitted &&
                                  userDetailsForm.controls.userGender.errors,
                              }"
                            >
                              <option disabled value="">
                                -- Select gender --
                              </option>
                              <option
                                *ngFor="let gender of genders"
                                [value]="gender.value"
                              >
                                {{ gender.text }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="country-citizen-group">
                          <div class="form-group">
                            <label
                              class="form-control-label"
                              for="userCountryOfBirth"
                            >
                              {{
                                isIndividual
                                  ? "Country of Birth"
                                  : "Representative Country of Birth"
                              }}
                            </label>
                            <select
                              id="userCountryOfBirth"
                              class="form-control"
                              formControlName="userCountryOfBirth"
                              [ngClass]="{
                                'is-invalid':
                                  isUserDetailsSubmitted &&
                                  userDetailsForm.controls.userCountryOfBirth
                                    .errors,
                              }"
                            >
                              <option
                                *ngFor="let item of country"
                                [value]="item.value"
                              >
                                {{ item.text }}
                              </option>
                            </select>
                          </div>
                          <div class="form-group">
                            <label
                              class="form-control-label"
                              for="userCitizenship"
                            >
                              {{
                                isIndividual
                                  ? "Citizenship"
                                  : "Representative Citizenship"
                              }}
                            </label>
                            <select
                              id="userCitizenship"
                              class="form-control"
                              formControlName="userCitizenship"
                              [ngClass]="{
                                'is-invalid':
                                  isUserDetailsSubmitted &&
                                  userDetailsForm.controls.userCitizenship
                                    .errors,
                              }"
                            >
                              <option value="" disabled selected>
                                -- Select country --
                              </option>
                              <option
                                *ngFor="let item of country"
                                [value]="item.value"
                              >
                                {{ item.text }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <label
                            class="form-control-label"
                            for="userId"
                            *ngIf="isIndividual && isSouthAfrican"
                          >
                            ID Number</label
                          >
                          <label
                            class="form-control-label"
                            for="userId"
                            *ngIf="isIndividual && !isSouthAfrican"
                          >
                            Passport Number</label
                          >
                          <label
                            class="form-control-label"
                            for="userId"
                            *ngIf="!isIndividual"
                          >
                            Company Reg. Number</label
                          >
                          <input
                            id="userId"
                            type="text"
                            placeholder="Number"
                            formControlName="userId"
                            class="form-control"
                            [value]="userId"
                            [ngClass]="{
                              'is-invalid':
                                isUserDetailsSubmitted &&
                                userDetailsForm.controls.userId.errors,
                            }"
                          />
                          <div
                            *ngIf="
                              isUserDetailsSubmitted &&
                              userDetailsForm.controls.userId.errors
                            "
                            class="invalid-feedback"
                          ></div>
                        </div>
                        <div class="date-marital-group">
                          <div class="form-group">
                            <label
                              class="form-control-label"
                              for="userDateOfBirth"
                            >
                              {{
                                isIndividual
                                  ? "Date of Birth"
                                  : "Representative Date of Birth"
                              }}
                            </label>
                            <input
                              id="userDateOfBirth"
                              type="date"
                              formControlName="userDateOfBirth"
                              class="form-control"
                              [value]="userDateOfBirth"
                              [ngClass]="{
                                'is-invalid':
                                  isUserDetailsSubmitted &&
                                  userDetailsForm.controls.userDateOfBirth
                                    .errors,
                              }"
                            />
                            <div
                              *ngIf="
                                isUserDetailsSubmitted &&
                                userDetailsForm.controls.userDateOfBirth.errors
                              "
                              class="invalid-feedback"
                            ></div>
                          </div>
                          <div class="form-group">
                            <label
                              class="form-control-label"
                              for="maritalStatus"
                            >
                              {{
                                isIndividual
                                  ? "Martital Status"
                                  : "Representative Martital Status"
                              }}</label
                            >
                            <select
                              id="userMaritalStatus"
                              class="form-control"
                              formControlName="userMaritalStatus"
                              [ngClass]="{
                                'is-invalid':
                                  isUserDetailsSubmitted &&
                                  userDetailsForm.controls.userMaritalStatus
                                    .errors,
                              }"
                            >
                              <option value="" disabled selected>
                                -- Select Marital Status --
                              </option>
                              <option
                                *ngFor="let item of maritalStatus"
                                [value]="item.value"
                              >
                                {{ item.text }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="name-group" *ngIf="!isIndividual">
                          <div class="form-group">
                            <label class="form-control-label" for="businessName"
                              >Business Name</label
                            >
                            <input
                              id="businessName"
                              class="form-control"
                              placeholder="Business Name"
                              formControlName="businessName"
                              [ngClass]="{
                                'is-invalid':
                                  isUserDetailsSubmitted &&
                                  userDetailsForm.controls.businessName.errors,
                              }"
                            />
                          </div>
                          <div class="form-group">
                            <label
                              class="form-control-label"
                              for="countryOfRegistration"
                              >Country of Registration</label
                            >
                            <select
                              id="countryOfRegistration"
                              class="form-control"
                              formControlName="countryOfRegistration"
                              [ngClass]="{
                                'is-invalid':
                                  isUserDetailsSubmitted &&
                                  userDetailsForm.controls.countryOfRegistration
                                    .errors,
                              }"
                            >
                              <option value="" disabled selected>
                                -- Select country --
                              </option>
                              <option
                                *ngFor="let item of country"
                                [value]="item.value"
                              >
                                {{ item.text }}
                              </option>
                            </select>
                            <div
                              *ngIf="
                                isUserDetailsSubmitted &&
                                userDetailsForm.controls.countryOfRegistration
                                  .errors
                              "
                              class="invalid-feedback"
                            ></div>
                          </div>
                        </div>
                        <div class="password-group">
                          <div class="form-group">
                            <label class="form-control-label" for="userPassword"
                              >Create a Password</label
                            >
                            <input
                              id="userPassword"
                              type="password"
                              placeholder="Password"
                              formControlName="userPassword"
                              class="form-control"
                              [ngClass]="{
                                'is-invalid':
                                  isUserDetailsSubmitted &&
                                  userDetailsForm.controls.userPassword.errors,
                              }"
                            />
                            <div
                              *ngIf="
                                isUserDetailsSubmitted &&
                                userDetailsForm.controls.userPassword.errors
                              "
                              class="invalid-feedback"
                            ></div>
                          </div>
                          <div class="form-group">
                            <label
                              class="form-control-label"
                              for="userConfirmPassword"
                              >Confirm Password</label
                            >
                            <input
                              id="userConfirmPassword"
                              type="password"
                              placeholder="Confirm Password"
                              formControlName="userConfirmPassword"
                              class="form-control"
                              [ngClass]="{
                                'is-invalid':
                                  isUserDetailsSubmitted &&
                                  userDetailsForm.controls.userConfirmPassword
                                    .errors,
                              }"
                            />
                            <div
                              *ngIf="
                                isUserDetailsSubmitted &&
                                userDetailsForm.controls.userConfirmPassword
                                  .errors
                              "
                              class="invalid-feedback"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="isGenericError" class="clearfix">
                        <p class="error-mgs">{{ genericErrorMessage }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="button-group">
                  <div class="btn-holder">
                    <button class="btn btn-sign-up" (click)="cancelSignUp()">
                      Cancel
                    </button>
                    <button class="btn btn-sign-up" (click)="addUserDetails()">
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div *ngIf="currentStep === 'cellValidation'">
              <div class="statement-download-h">
                <h3 class="form-header">Let's Verify Your Cell</h3>
              </div>
              <form [formGroup]="verifyCellForm" class="">
                <div class="section-item">
                  <div class="section-item-card otp-container">
                    <div class="form-holder">
                      <div class="otp-text center">
                        Please enter the OTP that was sent to your cell.
                      </div>
                      <div class="otp-holder">
                        <ng-otp-input
                          [config]="{ length: 6 }"
                          (onInputChange)="onOtpChange($event)"
                        >
                        </ng-otp-input>
                      </div>
                      <div *ngIf="isGenericError" class="clearfix">
                        <p class="error-mgs">{{ genericErrorMessage }}</p>
                      </div>
                    </div>
                    <div class="otp-countdown">
                      time left:
                      <countdown
                        #cd
                        [config]="{ leftTime: 180, format: 'mm:ss' }"
                        (event)="countdownEvent($event)"
                      >
                      </countdown>
                    </div>
                  </div>
                </div>
                <div class="button-group">
                  <div class="btn-holder">
                    <button class="btn btn-sign-up" (click)="previousStep()">
                      Previous
                    </button>
                    <button class="btn btn-sign-up" (click)="ResendOtp()">
                      Resend OTP
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div *ngIf="currentStep === 'addressDetails'">
              <form class="" [formGroup]="userAddressForm">
                <div class="section-item">
                  <div class="section-item-card">
                    <div class="statement-download-h">
                      <h3 class="form-header">
                        {{
                          isIndividual
                            ? "Let's Get Your Address"
                            : "Business Address"
                        }}
                      </h3>
                    </div>
                    <div class="form-group">
                      <label class="form-control-label" for="buildingName"
                        >Complex / Building Name ( Optional )</label
                      >
                      <input
                        id="buildingName"
                        type="text"
                        placeholder="Complex / Building Name"
                        formControlName="buildingName"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid':
                            isUserAddressSubmitted &&
                            userAddressForm.controls.buildingName.errors,
                        }"
                      />
                      <div
                        *ngIf="
                          isUserAddressSubmitted &&
                          userAddressForm.controls.buildingName.errors
                        "
                        class="invalid-feedback"
                      ></div>
                    </div>
                    <div class="form-group">
                      <label class="form-control-label" for="street"
                        >Street</label
                      >
                      <input
                        id="street"
                        type="text"
                        placeholder="Street"
                        formControlName="street"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid':
                            isUserAddressSubmitted &&
                            userAddressForm.controls.street.errors,
                        }"
                      />
                      <div
                        *ngIf="
                          isUserAddressSubmitted &&
                          userAddressForm.controls.street.errors
                        "
                        class="invalid-feedback"
                      ></div>
                    </div>
                    <div class="form-group">
                      <label class="form-control-label" for="suburb"
                        >Suburb</label
                      >
                      <input
                        id="suburb"
                        type="text"
                        placeholder="Suburb"
                        formControlName="suburb"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid':
                            isUserAddressSubmitted &&
                            userAddressForm.controls.suburb.errors,
                        }"
                      />
                      <div
                        *ngIf="
                          isUserAddressSubmitted &&
                          userAddressForm.controls.suburb.errors
                        "
                        class="invalid-feedback"
                      ></div>
                    </div>
                    <div class="form-group">
                      <label class="form-control-label" for="city">City</label>
                      <input
                        id="city"
                        type="text"
                        placeholder="City"
                        formControlName="city"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid':
                            isUserAddressSubmitted &&
                            userAddressForm.controls.city.errors,
                        }"
                      />
                      <div
                        *ngIf="
                          isUserAddressSubmitted &&
                          userAddressForm.controls.city.errors
                        "
                        class="invalid-feedback"
                      ></div>
                    </div>
                    <div class="form-group">
                      <label class="form-control-label" for="province"
                        >Province/State</label
                      >
                      <input
                        id="province"
                        type="text"
                        placeholder="Province"
                        formControlName="province"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid':
                            isUserAddressSubmitted &&
                            userAddressForm.controls.province.errors,
                        }"
                      />
                      <div
                        *ngIf="
                          isUserAddressSubmitted &&
                          userAddressForm.controls.province.errors
                        "
                        class="invalid-feedback"
                      ></div>
                    </div>
                    <div class="form-group">
                      <label class="form-control-label" for="country"
                        >Country</label
                      >
                      <input
                        id="counrty"
                        class="form-control"
                        formControlName="country"
                        [ngClass]="{
                          'is-invalid':
                            isUserAddressSubmitted &&
                            userAddressForm.controls.country.errors,
                        }"
                      />
                      <!-- <option value="selectedCountry">
                        {{ selectedCountry }}
                      </option> -->
                    </div>
                    <div class="form-group">
                      <label class="form-control-label" for="postalCode"
                        >Postal Code</label
                      >
                      <input
                        id="postalCode"
                        type="text"
                        placeholder="Postal Code"
                        formControlName="postalCode"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid':
                            isUserAddressSubmitted &&
                            userAddressForm.controls.postalCode.errors,
                        }"
                      />
                      <div
                        *ngIf="
                          isUserAddressSubmitted &&
                          userAddressForm.controls.postalCode.errors
                        "
                        class="invalid-feedback"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="button-group">
                  <div class="btn-holder">
                    <button class="btn btn-sign-up" (click)="previousStep()">
                      Previous
                    </button>
                    <button class="btn btn-sign-up" (click)="addUserAddress()">
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div *ngIf="currentStep === 'accountDetails'">
              <form class="" [formGroup]="userBankDetailsForm">
                <div class="section-item">
                  <div class="section-item-card">
                    <div class="statement-download-h">
                      <h3 class="form-header">
                        Let's Get Your Banking Details
                      </h3>
                    </div>
                    <div class="form-group">
                      <label class="form-control-label" for="bankName"
                        >Bank Name</label
                      >
                      <select
                        id="bankName"
                        class="form-control"
                        formControlName="bankName"
                        [ngClass]="{
                          'is-invalid':
                            isUserBankDetailsSubmitted &&
                            userBankDetailsForm.controls.bankName.errors,
                        }"
                        required
                      >
                        <div
                          *ngIf="
                            isUserBankDetailsSubmitted &&
                            userBankDetailsForm.controls.bankName.errors
                          "
                          class="invalid-feedback"
                        ></div>
                        <option value="" disabled selected>
                          -- Select your Bank --
                        </option>
                        <option
                          *ngFor="let bank of supportedBanks"
                          [value]="bank?.displayName"
                        >
                          {{ bank?.displayName }}
                        </option>
                      </select>
                      <div
                        *ngIf="
                          isUserBankDetailsSubmitted &&
                          userBankDetailsForm.controls.bankName.errors
                        "
                        class="invalid-feedback"
                      ></div>
                      <div *ngIf="!isBankNameValid" style="color: red">
                        Bank name does not match any available options!
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="form-control-label" for="acc-holder"
                        >Account Holder Name</label
                      >
                      <input
                        id="acc-holder"
                        type="text"
                        placeholder="Account Holder Name"
                        formControlName="accountHolderName"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid':
                            isUserBankDetailsSubmitted &&
                            userBankDetailsForm.controls.accountHolderName
                              .errors,
                        }"
                      />
                      <div
                        *ngIf="
                          isUserBankDetailsSubmitted &&
                          userBankDetailsForm.controls.accountHolderName.errors
                        "
                      ></div>
                    </div>

                    <div class="form-group">
                      <label class="form-control-label" for="acc-number"
                        >Account Number</label
                      >
                      <input
                        id="acc-number"
                        type="text"
                        placeholder="Account Number"
                        formControlName="accountNumber"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid':
                            isUserBankDetailsSubmitted &&
                            userBankDetailsForm.controls.accountNumber.errors,
                        }"
                      />
                      <div
                        *ngIf="
                          isUserBankDetailsSubmitted &&
                          userBankDetailsForm.controls.accountNumber.errors
                        "
                        class="invalid-feedback"
                      ></div>
                    </div>

                    <div class="form-group">
                      <label class="form-control-label" for="acc-type"
                        >Account Type</label
                      >
                      <select
                        id="accountType"
                        class="form-control"
                        formControlName="accountType"
                        [ngClass]="{
                          'is-invalid':
                            isUserBankDetailsSubmitted &&
                            userBankDetailsForm.controls.accountType.errors,
                        }"
                      >
                        <option disabled value="">
                          -- Select Account Type --
                        </option>
                        <option
                          *ngFor="let account of accountType"
                          [value]="account.value"
                        >
                          {{ account.text }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label class="form-control-label" for="branch-code"
                        >Branch Code</label
                      >
                      <input
                        id="branch-code"
                        type="text"
                        placeholder="Branch Code"
                        formControlName="branchCode"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid':
                            isUserBankDetailsSubmitted &&
                            userBankDetailsForm.controls.branchCode.errors,
                        }"
                      />
                      <div
                        *ngIf="
                          isUserBankDetailsSubmitted &&
                          userBankDetailsForm.controls.branchCode.errors
                        "
                        class="invalid-feedback"
                      ></div>
                    </div>
                    <div class="form-group">
                      <label class="form-control-label" for="taxNumber">
                        {{
                          isIndividual ? "Tax Number" : "VAT Reg. Number"
                        }}</label
                      >
                      <input
                        id="taxNumber"
                        type="text"
                        placeholder="Tax Number"
                        formControlName="taxNumber"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid':
                            isUserBankDetailsSubmitted &&
                            userBankDetailsForm.controls.taxNumber.errors,
                        }"
                      />
                      <div
                        *ngIf="
                          isUserBankDetailsSubmitted &&
                          userBankDetailsForm.controls.taxNumber.errors
                        "
                        class="invalid-feedback"
                      ></div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="button-group">
                <div class="btn-holder">
                  <button class="btn btn-sign-up" (click)="previousStep()">
                    Previous
                  </button>
                  <button class="btn btn-sign-up" (click)="addUserBanking()">
                    Next
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="currentStep === 'ficaDetails'">
              <form class="" [formGroup]="userFicaDetailsForm">
                <div class="section-item">
                  <div class="section-item-card">
                    <div class="statement-download-h">
                      <h3 class="form-header">Let's Get Your FICA Details</h3>
                    </div>
                    <div class="form-group">
                      <label class="form-control-label" for="fundsSource"
                        >What is your source of funds/wealth?</label
                      >
                      <select
                        id="fundsSource"
                        class="form-control"
                        formControlName="fundsSource"
                        [ngClass]="{
                          'is-invalid':
                            isUserFicaDetailsSubmitted &&
                            userFicaDetailsForm.controls.fundsSource.errors,
                        }"
                        required
                      >
                        <option value="" disabled selected>
                          -- Choose Funds Source --
                        </option>
                        <option
                          *ngFor="let item of sourceOfFunds"
                          [value]="item.value"
                        >
                          {{ item.text }}
                        </option>
                      </select>
                      <div
                        *ngIf="
                          isUserFicaDetailsSubmitted &&
                          userFicaDetailsForm.controls.fundsSource.errors
                        "
                        class="invalid-feedback"
                      ></div>
                    </div>
                    <div class="fica-holder">
                      <div class="form-group">
                        <label class="form-control-label" for="industry">{{
                          isIndividual ? "Occupation" : "Industry"
                        }}</label>
                        <select
                          class="form-control"
                          formControlName="industry"
                          [ngClass]="{
                            'is-invalid':
                              isUserFicaDetailsSubmitted &&
                              userFicaDetailsForm.controls.industry.errors,
                          }"
                        >
                          <option value="" disabled selected>
                            {{
                              isIndividual
                                ? "-- Select Occupation --"
                                : "-- Select Industry --"
                            }}
                          </option>
                          <option
                            *ngFor="let item of industry"
                            [ngValue]="item?.value"
                          >
                            {{ item?.text }}
                          </option>
                        </select>
                        <div
                          *ngIf="
                            isUserFicaDetailsSubmitted &&
                            userFicaDetailsForm.controls.industry.errors
                          "
                          class="invalid-feedback"
                        ></div>
                      </div>
                      <div class="form-group">
                        <label class="form-control-label">
                          {{
                            isIndividual ? "Annual Income" : "Annual Revenue"
                          }}</label
                        >
                        <select
                          class="form-control"
                          formControlName="totalAnnualIncome"
                          [ngClass]="{
                            'is-invalid':
                              isUserFicaDetailsSubmitted &&
                              userFicaDetailsForm.controls.totalAnnualIncome
                                .errors,
                          }"
                          *ngIf="isIndividual"
                        >
                          <option value="" disabled selected>
                            {{
                              isIndividual
                                ? "-- Annual Income --"
                                : "-- Annual Revenue --"
                            }}
                          </option>
                          <option
                            *ngFor="let item of totalAnnualIncome"
                            [ngValue]="item?.value"
                          >
                            {{ item?.text }}
                          </option>
                        </select>
                        <select
                          class="form-control"
                          formControlName="totalAnnualIncome"
                          [ngClass]="{
                            'is-invalid':
                              isUserFicaDetailsSubmitted &&
                              userFicaDetailsForm.controls.totalAnnualIncome
                                .errors,
                          }"
                          *ngIf="!isIndividual"
                        >
                          <option value="" disabled selected>
                            -- Total Business Revenue --
                          </option>
                          <option
                            *ngFor="let item of totalBusinessRevenue"
                            [ngValue]="item?.value"
                          >
                            {{ item?.text }}
                          </option>
                        </select>
                        <div
                          *ngIf="
                            isUserFicaDetailsSubmitted &&
                            userFicaDetailsForm.controls.totalAnnualIncome
                              .errors
                          "
                          class="invalid-feedback"
                        ></div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="form-control-label">
                        Are you a Politically Exposed Person?</label
                      >
                      <br />
                      <div class="radio-btn-box">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="politicallyExposedPerson"
                            formControlName="politicallyExposedPerson"
                            [value]="true"
                            [ngClass]="{
                              'is-invalid':
                                isUserFicaDetailsSubmitted &&
                                userFicaDetailsForm.controls
                                  .politicallyExposedPerson.errors,
                            }"
                          />
                          <label
                            class="form-check-label"
                            for="politicallyExposedPerson"
                            >Yes</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="politicallyExposedPerson"
                            formControlName="politicallyExposedPerson"
                            [value]="false"
                            [ngClass]="{
                              'is-invalid':
                                isUserFicaDetailsSubmitted &&
                                userFicaDetailsForm.controls
                                  .politicallyExposedPerson.errors,
                            }"
                          />
                          <label
                            class="form-check-label"
                            for="politicallyExposedPerson"
                            >No</label
                          >
                        </div>
                      </div>
                      <p class="pep-description">
                        Politically Exposed Person, or PEPs include heads of
                        state, government ministers or equivalent politicians,
                        senior government executives, high-ranking judges,
                        high-ranking military officers, central bank governors,
                        or board members or executives of an international
                        organisation, even members of a royal family.
                      </p>

                      <div
                        *ngIf="
                          isUserFicaDetailsSubmitted &&
                          userFicaDetailsForm.controls.politicallyExposedPerson
                            .errors
                        "
                        class="invalid-feedback"
                      ></div>
                    </div>
                    <div
                      class="form-group"
                      *ngIf="politicallyExposedPersonValue"
                    >
                      <label class="form-control-label"
                        >Select your Position</label
                      >
                      <select
                        class="form-control"
                        formControlName="politicallyExposedPersonType"
                        [ngClass]="{
                          'is-invalid':
                            isUserFicaDetailsSubmitted &&
                            userFicaDetailsForm.controls
                              .politicallyExposedPersonType?.errors,
                        }"
                      >
                        <option value="" disabled selected>-- Select --</option>
                        <option
                          *ngFor="let item of politicallyExposedPersonType"
                          [ngValue]="item?.value"
                        >
                          {{ item?.text }}
                        </option>
                      </select>
                      <div
                        *ngIf="
                          isUserFicaDetailsSubmitted &&
                          userFicaDetailsForm.controls
                            .politicallyExposedPersonType?.errors
                        "
                        class="invalid-feedback"
                      ></div>
                    </div>
                    <div
                      class="form-group"
                      *ngIf="politicallyExposedPersonValue === false"
                    >
                      <label class="form-control-label">
                        Are you a Prominent Influential Person?</label
                      >
                      <br />
                      <div class="radio-btn-box">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="prominentInfluentialPerson"
                            formControlName="prominentInfluentialPerson"
                            [value]="true"
                            [ngClass]="{
                              'is-invalid':
                                isUserFicaDetailsSubmitted &&
                                userFicaDetailsForm.controls
                                  .prominentInfluentialPerson?.errors,
                            }"
                          />
                          <label
                            class="form-check-label"
                            for="prominentInfluentialPerson"
                            >Yes</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="prominentInfluentialPerson"
                            formControlName="prominentInfluentialPerson"
                            [value]="false"
                            [ngClass]="{
                              'is-invalid':
                                isUserFicaDetailsSubmitted &&
                                userFicaDetailsForm.controls
                                  .prominentInfluentialPerson?.errors,
                            }"
                          />
                          <label
                            class="form-check-label"
                            for="prominentInfluentialPerson"
                            >No</label
                          >
                        </div>
                      </div>
                      <div
                        *ngIf="
                          isUserFicaDetailsSubmitted &&
                          userFicaDetailsForm.controls
                            .prominentInfluentialPerson.errors
                        "
                        class="invalid-feedback"
                      ></div>
                      <p class="pep-description">
                        A Prominent Influential Person (PIP) is someone who,
                        within the past 12 months, has held a key leadership
                        role—such as Chairperson, Executive Officer, or CFO—in a
                        company that supplies goods or services to the
                        government, with transactions exceeding a threshold set
                        by the Minister of Finance. This includes companies with
                        government contracts and tenders.
                      </p>
                    </div>
                    <div
                      class="form-group"
                      *ngIf="
                        prominentInfluentialPersonValue === true &&
                        politicallyExposedPersonValue === false
                      "
                    >
                      <label class="form-control-label"
                        >Select your Position</label
                      >
                      <select
                        class="form-control"
                        formControlName="prominentInfluentialPersonType"
                        [ngClass]="{
                          'is-invalid':
                            isUserFicaDetailsSubmitted &&
                            userFicaDetailsForm.controls
                              .prominentInfluentialPersonType?.errors,
                        }"
                      >
                        <option value="" disabled selected>-- Select --</option>
                        <option
                          *ngFor="let item of prominentInfluentialPersonType"
                          [ngValue]="item?.value"
                        >
                          {{ item?.text }}
                        </option>
                      </select>
                      <div
                        *ngIf="
                          isUserFicaDetailsSubmitted &&
                          userFicaDetailsForm.controls
                            .prominentInfluentialPersonType.errors
                        "
                        class="invalid-feedback"
                      ></div>
                    </div>
                  </div>
                </div>
              </form>

              <div class="button-group">
                <div class="btn-holder">
                  <button class="btn btn-sign-up" (click)="previousStep()">
                    Previous
                  </button>
                  <button
                    class="btn btn-sign-up"
                    (click)="addUserFicaDetails()"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="currentStep === 'googleAuth'">
              <form
                class="google-auth-holder"
                [formGroup]="userAuthenticatorForm"
              >
                <div class="section-item">
                  <div class="section-item-card">
                    <div class="statement-download-h">
                      <h3 class="form-header">
                        Let's Set Up Google Authenicator
                      </h3>
                    </div>
                    <div class="form-group google-auth-form">
                      <div>
                        <label class="form-control-label" for="opt-auth"
                          >Google Authenticator (Optional)</label
                        >
                        <div class="modal-body">
                          <div class="center mb-1 form-control-label">
                            Please scan the QR code below using your Google
                            Authenticator application
                          </div>
                          <div class="center">
                            <img [src]="qrCode" />
                          </div>
                          <div class="form-group">
                            <div class="center mb-3 mt-1 form-control-label">
                              Next provide the OTP that you see in your Google
                              Authenticator application
                            </div>
                            <div class="otp-holder">
                              <ng-otp-input
                                name="googleAuth"
                                id="googleAuth"
                                [config]="{ length: 6, allowNumbersOnly: true }"
                                (onInputChange)="onAuthOtpChange($event)"
                              >
                              </ng-otp-input>
                            </div>
                          </div>
                          <div *ngIf="isGenericError" class="clearfix">
                            <p class="error-mgs">{{ genericErrorMessage }}</p>
                          </div>
                        </div>
                        <div
                          *ngIf="
                            isUserAuthenticatorSubmitted &&
                            userAuthenticatorForm.controls.googleAuth.errors
                          "
                          class="invalid-feedback"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="button-group">
                  <div class="btn-holder">
                    <button class="btn btn-sign-up" (click)="previousStep()">
                      Previous
                    </button>
                    <!-- <button class="btn btn-sign-up" (click)="skipGoogleAuth()">
                      Skip
                    </button> -->
                    <button class="btn btn-sign-up" (click)="addUserAuth()">
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div *ngIf="currentStep === 'documentUpload'">
              <form class="" [formGroup]="documentUpload">
                <div class="section-item">
                  <div class="section-item-card">
                    <div class="statement-download-h">
                      <h3 class="form-header">Let's Get Your Documents</h3>
                      <span class="optional-text" style="text-align: center">
                        <p>Document Upload (Optional)</p>
                        <div class="error-mgs">PDF Only (Max 5MB)</div>
                      </span>
                    </div>
                    <div *ngIf="isIndividual" class="individual-docs">
                      <div class="business-docs-wrapper">
                        <div class="form-group form-group-docs">
                          <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label class="form-control-label">
                              {{
                                isSouthAfrican ? "ID Document" : "Passport"
                              }}</label
                            >
                            <input
                              type="file"
                              id="file"
                              name="File Upload"
                              (change)="fileUpload($event, 'indv_id')"
                              accept=".pdf,.png,.jpg,.jpeg"
                            />
                          </div>
                        </div>
                        <div class="form-group form-group-docs">
                          <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label class="form-control-label"
                              >Proof of Bank</label
                            >
                            <input
                              type="file"
                              id="file"
                              name="File Upload"
                              (change)="fileUpload($event, 'indv_bank')"
                              accept=".pdf,.png,.jpg,.jpeg"
                            />
                          </div>
                        </div>
                        <div class="form-group form-group-docs">
                          <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label class="form-control-label"
                              >Proof of Tax Reg.</label
                            >
                            <input
                              type="file"
                              id="file"
                              name="File Upload"
                              (change)="fileUpload($event, 'indv_tax')"
                              accept=".pdf,.png,.jpg,.jpeg"
                            />
                          </div>
                        </div>
                        <div class="form-group form-group-docs">
                          <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label class="form-control-label"
                              >Proof of Income</label
                            >
                            <input
                              type="file"
                              id="file"
                              name="File Upload"
                              (change)="fileUpload($event, 'indv_income')"
                              accept=".pdf,.png,.jpg,.jpeg"
                            />
                          </div>
                        </div>
                        <div class="form-group form-group-docs">
                          <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label class="form-control-label"
                              >Proof of Address</label
                            >
                            <input
                              type="file"
                              id="file"
                              name="File Upload"
                              (change)="fileUpload($event, 'indv_address')"
                              accept=".pdf,.png,.jpg,.jpeg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="business-docs" *ngIf="!isIndividual">
                      <div class="business-docs-wrapper">
                        <div class="scrollable-container">
                          <div class="row">
                            <div class="col bus-docs-col">
                              <div class="form-group form-group-docs">
                                <div
                                  class="col-lg-6 col-md-6 col-sm-12 form-group"
                                >
                                  <label class="form-control-label"
                                    >CIPC Documentation</label
                                  >
                                  <input
                                    type="file"
                                    id="file"
                                    name="File Upload"
                                    (change)="fileUpload($event, 'bus_cipc')"
                                    accept=".pdf,.png,.jpg,.jpeg"
                                  />
                                </div>
                              </div>
                              <div class="form-group form-group-docs">
                                <div
                                  class="col-lg-6 col-md-6 col-sm-12 form-group"
                                >
                                  <label class="form-control-label"
                                    >Proof of Company Address</label
                                  >
                                  <input
                                    type="file"
                                    id="file"
                                    name="File Upload"
                                    (change)="fileUpload($event, 'bus_address')"
                                    accept=".pdf,.png,.jpg,.jpeg"
                                  />
                                </div>
                              </div>
                              <div class="form-group form-group-docs">
                                <div
                                  class="col-lg-6 col-md-6 col-sm-12 form-group"
                                >
                                  <label class="form-control-label"
                                    >Proof of Company Bank</label
                                  >
                                  <input
                                    type="file"
                                    id="file"
                                    name="File Upload"
                                    (change)="fileUpload($event, 'bus_bank')"
                                    accept=".pdf,.png,.jpg,.jpeg"
                                  />
                                </div>
                              </div>
                              <div class="form-group form-group-docs">
                                <div
                                  class="col-lg-6 col-md-6 col-sm-12 form-group"
                                >
                                  <label class="form-control-label"
                                    >Proof of Tax Reg.</label
                                  >
                                  <input
                                    type="file"
                                    id="file"
                                    name="File Upload"
                                    (change)="fileUpload($event, 'bus_tax')"
                                    accept=".pdf,.png,.jpg,.jpeg"
                                  />
                                </div>
                              </div>
                              <div class="form-group form-group-docs">
                                <div
                                  class="col-lg-6 col-md-6 col-sm-12 form-group"
                                >
                                  <label class="form-control-label"
                                    >Proof of Source of Funds</label
                                  >
                                  <input
                                    type="file"
                                    id="file"
                                    name="File Upload"
                                    (change)="fileUpload($event, 'bus_income')"
                                    accept=".pdf,.png,.jpg,.jpeg"
                                  />
                                </div>
                              </div>
                              <div class="form-group form-group-docs">
                                <div
                                  class="col-lg-6 col-md-6 col-sm-12 form-group"
                                >
                                  <label class="form-control-label"
                                    >Shares Certificate</label
                                  >
                                  <input
                                    type="file"
                                    id="file"
                                    name="File Upload"
                                    (change)="
                                      fileUpload(
                                        $event,
                                        'bus_share_certificate'
                                      )
                                    "
                                    accept=".pdf,.png,.jpg,.jpeg"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col bus-docs-col">
                              <div class="form-group form-group-docs">
                                <div
                                  class="col-lg-6 col-md-6 col-sm-12 form-group"
                                >
                                  <label class="form-control-label"
                                    >Company Structure Chart</label
                                  >
                                  <input
                                    type="file"
                                    id="file"
                                    name="File Upload"
                                    (change)="
                                      fileUpload($event, 'bus_structure_chart')
                                    "
                                    accept=".pdf,.png,.jpg,.jpeg"
                                  />
                                </div>
                              </div>
                              <div class="form-group form-group-docs">
                                <div
                                  class="col-lg-6 col-md-6 col-sm-12 form-group"
                                >
                                  <label class="form-control-label"
                                    >Company Directors ID Document</label
                                  >
                                  <input
                                    type="file"
                                    id="file"
                                    name="File Upload"
                                    (change)="
                                      fileUpload($event, 'bus_director_id')
                                    "
                                    accept=".pdf,.png,.jpg,.jpeg"
                                  />
                                </div>
                              </div>
                              <div class="form-group form-group-docs">
                                <div
                                  class="col-lg-6 col-md-6 col-sm-12 form-group"
                                >
                                  <label class="form-control-label"
                                    >Company Directors Proof of Address</label
                                  >
                                  <input
                                    type="file"
                                    id="file"
                                    name="File Upload"
                                    (change)="
                                      fileUpload($event, 'bus_director_address')
                                    "
                                    accept=".pdf,.png,.jpg,.jpeg"
                                  />
                                </div>
                              </div>
                              <div class="form-group form-group-docs">
                                <div
                                  class="col-lg-6 col-md-6 col-sm-12 form-group"
                                >
                                  <label class="form-control-label"
                                    >Company Directors Proof of Tax Reg.</label
                                  >
                                  <input
                                    type="file"
                                    id="file"
                                    name="File Upload"
                                    (change)="
                                      fileUpload($event, 'bus_director_tax')
                                    "
                                    accept=".pdf,.png,.jpg,.jpeg"
                                  />
                                </div>
                              </div>
                              <div class="form-group form-group-docs">
                                <div
                                  class="col-lg-6 col-md-6 col-sm-12 form-group"
                                >
                                  <label class="form-control-label"
                                    >Company Directors' Resolution</label
                                  >
                                  <input
                                    type="file"
                                    id="file"
                                    name="File Upload"
                                    (change)="
                                      fileUpload(
                                        $event,
                                        'bus_director_resolution'
                                      )
                                    "
                                    accept=".pdf,.png,.jpg,.jpeg"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="button-group">
                <div class="btn-holder">
                  <button
                    class="btn btn-sign-up"
                    disabled
                    style="cursor: not-allowed"
                  >
                    Previous
                  </button>

                  <button class="btn btn-sign-up" (click)="submitFile()">
                    Next
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="currentStep === 'kycDetails'">
              <form class="" [formGroup]="userKYCForm">
                <div class="section-item">
                  <div class="section-item-card">
                    <div class="statement-download-h">
                      <h3 class="form-header">KYC Details</h3>
                    </div>
                    <div class="form-group">
                      <div>
                        <label class="form-control-label" for="cell"></label>
                        <div class="kyc-text-group">
                          <h4 class="kyc-text">
                            Click Set Up to start your KYC process
                          </h4>
                          <h4 class="kyc-text">
                            Keep in mind this is an important step to comply
                            with FSCA regulations.
                          </h4>
                          <h4 class="kyc-text">
                            Keep your phone ready as you will be redirected to a
                            secured verification portal.
                          </h4>
                          <div class="kyc-btn-holder">
                            <button
                              class="btn btn-sign-up"
                              (click)="addUserKYC(kycToken)"
                            >
                              Set Up
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="genericErrorMessage" class="clearfix">
                      <p class="error-mgs">{{ genericErrorMessage }}</p>
                    </div>
                    <div id="complycube-mount"></div>
                  </div>
                </div>
                <div class="button-group">
                  <div class="btn-holder">
                    <button
                      class="btn btn-sign-up"
                      disabled
                      style="cursor: not-allowed"
                    >
                      Previous
                    </button>
                    <button class="btn btn-sign-up" (click)="skipUserKyc()">
                      Skip
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div *ngIf="currentStep === 'agreements'">
              <form class="" [formGroup]="acceptFormDisclaimer">
                <div class="statement-download-h">
                  <h3>Accept the Disclaimer</h3>
                </div>
                <div class="section-item section-item-center agreement-box">
                  <div class="section-item-card-box section-item-center">
                    <div class="account-h">
                      <div class="submit-logo-h">
                        <img
                          class="submit-logo"
                          src="assets/images/blue_asset_logo_inline_white.png"
                        />
                      </div>
                      <div>
                        <p class="agreements-text">
                          Please accept and agree to the terms and conditions.
                        </p>
                      </div>
                      <div class="form-group" formArrayName="checkDisclaimer">
                        <div *ngIf="agreements?.length > 0">
                          <div
                            *ngFor="let agreement of agreements; let i = index"
                            class="form-check"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              [formControlName]="i"
                              id="flexCheckDisclaimer{{ i }}"
                            />
                            <label
                              class="form-check-label funds-txt"
                              for="flexCheckDisclaimer{{ i }}"
                            >
                              I accept and agree to the
                              <a
                                class="agreements-modal"
                                (click)="
                                  openAgreementModal(
                                    modalAgreements,
                                    agreement.content
                                  )
                                "
                              >
                                {{ agreement.label }}
                              </a>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="date-holder">
                        {{ date | date: "fullDate" }}
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="genericErrorMessage" class="clearfix">
                  <p class="error-mgs">{{ genericErrorMessage }}</p>
                </div>
                <div class="button-group">
                  <div class="btn-holder">
                    <button
                      class="btn btn-sign-up"
                      disabled
                      style="cursor: not-allowed"
                    >
                      Previous
                    </button>
                    <button
                      class="btn btn-sign-up"
                      (click)="addUserDisclaimerCheck()"
                    >
                      Done
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="success-holder" *ngIf="this.showSuccessMsg">
    <div class="section-holder-success">
      <div class="section-item-card">
        <div class="img-holder">
          <img
            class="logo"
            src="../../assets/images/blue_asset_logo_inline_white copy.png"
            alt=""
          />
        </div>
        <div class="statement-download-h">
          <h3 class="form-header">Success</h3>
        </div>
        <div class="text-holder-success center-align">
          <div class="success-text">
            Welcome to BlueAsset Group,<br />
            we will be in touch with you shortly.
          </div>
        </div>
      </div>
      <div class="button-container-success">
        <button class="btn btn-sign-up" (click)="goHome()">Okay</button>
      </div>
    </div>
  </div>

  <!-- Generic Modal Popup -->
  <ng-template #genericModal>
    <div class="modal-header">
      <h4 *ngIf="!isGenericError" class="modal-title pull-left">Success</h4>
      <h4 *ngIf="isGenericError" class="modal-title pull-left">Error</h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="hideModal()"
      ></button>
    </div>
    <div class="modal-body">
      {{ genericMessage }}
    </div>
    <div class="modal-footer">
      <button
        *ngIf="isGenericError === false"
        type="button"
        class="btn btn-primary"
        (click)="hideModal()"
      >
        Close
      </button>
      <button
        *ngIf="isGenericError === true"
        type="button"
        class="btn btn-danger"
        (click)="hideModal()"
      >
        Close
      </button>
    </div>
  </ng-template>

  <!-- Agreements Modal Popup -->
  <ng-template #modalAgreements>
    <div class="modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Agreement Details</h5>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modalRef?.hide()"
          ></button>
        </div>
        <div class="modal-body">
          <markdown [data]="selectedAgreementContent"></markdown>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            (click)="modalRef?.hide()"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
