import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class LoansService {
  private activateLoanKey = 'activateloan';
  private addLoanRevenueKey = 'addloanrevenue';
  private getLoanSummaryKey = 'getloansummary';
  private getLoanSummaryGroupKey = 'getloansummarygroup';
  private addloancollatoralforuserloansKey = 'addloancollatoralforuserloans';
  private withdrawloancollatoralforuserloansKey =
    'withdrawloancollatoralforuserloans';
  private deleteloanKey = 'deleteuserloan';
  private splitloanKey = 'splituserloan';
  private getloanauditKey = 'getloanaudit';
  private withdrawzeroloanKey = 'withdrawzeroloan';
  private settleloansKey = 'settleloans';
  private getloansforuserKey = 'getloansforuser';
  private getloansummaryforusersKey = 'getloansummaryforusers';
  private getloansummarygroupforgroupsKey = 'getloansummarygroupforgroups';

  constructor(private apiService: ApiService) {}

  getLoansForUser(data) {
    return this.apiService.post(this.getloansforuserKey, data);
  }

  activateLoan(data) {
    return this.apiService.post(this.activateLoanKey, data);
  }

  addLoanRevenue(data) {
    return this.apiService.post(this.addLoanRevenueKey, data);
  }

  getLoanSummary(data) {
    return this.apiService.post(this.getLoanSummaryKey, data);
  }

  getLoanSummaryForUsers(data) {
    return this.apiService.post(this.getloansummaryforusersKey, data);
  }

  getLoanSummaryGroupForGroups(data) {
    return this.apiService.post(this.getloansummarygroupforgroupsKey, data);
  }

  getLoanSummaryGroup(data) {
    return this.apiService.post(this.getLoanSummaryGroupKey, data);
  }

  settleLoans(data) {
    return this.apiService.post(this.settleloansKey, data);
  }

  addLoanCollatoralForUserLoans(data) {
    return this.apiService.post(this.addloancollatoralforuserloansKey, data);
  }

  withdrawLoanCollatoralForUserLoans(data) {
    return this.apiService.post(
      this.withdrawloancollatoralforuserloansKey,
      data
    );
  }

  withdrawLoan(data) {
    return this.apiService.post(this.withdrawzeroloanKey, data);
  }

  deleteLoan(data) {
    return this.apiService.post(this.deleteloanKey, data);
  }

  splitLoan(data) {
    return this.apiService.post(this.splitloanKey, data);
  }

  getLoanAudit(data) {
    return this.apiService.post(this.getloanauditKey, data);
  }
}
