import { SettingsService } from './../services/settings.service';
import { LoginService } from 'src/app/services/login.service';
import { RegisterService } from './../services/register.service';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input-gg';
import { CountdownComponent } from 'ngx-countdown';
import { DataService } from '../services/data.service';
import { LoaderService } from '../services/loader.service';
import { NgOtpInputComponent, NgOtpInputModule } from 'ng-otp-input';

declare var ComplyCube: any;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrl: './sign-up.component.scss',
})
export class SignUpComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('verifyModal') verifyModal: TemplateRef<any>;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('cd', { static: false }) public countdown: CountdownComponent;
  @ViewChild(NgOtpInputComponent, { static: false })
  ngOtpInput: NgOtpInputComponent;

  public modalRef: BsModalRef;
  public userDetailsForm: UntypedFormGroup;
  public userCellForm: UntypedFormGroup;
  public verifyCellForm: UntypedFormGroup;
  public userAddressForm: UntypedFormGroup;
  public userBankDetailsForm: UntypedFormGroup;
  public userFicaDetailsForm: UntypedFormGroup;
  public documentUpload: UntypedFormGroup;
  public userAuthenticatorForm: UntypedFormGroup;
  public verifyAuthenticatorForm: UntypedFormGroup;
  public userKYCForm: UntypedFormGroup;
  public acceptFormDisclaimer: UntypedFormGroup;

  public showSuccessMsg = false;

  public isLoggedIn: boolean;
  public isSouthAfrican: boolean = true;
  public selectedCountry: any;
  public userType: any;
  public isIndividual: any;
  public noSpaceCountry: any;

  public date = new Date();

  // Token
  public verifiedUserToken: any;
  public tempUserData: any = {
    token: '',
    userData: [],
  };

  // Display user details
  public username: string = '';
  public usersurname: string = '';
  public userId: number = null;
  public titles: any;
  public genders: any;
  public maritalStatus: any;
  public idTypes: any;

  private userChangedCitizenship = false;

  public politicallyExposedPersonValue: boolean;
  public prominentInfluentialPersonValue: boolean;

  //Business detaisls
  public businessName: any;
  public countryOfRegistration: any;

  // Address details
  public buildingName: any;
  public street: string = '';
  public suburb: string = '';
  public city: string = '';
  public province: string = '';
  public country: any;

  // Banking details
  public bankName: any = [];
  public accountHolderName: any = '';
  public accountNumber: any = '';
  public accountType: any;
  public branchCode: any = '';

  // FICA Details
  public fundsSource: any = [];
  public industry: any = [];
  public totalAnnualIncome: any = [];
  public totalBusinessRevenue: any = [];
  public politicallyExposedPersonType: any = [];
  public prominentInfluentialPersonType: any = [];

  // Google Auth (optional)
  public isShowGoogleAuthOTPForm: boolean;
  public qrCode: any;
  public googleAuthOtp = '';
  public googleOtpConfig = {
    allowNumbersOnly: true,
    length: 6,
    placeholder: '',
  };

  // Document Upload
  filesToUpload: { [key: string]: File } = {};
  public documents = [];
  public hasDocument = false;
  public fileErrors: { [key: string]: boolean } = {};

  // Individual Docs
  public indv_id: File | null = null;
  public indv_bank: File | null = null;
  public indv_tax: File | null = null;
  public indv_income: File | null = null;
  public indv_address: File | null = null;

  // Business Docs
  public bus_cipc: File | null = null;
  public bus_address: File | null = null;
  public bus_bank: File | null = null;
  public bus_tax: File | null = null;
  public bus_income: File | null = null;
  public bus_share_certificate: File | null = null;
  public bus_structure_chart: File | null = null;
  public bus_director_id: File | null = null;
  public bus_director_address: File | null = null;
  public bus_director_tax: File | null = null;
  public bus_director_resolution: File | null = null;

  private kycToken: string = '';

  public agreements: any[];
  public selectedAgreementContent = '';

  // Cell Valitation
  public sendCellOtp = false;
  public isCellValid = false;
  public cellOtp: string = '';

  // Cell Form
  public cell: number = null;
  public separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.SouthAfrica,
    CountryISO.UnitedStates,
  ];
  public cellOtpConfig = {
    allowNumbersOnly: true,
    length: 6,
    placeholder: '',
  };

  private didSubmitOtp = false;

  // Current Step
  public currentStep: any;
  public nextStep: any;

  // Modal
  public isGenericError = false;
  public genericErrorMessage = '';

  // Seperate form valid checkers
  isUserDetailsSubmitted = false;
  isUserAddressSubmitted = false;
  isUserBankDetailsSubmitted = false;
  isUserFicaDetailsSubmitted = false;
  isUserCellSubmitted = false;
  isUserCellOtpSubmitted = false;
  isUserAuthenticatorSubmitted = false;
  isUserKYCSubmitted = false;
  isAcceptFormSubmitted = false;
  public allowUserAdd: boolean;

  public modalMessage = '';
  public userToUpload: any;

  // Password error
  public passwordHasError = false;
  public passwordsDontMatch = false;
  public errorPasswordMgs = '';

  // Bank
  public supportedBanks: any = [];
  public selectedBank: any;
  public isBankNameValid = true;
  public sourceOfFunds: any;

  public allFilesValid: boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private loaderService: LoaderService,
    private modalService: BsModalService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private registerService: RegisterService,
    private loginService: LoginService,
    private ngOtpInputModule: NgOtpInputModule,
  ) {
    this.userDetailsForm = formBuilder.group({
      userTitle: ['', [Validators.required]],
      userDateOfBirth: ['', [Validators.required]],
      userId: ['', [Validators.required]],
      userIdType: [
        { value: 'userIdType', disabled: true },
        [Validators.required],
      ],
      userGender: ['', [Validators.required]],
      userCell: ['', [Validators.required]],
      userCountryOfBirth: ['', [Validators.required]],
      userMaritalStatus: ['', [Validators.required]],
      userCitizenship: ['', [Validators.required]],
      businessName: ['', [Validators.required]],
      countryOfRegistration: [
        { value: 'country', disabled: false },
        [Validators.required],
      ],
      userPassword: ['', [Validators.required]],
      userConfirmPassword: ['', [Validators.required]],
    });
    this.verifyCellForm = formBuilder.group({
      cellOtp: ['', [Validators.required]],
    });
    this.userAddressForm = formBuilder.group({
      buildingName: [''],
      street: ['', [Validators.required]],
      suburb: ['', [Validators.required]],
      city: ['', [Validators.required]],
      province: ['', [Validators.required]],
      country: [{ value: 'country', disabled: true }, [Validators.required]],
      postalCode: ['', [Validators.required]],
    });
    this.userBankDetailsForm = formBuilder.group({
      bankName: ['', [Validators.required]],
      accountHolderName: ['', [Validators.required]],
      accountNumber: ['', [Validators.required]],
      accountType: ['', [Validators.required]],
      branchCode: ['', [Validators.required]],
      taxNumber: ['', [Validators.required]],
    });
    this.userFicaDetailsForm = formBuilder.group({
      fundsSource: ['', [Validators.required]],
      industry: ['', [Validators.required]],
      totalAnnualIncome: ['', [Validators.required]],
      politicallyExposedPerson: ['', [Validators.required]],
      politicallyExposedPersonType: ['', [Validators.required]],
      prominentInfluentialPerson: ['', [Validators.required]],
      prominentInfluentialPersonType: ['', [Validators.required]],
    });
    this.documentUpload = formBuilder.group({
      file: ['', [Validators.required]],
    });
    this.userAuthenticatorForm = formBuilder.group({
      googleAuth: [''],
    });
    this.userKYCForm = formBuilder.group({
      launchKYC: ['', [Validators.required]],
    });
    this.acceptFormDisclaimer = this.formBuilder.group({
      checkDisclaimer: this.formBuilder.array([], Validators.required),
    });
    this.userFicaDetailsForm
      .get('politicallyExposedPerson')
      ?.valueChanges.subscribe((value) => {
        this.politicallyExposedPersonValue = value === true;

        if (this.politicallyExposedPersonValue) {
          this.userFicaDetailsForm
            .get('politicallyExposedPersonType')
            ?.enable();

          // Disable validators for prominentInfluentialPerson when politicallyExposedPerson is selected
          this.userFicaDetailsForm
            .get('prominentInfluentialPerson')
            ?.setValue(false);
          this.userFicaDetailsForm.get('prominentInfluentialPerson')?.disable();
          this.userFicaDetailsForm
            .get('prominentInfluentialPerson')
            ?.clearValidators();
          this.userFicaDetailsForm
            .get('prominentInfluentialPerson')
            ?.updateValueAndValidity();

          this.userFicaDetailsForm
            .get('prominentInfluentialPersonType')
            ?.setValue('n/a');
          this.userFicaDetailsForm
            .get('prominentInfluentialPersonType')
            ?.disable();
        } else {
          this.userFicaDetailsForm
            .get('politicallyExposedPersonType')
            ?.disable();
          this.userFicaDetailsForm
            .get('politicallyExposedPersonType')
            ?.setValue('n/a');

          // Re-enable validators for prominentInfluentialPerson
          this.userFicaDetailsForm.get('prominentInfluentialPerson')?.enable();
          this.userFicaDetailsForm
            .get('prominentInfluentialPerson')
            ?.setValidators([Validators.required]);
          this.userFicaDetailsForm
            .get('prominentInfluentialPerson')
            ?.updateValueAndValidity();
        }
      });
    this.userFicaDetailsForm
      .get('prominentInfluentialPerson')
      ?.valueChanges.subscribe((value) => {
        this.prominentInfluentialPersonValue = value === true;

        if (this.prominentInfluentialPersonValue) {
          this.userFicaDetailsForm
            .get('prominentInfluentialPersonType')
            ?.enable();
          this.userFicaDetailsForm
            .get('prominentInfluentialPersonType')
            ?.setValidators([Validators.required]);
        } else {
          this.userFicaDetailsForm
            .get('prominentInfluentialPersonType')
            ?.disable();
          this.userFicaDetailsForm
            .get('prominentInfluentialPersonType')
            ?.setValue('n/a');
        }
      });
  }

  ngOnInit() {
    this.isGenericError = false;
    this.didSubmitOtp = false;
    this.supportedBanks = this.dataService.getValrSupportedBanks();
    this.country = this.dataService.getCountries();
    this.titles = this.dataService.getTitles();
    this.genders = this.dataService.getGenders();
    this.maritalStatus = this.dataService.getMaritalStatus();
    this.accountType = this.dataService.getAccountTypes();
    this.idTypes = this.dataService.getIdTypes();
    this.sourceOfFunds = this.dataService.getSourceOfFunds();
    this.industry = this.dataService.getOccupations();
    this.totalAnnualIncome = this.dataService.getAnnualIncome();
    this.totalBusinessRevenue = this.dataService.getBusinessRevenue();
    this.politicallyExposedPersonType =
      this.dataService.getPoliticallyExposedPerson();
    this.prominentInfluentialPersonType =
      this.dataService.getProminentInfluentialPerson();
    this.allFilesValid = false;
    this.doPreCheck();
    this.checkLocalStorage();
    this.onBankNameChange();
    // To fix RuntimeError and remove header
    setTimeout(() => {
      this.loginService.updateLogin(true);
    });
    this.userDetailsForm
      .get('userCountryOfBirth')
      ?.valueChanges.subscribe((country) => {
        if (!this.userChangedCitizenship) {
          this.userDetailsForm.patchValue({ userCitizenship: country });
        }
      });
  }

  ngAfterViewInit() {}

  checkLocalStorage() {
    this.route.params.subscribe((params) => {
      this.verifiedUserToken = params['token'];
    });

    if (this.verifiedUserToken) {
      // Store the token in local storage
      localStorage.setItem('token', this.verifiedUserToken);
    }

    return this.verifiedUserToken;
  }

  doPreCheck() {
    this.checkLocalStorage();
    this.loaderService.updateLoader(true);
    this.registerService
      .getRegisterUserToken({
        token: this.verifiedUserToken,
      })
      .subscribe(
        (res) => {
          this.loaderService.updateLoader(false);
          if (res.success === true) {
            this.tempUserData = {
              token: this.verifiedUserToken,
              userData: res,
            };

            this.userType = res.response.type;
            this.selectedCountry = res.response.steps[2]?.fields.country;

            this.noSpaceCountry = this.selectedCountry.replace(/\s+/g, '');

            if (this.userType === 'individual') {
              this.isIndividual = true;
            } else {
              this.isIndividual = false;
            }

            if (this.selectedCountry === 'South Africa') {
              this.isSouthAfrican = true;
            } else {
              this.isSouthAfrican = false;
            }

            if (this.isSouthAfrican && this.isIndividual) {
              this.userDetailsForm.get('userIdType').setValue('id');
            } else if (!this.isSouthAfrican && this.isIndividual) {
              this.userDetailsForm.get('userIdType').setValue('passport');
            } else {
              this.userDetailsForm.get('userIdType').setValue('business');
            }

            if (this.isIndividual) {
              this.userDetailsForm
                .get('countryOfRegistration')
                ?.clearValidators();
              this.userDetailsForm.get('businessName')?.clearValidators();
            }

            this.userDetailsForm
              .get('countryOfRegistration')
              ?.updateValueAndValidity();
            this.userDetailsForm.get('businessName')?.updateValueAndValidity();

            // Check Current step
            this.navigateToStep(res.response.currentStep);
            // this.navigateToStep('googleAuth');

            // Get User or Business Country
            this.selectedCountry = res.response.steps[2]?.fields.country;

            // Check and Fill in data user has already provided
            this.username = res.response.steps[0]?.fields.name;
            this.usersurname = res.response.steps[0]?.fields.surname;
            this.userId = res.response.steps[0]?.fields.id;

            // Personal Details
            this.userDetailsForm.patchValue({
              userTitle: res.response.steps[0]?.fields?.title,
              username: res.response.steps[0]?.fields.name,
              usersurname: res.response.steps[0]?.fields.surname,
              userId: res.response.steps[0]?.fields.id,
              userCell: res.response.steps[0]?.fields?.cell,
              password: res.response.steps[0]?.fields?.password,
              userCountryOfBirth: this.selectedCountry,
              userDateOfBirth:
                res.response.steps[0]?.fields?.individualDetails?.dateOfBirth,
              userGender:
                res.response.steps[0]?.fields?.individualDetails?.gender || [],
              userMaritalStatus:
                res.response.steps[0]?.fields?.individualDetails
                  ?.maritalStatus || [],
              userCitizenship:
                res.response.steps[0]?.fields?.individualDetails?.citizenship,
              businessName:
                res.response.steps[0]?.fields?.businessDetail?.name || '',
              countryOfRegistration:
                res.response.steps[0]?.fields?.businessDetail
                  ?.countryOfRegistration || '',
            });
            // Address Details
            this.userDetailsForm.patchValue({
              businessName:
                res.response.steps[0]?.fields?.businessDetails?.name,
              countryOfRegistration: '',
              username: res.response.steps[0]?.fields?.businessDetails?.name,
              usersurname: res.response.steps[0]?.fields?.surname,
              userId: res.response.steps[0]?.fields?.id,
              userCountryOfBirth: this.selectedCountry,
            });
            this.userAddressForm.patchValue({
              buildingName: res.response.steps[2]?.fields.buildingName || '',
              street: res.response.steps[2]?.fields.street || '',
              suburb: res.response.steps[2]?.fields.suburb || '',
              city: res.response.steps[2]?.fields.city || '',
              province: res.response.steps[2]?.fields.province || '',
              country:
                res.response.steps[2]?.fields.country || this.selectedCountry,
              postalCode: res.response.steps[2]?.fields.postalCode || '',
            });

            if (
              res.response.steps[1]?.fields.country &&
              res.response.steps[1]?.fields.country !== 'South Africa'
            ) {
              this.isSouthAfrican = false;
            }

            if (res.response.steps[3]?.fields.bankName === 'N/A') {
              this.userBankDetailsForm.patchValue({
                bankName: [],
                accountHolderName: '',
                accountNumber: '',
                accountType: '',
                branchCode: '',
                fundsSource: [],
              });
            } else {        
                    
              this.userBankDetailsForm.patchValue({
                bankName: res.response.steps[3]?.fields.bankName || [],
                accountHolderName:
                  res.response.steps[3]?.fields.accountHolderName || '',
                accountNumber:
                  res.response.steps[3]?.fields.accountNumber || '',
                accountType: res.response.steps[3]?.fields.accountType || '',
                branchCode: res.response.steps[3]?.fields.branchCode || '',
                taxNumber: res.response.steps[3]?.fields.taxNumber || '',
              });
            }

            this.userFicaDetailsForm.patchValue({
              fundsSource: res.response.steps[4]?.fields.sourceOfIncome || [],
              industry: res.response.steps[4]?.fields.industry || [],
              totalAnnualIncome:
                res.response.steps[4]?.fields.totalAnnualIncome || [],
            });

            this.qrCode = res.response.steps[5]?.fields.qrCode;

            this.kycToken = res.response.steps[6]?.fields.kycToken;

            this.agreements = res.response.steps[8]?.fields.agreements;

            this.addAgreementControls(this.agreements);
          } else {
            this.router.navigate(['/home']);
          }
        },

        (err) => {
          this.loaderService.updateLoader(false);
          this.router.navigate(['/home']);
        },
      );
  }

  addUserDetails() {
    this.isUserDetailsSubmitted = true;
    this.loaderService.updateLoader(true);

    // Password validation logic
    const password = this.userDetailsForm.get('userPassword').value;
    const confirmPassword = this.userDetailsForm.get(
      'userConfirmPassword',
    ).value;

    if (password !== confirmPassword) {
      this.loaderService.updateLoader(false);
      this.isGenericError = true;
      this.genericErrorMessage = 'Passwords do not match!';
      return;
    }

    if (password.length < 8) {
      this.loaderService.updateLoader(false);
      this.isGenericError = true;
      this.genericErrorMessage = 'Password must be at least 8 characters long!';
      return;
    }

    const numberChar = /\d/;
    if (!numberChar.test(password)) {
      this.loaderService.updateLoader(false);
      this.isGenericError = true;
      this.genericErrorMessage = 'Password must contain at least 1 number!';
      return;
    }

    const specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (!specialChars.test(password)) {
      this.loaderService.updateLoader(false);
      this.isGenericError = true;
      this.genericErrorMessage =
        'Password must contain at least 1 special character!';
      return;
    }

    if (/\s/g.test(password)) {
      this.loaderService.updateLoader(false);
      this.isGenericError = true;
      this.genericErrorMessage = 'Password cannot contain any whitespaces!';
      return;
    }

    // Clear error message if all validations pass
    this.isGenericError = false;
    this.genericErrorMessage = '';

    if (this.userDetailsForm.invalid) {
      this.loaderService.updateLoader(false);
      return;
    }

    const individualDetails = {
      dateOfBirth: this.userDetailsForm.get('userDateOfBirth').value,
      gender: this.userDetailsForm.get('userGender').value,
      countryOfBirth: this.userDetailsForm.get('userCountryOfBirth').value,
      citizenship: this.userDetailsForm.get('userCitizenship').value,
      maritalStatus: this.userDetailsForm.get('userMaritalStatus').value,
    };

    const businessDetails = {
      name: this.userDetailsForm.get('businessName').value || '',
      countryOfRegistration:
        this.userDetailsForm.get('countryOfRegistration').value || '',
    };

    const userDetails = {
      name: 'personalDetails',
      fields: {
        title: this.userDetailsForm.get('userTitle').value,
        name: this.userDetailsForm.get('username') || this.username,
        suranme: this.userDetailsForm.get('usersurname') || this.usersurname,
        id: this.userDetailsForm.get('userId').value,
        idType: this.userDetailsForm.get('userIdType').value,
        cell: this.userDetailsForm.get('userCell').value?.e164Number,
        password: password,
        individualDetails: individualDetails,
        businessDetails: businessDetails,
      },
    };

    this.registerService.addToRegisterUser(userDetails).subscribe(
      (res) => {
        if (res.success === true) {
          this.loaderService.updateLoader(false);
          this.isGenericError = false;
          this.navigateToStep(res.response.nextStep);
        } else {
          this.isGenericError = true;
          this.genericErrorMessage =
            res.message || 'Something went wrong. Please try again.';
        }
        this.loaderService.updateLoader(false);
      },
      (err) => {
        this.isGenericError = true;
        this.genericErrorMessage =
          err.message || 'Something went wrong. Please try again.';
        this.loaderService.updateLoader(false);
      },
    );

    this.genericErrorMessage = '';
  }

  onOtpChange(input) {
    this.cellOtp = input;
    // Auto submit OTP of filled in
    if (this.cellOtp.length !== 6) {
      this.didSubmitOtp = false;
    }

    if (this.didSubmitOtp === false) {
      // Auto submit OTP of filled in
      if (this.cellOtp.length === 6) {
        this.didSubmitOtp = true;
        this.addCellOtp();
      }
    }
  }

  addCellOtp() {
    this.isUserCellOtpSubmitted = true;
    this.loaderService.updateLoader(true);
    this.isGenericError = false;

    const otpPayload = {
      name: 'cellValidation',
      fields: {
        otp: this.cellOtp,
      },
    };

    this.registerService.addToRegisterUser(otpPayload).subscribe(
      (res) => {
        if (res.success === true) {
          this.isGenericError = false;
          this.navigateToStep(res.response.nextStep);
        } else {
          this.isGenericError = true;
          this.genericErrorMessage =
            res.message || 'Invalid OTP. Please try again.';
        }
        this.loaderService.updateLoader(false);
        this.verifyCellForm.reset();
      },
      (err) => {
        this.isGenericError = true;
        this.genericErrorMessage =
          err.message || 'Unable to verify OTP. Please try again later.';
        this.verifyCellForm.reset();
        this.loaderService.updateLoader(false);
      },
    );
  }

  ResendOtp() {
    this.loaderService.updateLoader(true);
    this.isGenericError = false;

    const resendOtp = {
      name: 'resendOtp',
      fields: {},
    };

    this.countdown.restart();

    this.registerService.addToRegisterUser(resendOtp).subscribe(
      (res) => {
        if (res.success === true) {
          this.countdown.restart();
          this.isGenericError = false;
          this.navigateToStep(this.currentStep);
          this.verifyCellForm.enable();
          this.verifyCellForm.reset();
        } else {
          this.isGenericError = true;
          this.genericErrorMessage =
            res.message || 'An error has occured, please try again.';
        }
        this.loaderService.updateLoader(false);
      },
      (err) => {
        this.isGenericError = true;
        this.genericErrorMessage =
          err.message || 'Unable to send OTP. Please try again later.';
        this.loaderService.updateLoader(false);
      },
    );
  }

  addUserAddress() {
    this.isUserAddressSubmitted = true;
    this.loaderService.updateLoader(true);
    this.isGenericError = false;

    if (this.userAddressForm.invalid) {
      this.loaderService.updateLoader(false);
      return;
    }

    const addressDetails = {
      name: 'addressDetails',
      fields: {
        buildingName: this.userAddressForm.get('buildingName').value,
        street: this.userAddressForm.get('street').value,
        suburb: this.userAddressForm.get('suburb').value,
        city: this.userAddressForm.get('city').value,
        province: this.userAddressForm.get('province').value,
        country: this.userAddressForm.get('country').value,
        postalCode: this.userAddressForm.get('postalCode').value,
      },
    };

    // Save the address details
    this.registerService.addToRegisterUser(addressDetails).subscribe(
      (res) => {
        if (res.success === true) {
          this.isGenericError = false;

          // Set country and update bank details based on it
          this.country = this.userAddressForm.get('country').value;

          if (this.country !== 'South Africa') {
            this.isSouthAfrican = false;
            this.addUserBanking();
          } else {
            this.isSouthAfrican = true;
            this.isUserBankDetailsSubmitted = false;
            this.navigateToStep(res.response.nextStep);
          }
        } else {
          this.isGenericError = true;
          this.genericErrorMessage =
            res.message || 'Something went wrong. Please try again.';
        }
        this.loaderService.updateLoader(false);
      },
      (err) => {
        this.isGenericError = true;
        this.genericErrorMessage =
          err.message || 'Something went wrong. Please try again.';
        this.loaderService.updateLoader(false);
      },
    );
  }

  addUserBanking() {
    this.isUserBankDetailsSubmitted = true;
    this.loaderService.updateLoader(true);
    this.isGenericError = false;

    let accountDetails: any;

    if (this.isSouthAfrican) {
      if (this.userBankDetailsForm.invalid) {
        this.loaderService.updateLoader(false);
        return;
      }
      accountDetails = {
        name: 'accountDetails',
        fields: {
          bankName: this.userBankDetailsForm.get('bankName').value,
          accountHolderName:
            this.userBankDetailsForm.get('accountHolderName').value,
          accountNumber: this.userBankDetailsForm.get('accountNumber').value,
          accountType: this.userBankDetailsForm.get('accountType').value,
          branchCode: this.userBankDetailsForm.get('branchCode').value,
          taxNumber: this.userBankDetailsForm.get('taxNumber').value,
        },
      };
    } else {
      accountDetails = {
        name: 'accountDetails',
        fields: {
          bankName: 'N/A',
          accountHolderName: 'N/A',
          accountNumber: 'N/A',
          accountType: 'N/A',
          branchCode: 'N/A',
          taxNumber: 'N/A',
        },
      };
    }

    this.registerService.addToRegisterUser(accountDetails).subscribe(
      (res) => {

        if (res.success === true) {
          this.isGenericError = false;
          this.navigateToStep(res.response.nextStep);
        } else {
          this.isGenericError = true;
          this.genericErrorMessage =
            res.message || 'Something went wrong. Please try again.';
        }
        this.loaderService.updateLoader(false);
      },
      (err) => {
        this.isGenericError = true;
        this.genericErrorMessage =
          err.message || 'Something went wrong. Please try again.';
        this.loaderService.updateLoader(false);
      },
    );
  }

  onBankNameChange(): void {
    this.userBankDetailsForm
      .get('bankName')
      ?.valueChanges.subscribe((selectedBankDisplayName) => {
        const selectedBank = this.supportedBanks.find(
          (bank) => bank.displayName === selectedBankDisplayName,
        );

        if (selectedBank) {
          this.userBankDetailsForm.patchValue({
            branchCode: selectedBank.defaultBranchCode,
          });
        } else {
          this.userBankDetailsForm.patchValue({
            branchCode: '',
          });
        }
      });
  }

  addUserFicaDetails() {
    this.isUserFicaDetailsSubmitted = true;
    this.loaderService.updateLoader(true);
    this.isGenericError = false;

    if (this.userFicaDetailsForm.invalid) {
      this.loaderService.updateLoader(false);
      return;
    }

    const ficaDetails = {
      name: 'ficaDetails',
      fields: {
        sourceOfIncome: this.userFicaDetailsForm.get('fundsSource').value,
        industry: this.userFicaDetailsForm.get('industry').value,
        totalAnnualIncome:
          this.userFicaDetailsForm.get('totalAnnualIncome').value,
        politicallyExposedPerson: this.userFicaDetailsForm.get(
          'politicallyExposedPerson',
        ).value,
        politicallyExposedPersonType: this.userFicaDetailsForm.get(
          'politicallyExposedPersonType',
        ).value,
        prominentInfluentialPerson: this.userFicaDetailsForm.get(
          'prominentInfluentialPerson',
        ).value,
        prominentInfluentialPersonType: this.userFicaDetailsForm.get(
          'prominentInfluentialPersonType',
        ).value,
      },
    };

    // Save the address details
    this.registerService.addToRegisterUser(ficaDetails).subscribe(
      (res) => {
        if (res.success === true) {
          this.loaderService.updateLoader(false);
          this.isGenericError = false;
          this.navigateToStep(res.response.nextStep);
          this.qrCode = res.response.qrCode;
        } else {
          this.isGenericError = true;
          this.genericErrorMessage =
            res.message || 'Something went wrong. Please try again.';
        }
        this.loaderService.updateLoader(false);
      },
      (err) => {
        this.isGenericError = true;
        this.genericErrorMessage =
          err.message || 'Something went wrong. Please try again.';
        this.loaderService.updateLoader(false);
      },
    );
  }

  onAuthOtpChange(input: string) {
    this.googleAuthOtp = input;
    // Auto submit OTP of filled in
    if (this.googleAuthOtp.length !== 6) {
      this.didSubmitOtp = false;
    }

    if (this.didSubmitOtp === false) {
      // Auto submit OTP of filled in
      if (this.googleAuthOtp.length === 6) {
        this.didSubmitOtp = true;
        this.addUserAuth();
      }
    }
  }

  addUserAuth() {
    this.qrCode = this.qrCode;
    this.isUserAuthenticatorSubmitted = true;
    this.loaderService.updateLoader(true);
    this.isGenericError = false;

    let googleAuth = {
      name: 'googleAuth',
      fields: {
        otp: this.googleAuthOtp,
      },
    };

    this.registerService.addToRegisterUser(googleAuth).subscribe(
      (res) => {
        if (res.success === true) {
          this.kycToken = res.response.kycToken;
          this.navigateToStep(res.response.nextStep);
          this.isGenericError = false;
        } else {
          this.isGenericError = true;
          this.genericErrorMessage =
            res.message || 'Invalid OTP. Please try again.';
        }
        this.loaderService.updateLoader(false);
      },
      (err) => {
        this.isGenericError = true;
        this.genericErrorMessage =
          err.message || 'Something went wrong. Please try again.';
        this.loaderService.updateLoader(false);
      },
    );
  }

  async fileUpload(event: any, name: string) {
    let files = event?.target?.files;
    this.hasDocument = false;

    if (!files || files.length < 1) {
      return;
    }

    let maxUpload = 10 * 1024 * 1024; // 10MB limit

    this.filesToUpload = this.filesToUpload || {};

    let file: File = files[0];
    if (file.size > maxUpload) {
      this.isGenericError = true;
      return;
    }
    this.filesToUpload[name] = file;
    this.hasDocument = true;
  }

  submitFile() {
    const formData: FormData = new FormData();

    const fileKeys = this.isIndividual
      ? ['indv_id', 'indv_bank', 'indv_tax', 'indv_income', 'indv_address']
      : [
          'bus_cipc',
          'bus_address',
          'bus_bank',
          'bus_tax',
          'bus_income',
          'bus_share_certificate',
          'bus_structure_chart',
          'bus_director_id',
          'bus_director_address',
          'bus_director_tax',
          'bus_director_resolution',
        ];

    let hasFile = false;

    // Loop through expected file keys and append only existing files
    fileKeys.forEach((key) => {
      if (this.filesToUpload[key]) {
        formData.append(key, this.filesToUpload[key]);
        hasFile = true;
      }
    });

    this.loaderService.updateLoader(true);

    let documentUpload = {
      name: 'documentUpload',
      fields: {},
    };

    formData.append('data', JSON.stringify(documentUpload));

    this.registerService.addToRegisterUser(formData).subscribe(
      (res) => {
        if (res.success) {
          this.navigateToStep(res.response.nextStep);
          this.isGenericError = false;
          this.genericErrorMessage = '';
          this.kycToken = res.response.kycToken;
        } else {
          this.isGenericError = true;
          this.genericErrorMessage =
            res.message || 'Something went wrong. Please try again.';
        }

        // Reset after upload
        this.filesToUpload = {};
        this.hasDocument = false;
        this.loaderService.updateLoader(false);
      },
      (err) => {
        console.error('Upload failed:', err);
        this.loaderService.updateLoader(false);
        this.isGenericError = true;
        this.filesToUpload = {};
      },
    );
  }

  addUserKYC() {
    this.isUserKYCSubmitted = true;
    this.isGenericError = false;
    this.genericErrorMessage = '';

    let complyCube;
    complyCube = ComplyCube.mount({
      token: this.kycToken,
      branding: {
        appearance: {
          primaryButtonColor: '#0089d8',
          primaryButtonHoverColor: '#00a2ff',
          primaryButtonBorderColor: '#00a2ff',
          primaryButtonTextColor: '#ffffff',
          secondaryButtonColor: '#4f5050',
          secondaryButtonHoverColor: '#6c757d',
          secondaryButtonBorderColor: '#6c757d',
          secondaryButtonTextColor: '#ffffff',
        },
        logo: {
          lightLogoUrl:
            'https://blueassetgroup.com/assets/images/home-logo-white.png',
          darkLogoUrl:
            'https://blueassetgroup.com/assets/images/home-logo-black.png',
        },
        textBrand: 'BlueAsset Group',
      },
      stages: [
        'intro',
        {
          name: 'documentCapture',
          options: {
            crossDeviceOnly: true,
            documentTypes: {
              passport: true,
              driving_license: false,
              national_identity_card: true,
              residence_permit: false,
            },
          },
        },
        {
          name: 'faceCapture',
          options: {
            mode: 'photo',
          },
        },
        'completion',
      ],
      onComplete: (data: any) => {
        this.loaderService.updateLoader(true);
        let kycDetails = {
          name: 'kycDetails',
          fields: {
            kycData: data,
          },
        };

        this.registerService.addToRegisterUser(kycDetails).subscribe(
          (res) => {
            if (res.success === true) {
              this.navigateToStep(res.response.nextStep);
              this.agreements = res.response.agreements;
              // Populate form dynamically
              this.populateDisclaimers();
            } else {
              this.isGenericError =
                res.message || 'Something went wrong. Please try again.';
            }
            this.loaderService.updateLoader(false);

            complyCube.updateSettings({ isModalOpen: false });
            complyCube.unmount();
            this.changeDetectorRef.detectChanges();
          },
          (error) => {
            this.isGenericError = true;
            this.loaderService.updateLoader(false);

            complyCube.updateSettings({ isModalOpen: false });
            complyCube.unmount();
            this.changeDetectorRef.detectChanges();
          },
        );
      },
      onModalClose: () => {
        complyCube.updateSettings({ isModalOpen: false });
        complyCube.unmount();
        this.changeDetectorRef.detectChanges();
      },
      onExit: () => {
        complyCube.updateSettings({ isModalOpen: false });
        complyCube.unmount();
        this.changeDetectorRef.detectChanges();
      },
      onError: ({ type, message }) => {
        if (type === 'token_expired') {
        }
        complyCube.updateSettings({ isModalOpen: false });
        complyCube.unmount();
        this.changeDetectorRef.detectChanges();
      },
    });
  }

  createKycChecks(kycData: any) {
    this.loaderService.updateLoader(true);
    let kycDetails = {
      name: 'kycDetails',
      fields: {
        kycData: kycData,
      },
    };

    this.registerService.addToRegisterUser(kycDetails).subscribe(
      (res) => {
        if (res.success === true) {
          this.navigateToStep(res.response.nextStep);
          this.agreements = res.response.agreements;
          // Populate form dynamically
          this.populateDisclaimers();
        } else {
          this.isGenericError =
            res.message || 'Something went wrong. Please try again.';
        }
        this.loaderService.updateLoader(false);
      },
      (error) => {
        this.isGenericError = true;
        this.loaderService.updateLoader(false);
      },
    );
  }

  skipUserKyc() {
    this.loaderService.updateLoader(true);
    let kycDetails = {
      name: 'kycDetails',
      fields: {},
    };

    this.registerService.addToRegisterUser(kycDetails).subscribe(
      (res) => {
        if (res.success === true) {
          this.navigateToStep(res.response.nextStep);
          this.agreements = res.response.agreements;
          // Populate form dynamically
          this.populateDisclaimers();
          this.isGenericError = false;
        } else {
          this.isGenericError =
            res.message || 'Something went wrong. Please try again.';
        }
        this.loaderService.updateLoader(false);
      },
      (error) => {
        this.isGenericError = true;
        this.loaderService.updateLoader(false);
      },
    );
  }

  // Adding all agreements from API response
  populateDisclaimers() {
    const formArray = this.acceptFormDisclaimer.get(
      'checkDisclaimer',
    ) as FormArray;
    this.agreements.forEach(() => {
      formArray.push(this.formBuilder.control(false, Validators.requiredTrue));
    });
  }

  // Dynamically setting and send through unique agreements
  addAgreementControls(agreements: any[] = []) {
    if (!agreements || !Array.isArray(agreements)) {
      console.error('Agreements is undefined or not an array', agreements);
      return;
    }

    const controlArray = this.acceptFormDisclaimer.get(
      'checkDisclaimer',
    ) as FormArray;
    agreements.forEach(() => {
      controlArray.push(
        this.formBuilder.control(false, Validators.requiredTrue),
      );
    });
  }

  // Modal for terms and conditions
  openAgreementModal(template: TemplateRef<any>, content: string) {
    this.selectedAgreementContent = content;
    this.modalRef = this.modalService.show(template);
  }

  addUserDisclaimerCheck() {
    this.isAcceptFormSubmitted = true;
    this.loaderService.updateLoader(true);
    this.isGenericError = false;
    if (this.acceptFormDisclaimer.invalid) {
      this.loaderService.updateLoader(false);
      this.isGenericError = true;
      this.genericErrorMessage =
        'Please read and accept the Agreements and/or Disclaimers.';
      return;
    }

    let fields: { [key: string]: boolean } = {};
    this.agreements.forEach((agreement: any, index: number) => {
      fields[agreement.type] =
        this.acceptFormDisclaimer.value.checkDisclaimer[index] === true;
    });

    let disclaimer = {
      name: 'agreements',
      // May contains multiple agreement fields
      fields: fields,
    };

    this.registerService.addToRegisterUser(disclaimer).subscribe(
      (res) => {
        if (res.success === true) {
          this.showSuccessMsg = true;
          this.isGenericError = false;
        } else {
          this.isGenericError =
            res.message || 'Something went wrong. Please try again.';
        }
        this.loaderService.updateLoader(false);
      },
      (error) => {
        this.isGenericError = true;
        this.loaderService.updateLoader(false);
      },
    );
  }

  validateSelectedBank() {
    // Get the selected bank name from the form control
    const selectedBankName = this.userBankDetailsForm.get('bankName').value;    
    this.supportedBanks = this.dataService.getValrSupportedBanks();
    this.selectedBank = this.supportedBanks.find(
      (bank) => bank.displayName === selectedBankName,
    );
    // Set to true if a bank is found, false otherwise
    this.isBankNameValid = !!this.selectedBank;

    if (this.isBankNameValid) {
      let defaultBranchCode = this.selectedBank?.defaultBranchCode;
      if (selectedBankName !== 'International') {
        this.userBankDetailsForm.controls['branchCode'].setValue(
          defaultBranchCode,
        );
        this.userBankDetailsForm.controls['branchCode'].disable();
      }

      if (defaultBranchCode === undefined || defaultBranchCode === '') {
        this.userBankDetailsForm.controls['branchCode'].enable();
      }
    }
  }

  countdownEvent(event) {
    if (event.action === 'done') {
      this.verifyCellForm.disable();
      this.isGenericError = true;
      this.genericErrorMessage = 'Please request a new OTP!';
    }
  }

  hideModal() {
    this.modalRef.hide();

    if (this.isGenericError === false) {
      this.router.navigate(['/home']);
    }
  }

  showGoogleAuthOtpForm() {
    this.googleAuthOtp = '';
    this.isShowGoogleAuthOTPForm = true;
  }

  navigateToStep(targetStep: string) {
    if (this.canNavigateToStep(targetStep)) {
      this.currentStep = targetStep;
    }
  }

  canNavigateToStep(targetStep: string): boolean {
    if (targetStep === 'accountDetails' && !this.isSouthAfrican) {
      return false;
    }
    return true;
  }

  goHome() {
    this.loaderService.updateLoader(true);
    this.router.navigate(['/za']);
    this.loaderService.updateLoader(false);
  }

  cancelSignUp() {
    this.loaderService.updateLoader(true);
    this.userDetailsForm.reset();
    this.userAddressForm.reset();
    this.userBankDetailsForm.reset();
    this.userFicaDetailsForm.reset();
    this.userAuthenticatorForm.reset();
    this.userKYCForm.reset();
    this.acceptFormDisclaimer.reset();
    this.router.navigate(['/za']);
    this.loaderService.updateLoader(false);
  }

  previousStep(): void {
    this.isGenericError = false;
    switch (this.currentStep) {
      case 'agreements':
        this.currentStep = 'kycDetails';
        break;
      case 'kycDetails':
        this.currentStep = 'documentUpload';
        break;
      case 'documentUpload':
        this.currentStep = 'googleAuth';
        break;
      case 'googleAuth':
        this.currentStep = 'ficaDetails';
        break;
      case 'ficaDetails':
        if (this.isSouthAfrican) {
          this.currentStep = 'accountDetails';
        } else {
          this.currentStep = 'addressDetails';
        }
        break;
      case 'accountDetails':
        this.currentStep = 'addressDetails';
        break;
      case 'cellValidation':
        this.currentStep = 'personalDetails';
        break;
      case 'addressDetails':
        this.currentStep = 'personalDetails';
        break;
    }
  }

  ngOnDestroy() {
    this.loginService.updateLogin(false);
  }
}
