import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class QuotesService {
  private generatequoteKey = 'generatequote';
  private deletequoteKey = 'deletequote';
  private getquotesKey = 'getquotes';

  constructor(private apiService: ApiService) {}

  generateQuote(data) {
    return this.apiService.post(this.generatequoteKey, data);
  }

  deleteQuote(data) {
    return this.apiService.post(this.deletequoteKey, data);
  }

  getQuotes(data) {
    return this.apiService.post(this.getquotesKey, data);
  }
}
