import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class FlexiService {
  private flexisummaryKey = 'getflexisummary';
  private updateflexipayoutKey = 'updateflexipayout';
  private withdrawfromflexiincomeKey = 'withdrawfromflexiincome';

  constructor(private apiService: ApiService) {}

  getFlexiSummary(data) {
    return this.apiService.post(this.flexisummaryKey, data);
  }

  updateFlexiPayout(data) {
    return this.apiService.post(this.updateflexipayoutKey, data);
  }

  withdrawFromFlexiIncome(data) {
    return this.apiService.post(this.withdrawfromflexiincomeKey, data);
  }

}
