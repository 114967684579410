import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-waves',
  templateUrl: './waves.component.html',
  styleUrls: ['./waves.component.scss'],
})
export class WavesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
