import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class ApiService {
  private backendApi = environment.api;
  private urlPath = '';

  private urls = {
    login: '/authenticate/login',
    userecoverycode: '/authenticate/use-recovery-code',
    sendemail: '/contact/send-inquiry-email',
    sidebar: '/config/get-side-bar',
    getassetprice: '/config/get-asset-price',
    getuser: '/users/get-user',
    getusers: '/users/get-users',
    getuserbyid: '/users/get-user-by-id',
    getsummary: '/summary/get-summary-data',
    calculateinvestment: '/calculator/calculate-investment',
    updatenotificationread: '/summary/update-notification-read',
    getusersforrole: '/users/get-users-for-role',
    deleteusers: '/users/delete-users',
    downloadusers: '/users/download-users',
    movedeceasedusertouser: '/users/move-deceased-user-to-user',
    getuserlinking: '/users/get-user-linking',
    adduser: '/users/add-user',
    migrateuser: '/users/migrate-user',
    updateuser: '/users/update-user',
    loginotp: '/authenticate/otp',
    resetpassword: '/authenticate/reset-password',
    register: '/authenticate/register',
    verifyemail: '/authenticate/verify-email',
    sendresetpasswordemail: '/users/send-reset-password-email',
    sendrecoverycode: '/users/send-recovery-code',
    adduserviaintroducercheck: '/users/add-user-via-introducer-check',
    querysignupuserlist: '/users/query-signup-user-list',
    adduserviaintroducer: '/users/add-user-via-introducer',
    verifyuser: '/users/verify-user-via-introducer',
    searchuserbyquery: '/users/search-user-by-query',
    getmyprofile: '/users/get-my-profile',
    getuserportfolio: '/users/get-user-portfolio',
    downloadsummarystatementforuser:
      '/users/download-summary-statement-for-user',

    // register
    presignupuser: '/register/pre-sign-up',
    presignupviaintroducer: '/register/pre-sign-up-via-introducer',
    signupuser: '/register/sign-up',
    submituserstep: '/register/submit-step',

    // commissions
    getcommissionsummary: '/commissions/get-commission-summary',
    getcommissionforagent: '/commissions/get-commission-for-agent',
    getcommissionforintroducer: '/commissions/get-commission-for-introducer',
    getcommissionformyself: '/commissions/get-commission-for-myself',

    // banking
    getbankingdepositdetails: '/config/get-banking-deposit-details',

    // documents
    uploadbusinessdocument: '/documents/upload-business-document',
    getbusinessdocuments: '/documents/get-business-documents',
    changedocumentstate: '/documents/change-document-state',
    downloaddocument: '/documents/download-document',
    uploadclientdocument: '/documents/upload-client-document',
    getdocumentsforusers: '/documents/get-documents-for-users',

    // income
    getincomesummary: '/income/get-income-summary',
    updateincomepayout: '/income/update-income-payout',

    // flexi
    getflexisummary: '/flexi/get-flexi-summary',
    updateflexipayout: '/flexi/update-flexi-payout',
    withdrawfromflexiincome: '/flexi/withdraw-from-flexi-income',

    // smart
    getsmartsummary: '/smart/get-smart-summary',
    updatesmartpayout: '/smart/update-smart-payout',
    withdrawfromsmartincome: '/smart/withdraw-from-smart-income',

    // retire
    getretiresummary: '/retire/get-retire-summary',
    updateretirepayout: '/retire/update-retire-payout',
    withdrawfromretireincome: '/retire/withdraw-from-retire-income',

    // accumulation
    getaccumulationsummary: '/accumulation/get-accumulation-summary',

    // loans
    activateloan: '/loans/activate-loan',
    activategrouploan: '/loans/activate-group-loan',
    activategrouploanformyself: '/loans/activate-group-loan-for-myself',
    addloanrevenue: '/loans/add-loan-revenue',
    getloansummary: '/loans/get-loan-summary',
    getloansummaryforusers: '/loans/get-loan-summary-for-users',
    getloansummarygroupforgroups: '/loans/get-loan-summary-group-for-groups',
    getloansummarygroup: '/loans/get-loan-summary-group',
    addloancollatoral: '/loans/add-loan-collatoral',
    withdrawzeroloan: '/loans/withdraw-zero-loan',
    addloancollatoralforuserloans: '/loans/add-loan-collatoral-for-user-loans',
    withdrawloancollatoralforuserloans:
      '/loans/withdraw-loan-collatoral-for-user-loans',
    deleteuserloan: '/loans/delete-loan-by-id',
    splituserloan: '/loans/split-loan-by-id',
    getloanaudit: '/loans/get-loan-audit-data',
    settleloans: '/loans/settle-loans',
    getloansforuser: '/loans/get-loans-for-user',

    // wallets
    getwallettypes: '/wallets/get-wallet-types',
    getwalletbalances: '/wallets/get-wallet-balances',
    modifycommsoncommission: '/wallets/modify-comms-on-commission',
    modifyapplytaxornot: '/wallets/modify-apply-tax-or-not',
    movecommstoreportbalance: '/wallets/move-comms-to-report-balance',
    querycommsreportbalance: '/wallets/query-comms-report-balance',
    approvecommsreportbalance: '/wallets/approve-comms-report-balance',
    searchuserbyquerywallet: '/wallets/search-user-by-query',
    walletaddwithdrawaddress: '/wallets/add-withdraw-address-to-wallet',
    withdrawfromwallet: '/wallets/withdraw-from-wallet',
    withdrawfromwalletpreview: '/wallets/withdraw-from-wallet-preview',
    requestdepositaddressforwallet:
      '/wallets/request-deposit-address-for-wallet',
    withdrawfromwallettobank: '/wallets/withdraw-from-wallet-to-bank',
    peertopeertransfer: '/wallets/peer-to-peer-transfer',
    swapassettousdtquote: '/wallets/swap-asset-to-usdt-quote',
    swapassettousdt: '/wallets/swap-asset-to-usdt',
    getwalletsformyself: '/wallets/get-wallets-for-myself',

    // settings
    updatepassword: '/settings/update-password',
    enabledisabletwofaotp: '/settings/enable-disable-two-fa-otp',
    sendtwofaotp: '/settings/send-two-fa-otp',
    verifyandenabletwofaotp: '/settings/verify-enable-two-fa-otp',
    enabledisablegoogleauth: '/settings/enable-disable-google-two-fa',
    generategoogleauth: '/settings/generate-google-auth',
    getrecoverycode: '/settings/get-recovery-code',

    // tools
    getreportingoptions: '/tools/get-reporting-options',
    generatereport: '/tools/generate-report',
    getbinanceaccountdetails: '/tools/get-binance-account-details',
    getsalesreport: '/tools/get-sales-report',
    emailsalesreport: '/tools/email-sales-report',
    emailbulk: '/tools/email-bulk',
    approvefireblocksflowcontroller:
      '/tools/approve-fireblocks-flow-controller',
    approvefireblocksflowoperator: '/tools/approve-fireblocks-flow-operator',
    queryfireblockswithdrawals: '/tools/query-fireblocks-withdrawals',
    approvefireblockswithdrawals: '/tools/approve-fireblocks-withdrawals',
    approvewithdrawfromplan: '/tools/approve-withdraw-from-plan',
    querywithdrawfromplan: '/tools/query-withdraw-from-plan',
    marketingaddbanner: '/tools/marketing-add-banner',
    querywithdrawfrombank: '/tools/query-withdraw-from-bank',
    approvewithdrawfrombank: '/tools/approve-withdraw-from-bank',
    completewithdrawfrombank: '/tools/complete-withdraw-from-bank',

    // transactions
    gettransactionsforuser: '/transactions/get-transactions-for-user',

    // product application
    getwalletdepositaddresses:
      '/product-application/get-wallet-deposit-addresses',
    getcollateralloandata: '/product-application/get-collateral-loan-data',

    // kyc
    getkycclienttoken: '/kyc/get-kyc-client-token',
    createkycchecks: '/kyc/create-kyc-checks',
    overridekycforuser: '/kyc/override-kyc-for-user',

    // quotes
    generatequote: '/quotes/generate-quote',
    deletequote: '/quotes/delete-quote',
    getquotes: '/quotes/get-quotes',
  };

  constructor(private http: HttpClient) {}

  // Build headers
  private getHeaders() {
    let headers = new HttpHeaders();

    headers = headers.append('x-source', 'portal');

    if (sessionStorage.getItem('x-token')) {
      headers = headers.append(
        'Authorization',
        `Bearer ${sessionStorage.getItem('x-token')}`,
      );
    } else if (localStorage.getItem('token')) {
      headers = headers.append(
        'Authorization',
        `Bearer ${localStorage.getItem('token')}`,
      );
    }

    return headers;
  }

  // Function to get the full URL needed for call
  private getFullURL(url) {
    return this.backendApi + this.urls[url];
  }

  // Function to do GET requests
  get(key, params) {
    this.urlPath = this.getFullURL(key);

    if (params) {
      this.urlPath += params;
    }

    let headers = this.getHeaders();
    let options = {
      headers: headers,
    };

    return this.http.get<any>(this.urlPath, options);
  }

  // Function to do POST requests
  post(key, body) {
    this.urlPath = this.getFullURL(key);

    let headers = this.getHeaders();
    let options = {
      headers: headers,
    };

    return this.http.post<any>(this.urlPath, body, options);
  }

  // Function to do PUT requests
  put(key, params, body) {
    this.urlPath = this.getFullURL(key);

    if (params) {
      this.urlPath += params;
    }

    let headers = this.getHeaders();
    let options = {
      headers: headers,
    };

    return this.http.put<any>(this.urlPath, body, options);
  }

  // Function to do DELETE request
  delete(key, params) {
    this.urlPath = this.getFullURL(key);

    if (params) {
      this.urlPath += params;
    }

    let headers = this.getHeaders();
    let options = {
      headers: headers,
    };

    return this.http.delete<any>(this.urlPath, options);
  }
}
