import { Router, RouterModule } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule, NgClass, RouterModule],
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  public isLoading: boolean;
  public isCollapsed: boolean;
  public mouseOvered: boolean;
  isScrolled = false;
  isBlurred = false;
  // private router: Router;

  constructor(private router: Router) {
    this.isCollapsed = true;
    this.isLoading = true;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isScrolled = window.scrollY > 100;
  }

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
    this.mouseOvered = false;
    this.toggleBlur(); // Add this line
  }

  toggleBlur(): void {
    if (window.innerWidth <= 991.98) {
      this.isBlurred = !this.isBlurred;
    }
  }

  onRegister() {
    this.router.navigate(['/membership/60c64db432d8ec05d3253f21']);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.isLoading = false;
  }
}
