<nav
  class="navbar navbar-expand-lg fixed-top chakra-petch-light"
  [ngClass]="{ blurred: isScrolled }"
>
  <div class="container-fluid full-height">
    <a class="navbar-brand" routerLink="za"
      ><img
        src="../../assets/images/blue_asset_logo_inline_black.png"
        class="logo logo-desktop"
      />
      <!-- <img
        src="../../assets/images/blue_asset_logo_inline_black copy.png"
        class="logo logo-mobile"
      /> -->
    </a>
    <button
      class="navbar-toggler collapsed"
      (click)="toggleCollapsed()"
      type="button"
      data-toggle="collapse"
      aria-controls="navbarNavAltMarkup"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="global-radius icon_wrap">
        <i class="fa fa-bars" [ngClass]="{ blurred: isScrolled }"></i>
      </span>
    </button>
    <div
      class="navbar-collapse collapse"
      [attr.aria-expanded]="!isCollapsed"
      [ngClass]="{ collapse: isCollapsed }"
    >
      <div class="navbar-nav ml-auto">
        <ul class="navbar-nav" (click)="toggleCollapsed()">
          <li class="nav-item">
            <a
              class="nav-link"
              [ngClass]="{
                blurred: isScrolled,
              }"
              [routerLink]="['/']"
              routerLinkActive="active-link active-link-login"
              >HOME</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              routerLink="/"
              fragment="investments"
              [ngClass]="{
                blurred: isScrolled,
              }"
              >PRODUCTS</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              routerLink="/"
              fragment="partners"
              [ngClass]="{
                blurred: isScrolled,
              }"
              >PARTNERS</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              routerLink="/"
              fragment="contact"
              [ngClass]="{
                blurred: isScrolled,
              }"
              >CONTACT</a
            >
          </li>
          <!-- <li class="nav-item">
            <a
              class="nav-link btn-highlight"
              routerLinkActive="active-link active-link-login"
              [ngClass]="{
                blurred: isScrolled
              }"
              (click)="onRegister()"
              >REGISTER</a
            >
          </li> -->
          <li class="nav-item">
            <a
              class="nav-link"
              [ngClass]="{
                blurred: isScrolled,
              }"
              [routerLink]="['/login']"
              routerLinkActive="active-link active-link-login"
              >LOGIN</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link btn-highlight btn-flow"
              [ngClass]="{
                blurred: isScrolled,
              }"
              [routerLink]="['/pre-sign-up']"
              routerLinkActive="active-link active-link-login"
              >SIGN UP</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
