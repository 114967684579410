import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-apply',
    templateUrl: './apply.component.html',
    styleUrls: ['./apply.component.scss'],
})
export class ApplyComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  goToSection(el: HTMLElement) {
    el.scrollIntoView();
  }
}
