<div>
  <div class="banner-content">
    <div class="content">
      <div class="main-holder">
        <div>
          <div class="top-left">
            <h2 class="main-header">Invest In The Future, Today</h2>
          </div>
          <div class="below-quote">
            At BlueAsset we created a symbiotic relationship by combining the
            traditional Investment Structures with the new and disruptive
            decentralized financial systems and eco systems. By setting up a
            regulated Investment Fund structure and using the new improved, fast
            execution able, and secure decentralized finance Instruments as
            internal works of fund. With newly improved decentralized leger
            technology purely use the latest blockchain technology, that allows
            the fund manager to execute fast and efficient investment
            strategies.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="carousel-slider">
    <h2 class="block-header">Investment Strategies</h2>
    <div class="blue-txt">
      Where innovation builds bridges between old and new financial instruments.
    </div>
    <div class="mt-3 blue-txt">
      The BlueAsset research team work hand in hand with fund management to
      create new investment strategies that operate in the DeFi Eco System as
      well as traditional financial instruments.
    </div>
    <div class="hex-items">
      <div (click)="goToSection(item1)" class="slide-item">
        <img class="carousel-icon" src="assets/icons/hexagon_icon.svg" />
        <div class="slide-header-icon">
          <img class="card-slide-icon" src="assets/icons/derivates.svg" />
          <div class="slide-text">Derivatives</div>
        </div>
      </div>
      <div (click)="goToSection(item2)" class="slide-item">
        <img class="carousel-icon" src="assets/icons/hexagon_icon.svg" />
        <div class="slide-header-icon">
          <img class="card-slide-icon" src="assets/icons/arbitrage.svg" />
          <div class="slide-text">Arbitrage</div>
        </div>
      </div>
      <div (click)="goToSection(item3)" class="slide-item">
        <img class="carousel-icon" src="assets/icons/hexagon_icon.svg" />
        <div class="slide-header-icon">
          <img class="card-slide-icon" src="assets/icons/master_nodes.svg" />
          <div class="slide-text">MasterNodes</div>
        </div>
      </div>
      <div (click)="goToSection(item4)" class="slide-item">
        <img class="carousel-icon" src="assets/icons/hexagon_icon.svg" />
        <div class="slide-header-icon">
          <img
            class="card-slide-icon"
            src="assets/icons/liquidity_farming.svg"
          />
          <div class="slide-text">Liquidity Farming</div>
        </div>
      </div>
      <div (click)="goToSection(item5)" class="slide-item">
        <img class="carousel-icon" src="assets/icons/hexagon_icon.svg" />
        <div class="slide-header-icon">
          <img class="card-slide-icon" src="assets/icons/vc_fund.svg" />
          <div class="slide-text">VC Fund</div>
        </div>
      </div>
    </div>
  </div>
  <div #item1 class="item-section">
    <div class="item-section-description">
      <h4>What Are Derivatives?</h4>
      <div>
        Derivatives are financial instruments that derive their value from an
        underlying asset. This underlying asset can be stocks, bonds,
        commodities, currencies, and other financial instruments. Derivatives
        are used to hedge risk, speculate, and increase leverage. They can be
        used for hedging against price fluctuations of the underlying asset,
        speculating on future market movements, and increasing leverage to gain
        greater returns.
      </div>
    </div>
    <div class="main-right">
      <img src="assets/images/world_purple.png" />
    </div>
  </div>
  <div #item2 class="item-section">
    <div class="main-right">
      <img src="assets/images/world_purple.png" />
    </div>
    <div class="item-section-description">
      <h4>Introducing Arbitrage</h4>
      <div>
        In finance, arbitrage is the practice of taking advantage of a
        difference in prices in two or more markets; striking a combination of
        matching deals to capitalise on the difference, the profit being the
        difference between the market prices at which the unit is traded.
      </div>
    </div>
  </div>
  <div #item3 class="item-section">
    <div class="item-section-description">
      <h4>Introducing Master Nodes</h4>
      <div>
        Master Nodes are specialized nodes that are used to validate
        transactions and secure the network. They are essential for running a
        secure and reliable blockchain network. They are also used to provide
        additional features, such as instant transactions, privacy, and
        governance. Master Nodes are incentivized to act as guardians of the
        network, as they receive rewards for their service. This incentivization
        helps to ensure that the network remains secure and reliable.
      </div>
    </div>
    <div class="main-right">
      <img src="assets/images/world_purple.png" />
    </div>
  </div>
  <div #item4 class="item-section">
    <div class="main-right">
      <img src="assets/images/world_purple.png" />
    </div>
    <div class="item-section-description">
      <h4>What Is Liquidity Farming?</h4>
      <div>
        Liquidity farming is a way to generate yield on digital assets. It
        involves providing liquidity to decentralized exchanges (DEXs) and
        earning rewards for it. Liquidity providers are incentivized with tokens
        or other rewards for providing liquidity to DEXs. This helps reduce
        slippage and market volatility, while providing a passive income stream
        for liquidity providers.
      </div>
    </div>
  </div>
  <div #item5 class="item-section">
    <div class="item-section-description">
      <h4>What Is VC Fund?</h4>
      <div>
        VC Fund stands for Venture Capital Fund, which is a type of private
        equity fund that invests in early-stage, high-growth companies. VC Funds
        provide capital to start-ups and small businesses in exchange for equity
        in the company. VC Funds are typically managed by professionals and are
        used to finance new products and services, expand existing operations,
        or acquire other companies. These funds are often used to help companies
        launch innovative technologies or products that may not be able to
        secure traditional financing.
      </div>
    </div>
    <div class="main-right">
      <img src="assets/images/world_purple.png" />
    </div>
  </div>
</div>
