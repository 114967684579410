import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  // We use this for all static dropdowns etc, to maintain on one central point

  constructor() {}

  depositTypes() {
    let depositTypes = [
      { text: 'ZAR', value: 'zar' },
      { text: 'USDT', value: 'usdt' },
      { text: 'BTC', value: 'bitcoin' },
      { text: 'ETH', value: 'ethereum' },
    ];

    return depositTypes;
  }

  getProvidedCoins() {
    let depositTypes = [
      { text: 'ZAR', value: 'zar' },
      { text: 'USDT', value: 'usdt' },
      { text: 'BTC', value: 'bitcoin' },
      { text: 'ETH', value: 'ethereum' },
    ];

    return depositTypes;
  }

  getWithdrawTypes() {
    let withdrawTypes = [
      { text: 'Bank', value: 'bank' },
      { text: 'Wallet', value: 'wallet' },
      { text: 'N/A', value: 'n/a' },
    ];

    return withdrawTypes;
  }

  getQuoteTypes() {
    let quoteTypes = [
      { text: 'Once Off', value: 'once-off' },
      { text: 'Recurring', value: 'recurring' },
    ];

    return quoteTypes;
  }

  getWithdrawPercentages() {
    let depositTypes = [
      { text: '0%', value: 0 },
      { text: '10%', value: 10 },
      { text: '20%', value: 20 },
      { text: '30%', value: 30 },
      { text: '40%', value: 40 },
      { text: '50%', value: 50 },
      { text: '60%', value: 60 },
      { text: '70%', value: 70 },
      { text: '80%', value: 80 },
    ];

    return depositTypes;
  }

  getAccountTypes() {
    let accountTypes = [
      { text: 'Current Account', value: 'Current Account' },
      { text: 'Savings Account', value: 'Savings Account' },
      { text: 'Money Market Account', value: 'Money Market Account' },
      { text: 'Certificate Of Deposit', value: 'Certificate Of Deposit' },
      { text: 'Retirement Account', value: 'Retirement Account' },
      { text: 'Unknown', value: 'Other' },
    ];
    return accountTypes;
  }

  getTitles() {
    let titles = [
      { text: 'Mr.', value: 'Mr.' },
      { text: 'Ms.', value: 'Ms.' },
      { text: 'Mrs.', value: 'Mrs.' },
      { text: 'Doctor', value: 'Doctor' },
      { text: 'Prof.', value: 'Prof.' },
    ];
    return titles;
  }

  getYesNo() {
    let yesNo = [
      { text: 'Yes', value: true },
      { text: 'No', value: false },
    ];
    return yesNo;
  }

  getGenders() {
    let genders = [
      { text: 'Male', value: 'male' },
      { text: 'Female', value: 'female' },
    ];
    return genders;
  }

  getPlans() {
    let plans = [
      { text: 'Accumulation Fund', value: 'zero' },
      { text: 'Flexi Income Plan', value: 'flexi' },
      { text: 'Flexi Smart Plan', value: 'smart' },
      { text: 'Retire Plan', value: 'retire' },
    ];
    return plans;
  }

  getMaritalStatus() {
    let maritalStatus = [
      { text: 'Single', value: 'single' },
      { text: 'Married', value: 'married' },
      { text: 'Divorced', value: 'divorced' },
      { text: 'Widowed', value: 'widowed' },
      { text: 'Separated', value: 'separated' },
      { text: 'Cohabiting', value: 'cohabiting' },
    ];
    return maritalStatus;
  }

  getOccupations() {
    let occupation = [
      { text: 'Private Sector Employment', value: 'privateSectorEmployment' },
      { text: 'Public Sector Employment', value: 'publicSectorEmployment' },
      {
        text: 'Self-employed/Consultant/Entrepreneur',
        value: 'self-employedConsultantEntrepreneur',
      },
      { text: 'Retired', value: 'retired' },
      { text: 'Student', value: 'student' },
      { text: 'Unemployed', value: 'unemployed' },
    ];
    return occupation;
  }

  getIdTypes() {
    let idTypes = [
      { text: 'Identification Card', value: 'id' },
      { text: 'Passport', value: 'passport' },
      { text: 'Business', value: 'business' },
    ];
    return idTypes;
  }

  getAnnualIncome() {
    let annualIncome = [
      { text: 'R0 to R237,100', value: '0-237,100' },
      { text: 'R237,101 to R370,500	', value: '237,101-370,500' },
      { text: 'R370,501 to R512,800	', value: '370,501-512,800' },
      { text: 'R512,801 to R673,000	', value: '512,801-673,000' },
      { text: 'R673,001 to R857,900	', value: '673,001-857,900' },
      { text: 'R857,901 to R1,817,000	', value: '857,901-1,817,000' },
      { text: 'R1,817,000	and above', value: '1,817,001+' },
    ];
    return annualIncome;
  }

  getBusinessRevenue() {
    let annualIncome = [
      { text: 'R0 to R500,000', value: '0-500,000	' },
      { text: 'R500,000 to R1,000,000	', value: '500,000-1,000,000' },
      { text: 'R1,000,000 to R5,000,000	', value: '1,000,000-5,000,000' },
      { text: 'R5,000,000 to R10,000,000	', value: '5,000,000-10,000,000' },
      { text: 'R10,000,000 to R20,000,000	', value: '10,000,000-20,000,000' },
      { text: 'R20,000,000 to R50,000,000	', value: '20,000,000-50,000,000' },
      { text: 'R50,000,000 to R100,000,000', value: '50,000,000-100,000,000' },
      { text: 'R100,000,000 and above', value: '100,000,000+' },
    ];
    return annualIncome;
  }

  getCountries() {
    let countries = [
      { text: 'South Africa', value: 'South Africa' },
      { text: 'Afghanistan', value: 'Afghanistan' },
      { text: 'Albania', value: 'Albania' },
      { text: 'Algeria', value: 'Algeria' },
      { text: 'American Samoa', value: 'American Samoa' },
      { text: 'Andorra', value: 'Andorra' },
      { text: 'Angola', value: 'Angola' },
      { text: 'Anguilla', value: 'Anguilla' },
      { text: 'Antigua & Barbuda', value: 'Antigua & Barbuda' },
      { text: 'Argentina', value: 'Argentina' },
      { text: 'Armenia', value: 'Armenia' },
      { text: 'Aruba', value: 'Aruba' },
      { text: 'Australia', value: 'Australia' },
      { text: 'Austria', value: 'Austria' },
      { text: 'Azerbaijan', value: 'Azerbaijan' },
      { text: 'Bahamas', value: 'Bahamas' },
      { text: 'Bahrain', value: 'Bahrain' },
      { text: 'Bangladesh', value: 'Bangladesh' },
      { text: 'Barbados', value: 'Barbados' },
      { text: 'Belarus', value: 'Belarus' },
      { text: 'Belgium', value: 'Belgium' },
      { text: 'Belize', value: 'Belize' },
      { text: 'Benin', value: 'Benin' },
      { text: 'Bermuda', value: 'Bermuda' },
      { text: 'Bhutan', value: 'Bhutan' },
      { text: 'Bolivia', value: 'Bolivia' },
      { text: 'Bonaire', value: 'Bonaire' },
      { text: 'Bosnia & Herzegovina', value: 'Bosnia & Herzegovina' },
      { text: 'Botswana', value: 'Botswana' },
      { text: 'Brazil', value: 'Brazil' },
      { text: 'British Indian Ocean Ter', value: 'British Indian Ocean Ter' },
      { text: 'Brunei', value: 'Brunei' },
      { text: 'Bulgaria', value: 'Bulgaria' },
      { text: 'Burkina Faso', value: 'Burkina Faso' },
      { text: 'Burundi', value: 'Burundi' },
      { text: 'Cambodia', value: 'Cambodia' },
      { text: 'Cameroon', value: 'Cameroon' },
      { text: 'Canada', value: 'Canada' },
      { text: 'Canary Islands', value: 'Canary Islands' },
      { text: 'Cape Verde', value: 'Cape Verde' },
      { text: 'Cayman Islands', value: 'Cayman Islands' },
      { text: 'Central African Republic', value: 'Central African Republic' },
      { text: 'Chad', value: 'Chad' },
      { text: 'Channel Islands', value: 'Channel Islands' },
      { text: 'Chile', value: 'Chile' },
      { text: 'China', value: 'China' },
      { text: 'Christmas Island', value: 'Christmas Island' },
      { text: 'Cocos Island', value: 'Cocos Island' },
      { text: 'Colombia', value: 'Colombia' },
      { text: 'Comoros', value: 'Comoros' },
      { text: 'Congo', value: 'Congo' },
      { text: 'Cook Islands', value: 'Cook Islands' },
      { text: 'Costa Rica', value: 'Costa Rica' },
      { text: 'Cote DIvoire', value: 'Cote DIvoire' },
      { text: 'Croatia', value: 'Croatia' },
      { text: 'Cuba', value: 'Cuba' },
      { text: 'Curacao', value: 'Curacao' },
      { text: 'Cyprus', value: 'Cyprus' },
      { text: 'Czech Republic', value: 'Czech Republic' },
      { text: 'Denmark', value: 'Denmark' },
      { text: 'Djibouti', value: 'Djibouti' },
      { text: 'Dominica', value: 'Dominica' },
      { text: 'Dominican Republic', value: 'Dominican Republic' },
      { text: 'East Timor', value: 'East Timor' },
      { text: 'Ecuador', value: 'Ecuador' },
      { text: 'Egypt', value: 'Egypt' },
      { text: 'El Salvador', value: 'El Salvador' },
      { text: 'Equatorial Guinea', value: 'Equatorial Guinea' },
      { text: 'Eritrea', value: 'Eritrea' },
      { text: 'Estonia', value: 'Estonia' },
      { text: 'Ethiopia', value: 'Ethiopia' },
      { text: 'Falkland Islands', value: 'Falkland Islands' },
      { text: 'Faroe Islands', value: 'Faroe Islands' },
      { text: 'Fiji', value: 'Fiji' },
      { text: 'Finland', value: 'Finland' },
      { text: 'France', value: 'France' },
      { text: 'French Guiana', value: 'French Guiana' },
      { text: 'French Polynesia', value: 'French Polynesia' },
      { text: 'French Southern Ter', value: 'French Southern Ter' },
      { text: 'Gabon', value: 'Gabon' },
      { text: 'Gambia', value: 'Gambia' },
      { text: 'Georgia', value: 'Georgia' },
      { text: 'Germany', value: 'Germany' },
      { text: 'Ghana', value: 'Ghana' },
      { text: 'Gibraltar', value: 'Gibraltar' },
      { text: 'Great Britain', value: 'Great Britain' },
      { text: 'Greece', value: 'Greece' },
      { text: 'Greenland', value: 'Greenland' },
      { text: 'Grenada', value: 'Grenada' },
      { text: 'Guadeloupe', value: 'Guadeloupe' },
      { text: 'Guam', value: 'Guam' },
      { text: 'Guatemala', value: 'Guatemala' },
      { text: 'Guinea', value: 'Guinea' },
      { text: 'Guyana', value: 'Guyana' },
      { text: 'Haiti', value: 'Haiti' },
      { text: 'Hawaii', value: 'Hawaii' },
      { text: 'Honduras', value: 'Honduras' },
      { text: 'Hong Kong', value: 'Hong Kong' },
      { text: 'Hungary', value: 'Hungary' },
      { text: 'Iceland', value: 'Iceland' },
      { text: 'Indonesia', value: 'Indonesia' },
      { text: 'India', value: 'India' },
      { text: 'Iran', value: 'Iran' },
      { text: 'Iraq', value: 'Iraq' },
      { text: 'Ireland', value: 'Ireland' },
      { text: 'Isle of Man', value: 'Isle of Man' },
      { text: 'Israel', value: 'Israel' },
      { text: 'Italy', value: 'Italy' },
      { text: 'Jamaica', value: 'Jamaica' },
      { text: 'Japan', value: 'Japan' },
      { text: 'Jordan', value: 'Jordan' },
      { text: 'Kazakhstan', value: 'Kazakhstan' },
      { text: 'Kenya', value: 'Kenya' },
      { text: 'Kiribati', value: 'Kiribati' },
      { text: 'Korea North', value: 'Korea North' },
      { text: 'Korea South', value: 'Korea South' },
      { text: 'Kuwait', value: 'Kuwait' },
      { text: 'Kyrgyzstan', value: 'Kyrgyzstan' },
      { text: 'Laos', value: 'Laos' },
      { text: 'Latvia', value: 'Latvia' },
      { text: 'Lebanon', value: 'Lebanon' },
      { text: 'Lesotho', value: 'Lesotho' },
      { text: 'Liberia', value: 'Liberia' },
      { text: 'Libya', value: 'Libya' },
      { text: 'Liechtenstein', value: 'Liechtenstein' },
      { text: 'Lithuania', value: 'Lithuania' },
      { text: 'Luxembourg', value: 'Luxembourg' },
      { text: 'Macau', value: 'Macau' },
      { text: 'Macedonia', value: 'Macedonia' },
      { text: 'Madagascar', value: 'Madagascar' },
      { text: 'Malaysia', value: 'Malaysia' },
      { text: 'Malawi', value: 'Malawi' },
      { text: 'Maldives', value: 'Maldives' },
      { text: 'Mali', value: 'Mali' },
      { text: 'Malta', value: 'Malta' },
      { text: 'Marshall Islands', value: 'Marshall Islands' },
      { text: 'Martinique', value: 'Martinique' },
      { text: 'Mauritania', value: 'Mauritania' },
      { text: 'Mauritius', value: 'Mauritius' },
      { text: 'Mayotte', value: 'Mayotte' },
      { text: 'Mexico', value: 'Mexico' },
      { text: 'Midway Islands', value: 'Midway Islands' },
      { text: 'Moldova', value: 'Moldova' },
      { text: 'Monaco', value: 'Monaco' },
      { text: 'Mongolia', value: 'Mongolia' },
      { text: 'Montserrat', value: 'Montserrat' },
      { text: 'Morocco', value: 'Morocco' },
      { text: 'Mozambique', value: 'Mozambique' },
      { text: 'Myanmar', value: 'Myanmar' },
      { text: 'Namibia', value: 'Namibia' },
      { text: 'Nauru', value: 'Nauru' },
      { text: 'Nepal', value: 'Nepal' },
      { text: 'Netherlands Antilles', value: 'Netherlands Antilles' },
      { text: 'Netherlands', value: 'Netherlands' },
      { text: 'New Caledonia', value: 'New Caledonia' },
      { text: 'New Zealand', value: 'New Zealand' },
      { text: 'Nicaragua', value: 'Nicaragua' },
      { text: 'Niger', value: 'Niger' },
      { text: 'Nigeria', value: 'Nigeria' },
      { text: 'Niue', value: 'Niue' },
      { text: 'Norfolk Island', value: 'Norfolk Island' },
      { text: 'Northern Mariana Islands', value: 'Northern Mariana Islands' },
      { text: 'Norway', value: 'Norway' },
      { text: 'Oman', value: 'Oman' },
      { text: 'Pakistan', value: 'Pakistan' },
      { text: 'Palau', value: 'Palau' },
      { text: 'Panama', value: 'Panama' },
      { text: 'Papua New Guinea', value: 'Papua New Guinea' },
      { text: 'Paraguay', value: 'Paraguay' },
      { text: 'Peru', value: 'Peru' },
      { text: 'Philippines', value: 'Philippines' },
      { text: 'Pitcairn Island', value: 'Pitcairn Island' },
      { text: 'Poland', value: 'Poland' },
      { text: 'Portugal', value: 'Portugal' },
      { text: 'Puerto Rico', value: 'Puerto Rico' },
      { text: 'Qatar', value: 'Qatar' },
      { text: 'Romania', value: 'Romania' },
      { text: 'Russia', value: 'Russia' },
      { text: 'Rwanda', value: 'Rwanda' },
      { text: 'Reunion', value: 'Reunion' },
      { text: 'Saint Barthelemy', value: 'Saint Barthelemy' },
      { text: 'Saint Helena', value: 'Saint Helena' },
      { text: 'Saint Kitts & Nevis', value: 'Saint Kitts & Nevis' },
      { text: 'Saint Lucia', value: 'Saint Lucia' },
      { text: 'Saint Martin', value: 'Saint Martin' },
      { text: 'Saint Pierre & Miquelon', value: 'Saint Pierre & Miquelon' },
      {
        text: 'Saint Vincent & Grenadines',
        value: 'Saint Vincent & Grenadines',
      },
      { text: 'Samoa', value: 'Samoa' },
      { text: 'San Marino', value: 'San Marino' },
      { text: 'Sao Tome & Principe', value: 'Sao Tome & Principe' },
      { text: 'Saudi Arabia', value: 'Saudi Arabia' },
      { text: 'Senegal', value: 'Senegal' },
      { text: 'Serbia', value: 'Serbia' },
      { text: 'Seychelles', value: 'Seychelles' },
      { text: 'Sierra Leone', value: 'Sierra Leone' },
      { text: 'Singapore', value: 'Singapore' },
      { text: 'Sint Maarten', value: 'Sint Maarten' },
      { text: 'Slovakia', value: 'Slovakia' },
      { text: 'Slovenia', value: 'Slovenia' },
      { text: 'Solomon Islands', value: 'Solomon Islands' },
      { text: 'Somalia', value: 'Somalia' },
      { text: 'South Korea', value: 'South Korea' },
      { text: 'South Sudan', value: 'South Sudan' },
      { text: 'Spain', value: 'Spain' },
      { text: 'Sri Lanka', value: 'Sri Lanka' },
      { text: 'Sudan', value: 'Sudan' },
      { text: 'Suriname', value: 'Suriname' },
      { text: 'Svalbard', value: 'Svalbard' },
      { text: 'Swaziland', value: 'Swaziland' },
      { text: 'Sweden', value: 'Sweden' },
      { text: 'Switzerland', value: 'Switzerland' },
      { text: 'Syria', value: 'Syria' },
      { text: 'Taiwan', value: 'Taiwan' },
      { text: 'Tajikistan', value: 'Tajikistan' },
      { text: 'Tanzania', value: 'Tanzania' },
      { text: 'Thailand', value: 'Thailand' },
      { text: 'Togo', value: 'Togo' },
      { text: 'Tokelau', value: 'Tokelau' },
      { text: 'Tonga', value: 'Tonga' },
      { text: 'Trinidad & Tobago', value: 'Trinidad & Tobago' },
      { text: 'Tunisia', value: 'Tunisia' },
      { text: 'Turkey', value: 'Turkey' },
      { text: 'Turkmenistan', value: 'Turkmenistan' },
      { text: 'Tuvalu', value: 'Tuvalu' },
      { text: 'Uganda', value: 'Uganda' },
      { text: 'Ukraine', value: 'Ukraine' },
      { text: 'United Arab Emirates', value: 'United Arab Emirates' },
      { text: 'United Kingdom', value: 'United Kingdom' },
      { text: 'United States', value: 'United States' },
      { text: 'Uruguay', value: 'Uruguay' },
      { text: 'Uzbekistan', value: 'Uzbekistan' },
      { text: 'Vanuatu', value: 'Vanuatu' },
      { text: 'Vatican City', value: 'Vatican City' },
      { text: 'Venezuela', value: 'Venezuela' },
      { text: 'Vietnam', value: 'Vietnam' },
      { text: 'Wallis & Futuna', value: 'Wallis & Futuna' },
      { text: 'Western Sahara', value: 'Western Sahara' },
      { text: 'Yemen', value: 'Yemen' },
      { text: 'Zambia', value: 'Zambia' },
      { text: 'Zimbabwe', value: 'Zimbabwe' },
    ];
    return countries;
  }

  getPoliticallyExposedPerson() {
    let pep = [
      {
        text: 'N/A',
        value: 'n/a',
      },
      {
        text: 'President or Deputy President',
        value: 'President or Deputy President',
      },
      {
        text: 'Head of State, Head of Country, or Government',
        value: 'Head of State, Head of Country, or Government',
      },
      {
        text: 'Government Minister or Deputy Minister',
        value: 'Government Minister or Deputy Minister',
      },
      {
        text: 'Premier or Executive Council Member of a Province',
        value: 'Premier or Executive Council Member of a Province',
      },
      {
        text: 'Executive Mayor of a Municipality',
        value: 'Executive Mayor of a Municipality',
      },
      {
        text: 'Leader of a Registered Political Party',
        value: 'Leader of a Registered Political Party',
      },
      {
        text: 'Government Minister, Senior Politician, or Political Party Leader',
        value:
          'Government Minister, Senior Politician, or Political Party Leader',
      },
      {
        text: 'Member of a Royal Family or Senior Traditional Leader',
        value: 'Member of a Royal Family or Senior Traditional Leader',
      },
      {
        text: 'Member of a Foreign Royal Family',
        value: 'Member of a Foreign Royal Family',
      },
      {
        text: 'Head, Accounting Officer, or CFO of a National/Provincial Department',
        value:
          'Head, Accounting Officer, or CFO of a National/Provincial Department',
      },
      {
        text: 'Municipal Manager or CFO of a Municipality',
        value: 'Municipal Manager or CFO of a Municipality',
      },
      {
        text: 'Chairperson, CEO, CFO, or CIO of a Public/Municipal Entity',
        value: 'Chairperson, CEO, CFO, or CIO of a Public/Municipal Entity',
      },
      {
        text: 'Senior Executive of a State-Owned Corporation',
        value: 'Senior Executive of a State-Owned Corporation',
      },
      {
        text: 'Constitutional Court Judge or any other Judge',
        value: 'Constitutional Court Judge or any other Judge',
      },
      {
        text: 'Senior Judicial Officer',
        value: 'Senior Judicial Officer',
      },
      {
        text: 'Ambassador, High Commissioner, or Senior Foreign Representative in the Republic',
        value:
          'Ambassador, High Commissioner, or Senior Foreign Representative in the Republic',
      },
      {
        text: 'Officer of the SANDF above the rank of Major-General',
        value: 'Officer of the SANDF above the rank of Major-General',
      },
      {
        text: 'High-ranking Military Member',
        value: 'High-ranking Military Member',
      },
      {
        text: 'Head or Executive directly accountable to that Head of an International Organisation',
        value:
          'Head or Executive directly accountable to that Head of an International Organisation',
      },
    ];
    return pep;
  }

  getProminentInfluentialPerson() {
    let pip = [
      {
        text: 'N/A',
        value: 'n/a',
      },
      {
        text: 'Chairperson of the Board of Directors',
        value: 'Chairperson of the Board of Directors',
      },
      {
        text: 'Chairperson of the Audit Committee',
        value: 'Chairperson of the Audit Committee',
      },
      { text: 'Executive Officer', value: 'Executive Officer' },
      { text: 'Chief Financial Officer', value: 'Chief Financial Officer' },
      {
        text: 'Head, or other Executive directly accountable to that Head, of an International Organisation based in the Republic',
        value:
          'Head, or other Executive directly accountable to that Head, of an International Organisation based in the Republic',
      },
    ];
    return pip;
  }

  getProvinces() {
    let provinces = [
      { name: 'Other/Abroad', caption: 'Other/Abroad' },
      { name: 'Gauteng', caption: 'Gauteng' },
      { name: 'Western Cape', caption: 'Western Cape' },
      { name: 'Eastern Cape', caption: 'Eastern Cape' },
      { name: 'Free State', caption: 'Free State' },
      { name: 'KwaZulu-Natal', caption: 'KwaZulu-Natal' },
      { name: 'Limpopo', caption: 'Limpopo' },
      { name: 'Mpumalanga', caption: 'Mpumalanga' },
      { name: 'Northern Cape', caption: 'Northern Cape' },
      { name: 'North West', caption: 'North West' },
    ];
    return provinces;
  }

  getSourceOfFunds() {
    let fundsSource = [
      { text: 'Compensation', value: 'Compensation' },
      { text: 'Dividends/Salary', value: 'Dividends/Salary' },
      { text: 'Inheritance', value: 'Inheritance' },
      { text: 'Lottery/Gambling Winnings', value: 'Lottery/Gambling Winnings' },
      { text: 'Pension Fund', value: 'Pension Fund' },
      { text: 'Sale of Property', value: 'Sale of Property' },
      { text: 'Sale of Shares', value: 'Sale of Shares' },
      { text: 'Savings', value: 'Savings' },
      { text: 'Other', value: 'Other' },
    ];
    return fundsSource;
  }

  getValrSupportedBanks() {
    let supportedBanks = [
      {
        code: 'ABSA',
        displayName: 'ABSA Bank',
        defaultBranchCode: '632005',
      },
      {
        code: 'CAPITEC',
        displayName: 'Capitec Bank',
        defaultBranchCode: '470010',
      },
      {
        code: 'FNB',
        displayName: 'FNB/RMB',
        defaultBranchCode: '250655',
      },
      {
        code: 'NEDBANK',
        displayName: 'Nedbank',
        defaultBranchCode: '198765',
      },
      {
        code: 'STANDARD',
        displayName: 'Standard Bank',
        defaultBranchCode: '051001',
      },
      {
        code: 'AFRICAN',
        displayName: 'African Bank',
        defaultBranchCode: '430000',
      },
      {
        code: 'ALBARAKA',
        displayName: 'Albaraka Bank',
        defaultBranchCode: '800000',
      },
      {
        code: 'ACCESS_BANK',
        displayName: 'Access Bank',
        defaultBranchCode: '410506',
      },
      {
        code: 'BANK_ZERO',
        displayName: 'Bank Zero',
        defaultBranchCode: '888000',
      },
      {
        code: 'BIDVEST',
        displayName: 'Bidvest Bank',
        defaultBranchCode: '462005',
      },
      {
        code: 'BNP_PARIBAS',
        displayName: 'BNP Paribas',
        defaultBranchCode: '688000',
      },
      {
        code: 'CITIBANK',
        displayName: 'CitiBank',
        defaultBranchCode: '350005',
      },
      {
        code: 'DISCOVERY',
        displayName: 'Discovery Bank',
        defaultBranchCode: '679000',
      },
      {
        code: 'FINBOND_MUTUAL',
        displayName: 'FinBond Mutual Bank',
        defaultBranchCode: '589000',
      },
      {
        code: 'GRINDROD',
        displayName: 'Grindrod Bank',
        defaultBranchCode: '584000',
      },
      {
        code: 'HBZ',
        displayName: 'HBZ Bank',
        defaultBranchCode: '',
      },
      {
        code: 'HSBC',
        displayName: 'HSBC',
        defaultBranchCode: '587000',
      },
      {
        code: 'HABIB_OVERSEAS',
        displayName: 'Habib Overseas Bank',
        defaultBranchCode: '',
      },
      {
        code: 'INVESTEC',
        displayName: 'Investec Bank',
        defaultBranchCode: '580105',
      },
      {
        code: 'ITHALA',
        displayName: 'Ithala',
        defaultBranchCode: '',
      },
      {
        code: 'JPMORGAN_CHASE',
        displayName: 'JPMorgan Chase Bank',
        defaultBranchCode: '432000',
      },
      {
        code: 'LESOTHO_POST',
        displayName: 'Lesotho Post Bank',
        defaultBranchCode: '',
      },
      {
        code: 'OLYMPUS_MOBILE',
        displayName: 'Olympus Mobile',
        defaultBranchCode: '585001',
      },
      {
        code: 'PEOPLES_BANK',
        displayName: 'Peoples Bank Ltd Inc NBS',
        defaultBranchCode: '720026',
      },
      {
        code: 'POSTBANK',
        displayName: 'Postbank',
        defaultBranchCode: '460005',
      },
      {
        code: 'SA_RESERVE_BANK',
        displayName: 'S.A. Reserve Bank',
        defaultBranchCode: '',
      },
      {
        code: 'SASFIN',
        displayName: 'Sasfin Bank',
        defaultBranchCode: '683000',
      },
      {
        code: 'SOCIETE_GENERALE',
        displayName: 'Societe Generale',
        defaultBranchCode: '351005',
      },
      {
        code: 'STANDARD_CHARTERED',
        displayName: 'Standard Chartered Bank',
        defaultBranchCode: '730020',
      },
      {
        code: 'STATE_BANK_OF_INDIA',
        displayName: 'State Bank of India',
        defaultBranchCode: '801000',
      },
      {
        code: 'TYME_BANK',
        displayName: 'TymeBank',
        defaultBranchCode: '678910',
      },
      {
        code: 'UBank',
        displayName: 'UBank Limited',
        defaultBranchCode: '431010',
      },
      {
        code: 'UNIBANK',
        displayName: 'Unibank',
        defaultBranchCode: '790005',
      },
      {
        code: 'CAPITEC_BUSINESS',
        displayName: 'Capitec Business',
        defaultBranchCode: '450105',
      },
      {
        code: 'INTERNATIONAL',
        displayName: 'International',
        defaultBranchCode: '',
      },
    ];
    return supportedBanks;
  }

  getLoanDepositTypes() {
    let loanDepositTypes = [
      { text: 'ERC-20 Address', value: 'erc-20' },
      { text: 'Bank EFT', value: 'bank' },
      { text: 'Collateral', value: 'collateral' },
    ];
    return loanDepositTypes;
  }

  getSplitAmount() {
    let splitAmounts = [
      { text: '500 USDT', value: 500 },
      { text: '1000 USDT', value: 1000 },
      { text: '1500 USDT', value: 1500 },
      { text: '2000 USDT', value: 2000 },
      { text: '2500 USDT', value: 2500 },
      { text: '5000 USDT', value: 5000 },
    ];

    return splitAmounts;
  }

  getLoanTypes() {
    let loanTypes = [
      { text: 'All Loans', value: 'all' },
      { text: 'Income Fund Loans', value: 'income' },
      { text: 'Hedge Loan Loans', value: 'hedge' },
    ];

    return loanTypes;
  }

  getCoinTypes() {
    let coinTypes = [
      { text: 'All Coins', value: 'all' },
      { text: 'Bitcoin', value: 'bitcoin' },
      { text: 'Ethereum', value: 'ethereum' },
    ];
    return coinTypes;
  }

  getCalculatorAssets() {
    let coinTypes = [
      { text: 'BlueAsset Stablecoin', value: 'basc' },
      { text: 'Bitcoin', value: 'bitcoin' },
      { text: 'Ethereum', value: 'ethereum' },
    ];
    return coinTypes;
  }

  getWithdrawPercentage() {
    let coinTypes = [
      { text: '0%', value: 0 },
      { text: '10%', value: 10 },
      { text: '20%', value: 20 },
      { text: '30%', value: 30 },
      { text: '40%', value: 40 },
      { text: '50%', value: 50 },
      { text: '60%', value: 60 },
      { text: '70%', value: 70 },
      { text: '80%', value: 80 },
    ];
    return coinTypes;
  }

  getIncomeProductPayoutTypes() {
    let payoutTypes = [
      {
        text: 'Bank EFT',
        value: 'bank',
        description: 'Pay out your monthly return into your bank account.',
      },
      {
        text: 'Monthly Reinvest',
        value: 'deferred',
        description: 'Reinvest your monthly return back into your income plan.',
      },
      {
        text: 'Wallet Transfer',
        value: 'wallet',
        description: 'Pay out your monthly return into your USDT wallet.',
      },
    ];

    return payoutTypes;
  }
}
