import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  // We use this for all static dropdowns etc, to maintain on one central point

  constructor() {}

  

  depositTypes() {
    let depositTypes = [
      { text: 'ZAR', value: 'zar' },
      { text: 'USDT', value: 'usdt' },
      { text: 'BTC', value: 'bitcoin' },
      { text: 'ETH', value: 'ethereum' },
    ];

    return depositTypes;
  }

  getAccountTypes() {
    let accountTypes = [
      { text: 'Current Account', value: 'Current Account' },
      { text: 'Savings Account', value: 'Savings Account' },
      { text: 'Money Market Account', value: 'Money Market Account' },
      { text: 'Certificate Of Deposit', value: 'Certificate Of Deposit' },
      { text: 'Retirement Account', value: 'Retirement Account' },
      { text: 'Unknown', value: 'Other' },
    ];
    return accountTypes;
  }

  getLoanPercentages() {
    let coinTypes = [
      { text: '50%', value: 50 },
      { text: '40%', value: 40 },
    ];
    return coinTypes;
  }

  getLiquidationAssets() {
    let liquidationAssets = [
      { text: 'Original Asset', value: 'original' },
      { text: 'Bitcoin', value: 'bitcoin' },
      { text: 'Ethereum', value: 'ethereum' },
      { text: 'BlueAsset Stablecoin', value: 'basc' },
      { text: 'BlueAsset IndexFund', value: 'baif' },
    ];

    return liquidationAssets;
  }

  getProvinces() {
    let provinces = [
      { name: 'Other/Abroad', caption: 'Other/Abroad' },
      { name: 'Gauteng', caption: 'Gauteng' },
      { name: 'Western Cape', caption: 'Western Cape' },
      { name: 'Eastern Cape', caption: 'Eastern Cape' },
      { name: 'Free State', caption: 'Free State' },
      { name: 'KwaZulu-Natal', caption: 'KwaZulu-Natal' },
      { name: 'Limpopo', caption: 'Limpopo' },
      { name: 'Mpumalanga', caption: 'Mpumalanga' },
      { name: 'Northern Cape', caption: 'Northern Cape' },
      { name: 'North West', caption: 'North West' },
    ];
    return provinces;
  }

  getSourceOfFunds() {
    let fundsSource = [
      { text: 'Compensation', value: 'Compensation' },
      { text: 'Dividends/Salary', value: 'Dividends/Salary' },
      { text: 'Inheritance', value: 'Inheritance' },
      { text: 'Lottery/Gambling Winnings', value: 'Lottery/Gambling Winnings' },
      { text: 'Pension Fund', value: 'Pension Fund' },
      { text: 'Sale of Property', value: 'Sale of Property' },
      { text: 'Sale of Shares', value: 'Sale of Shares' },
      { text: 'Savings', value: 'Savings' },
      { text: 'Other', value: 'Other' },
    ];
    return fundsSource;
  }

  getValrSupportedBanks(){
    let supportedBanks = [
      {
        code: 'ABSA',
        displayName: 'ABSA Bank',
        defaultBranchCode: '632005',
      },
      {
        code: 'CAPITEC',
        displayName: 'Capitec Bank',
        defaultBranchCode: '470010',
      },
      {
        code: 'FNB',
        displayName: 'FNB/RMB',
        defaultBranchCode: '250655',
      },
      {
        code: 'NEDBANK',
        displayName: 'Nedbank',
        defaultBranchCode: '198765',
      },
      {
        code: 'STANDARD',
        displayName: 'Standard Bank',
        defaultBranchCode: '051001',
      },
      {
        code: 'AFRICAN',
        displayName: 'African Bank',
        defaultBranchCode: '430000',
      },
      {
        code: 'ALBARAKA',
        displayName: 'Albaraka Bank',
        defaultBranchCode: '800000',
      },
      {
        code: 'ACCESS_BANK',
        displayName: 'Access Bank',
        defaultBranchCode: '410506',
      },
      {
        code: 'BANK_ZERO',
        displayName: 'Bank Zero',
        defaultBranchCode: '888000',
      },
      {
        code: 'BIDVEST',
        displayName: 'Bidvest Bank',
        defaultBranchCode: '462005',
      },
      {
        code: 'BNP_PARIBAS',
        displayName: 'BNP Paribas',
        defaultBranchCode: '688000',
      },
      {
        code: 'CITIBANK',
        displayName: 'CitiBank',
        defaultBranchCode: '350005',
      },
      {
        code: 'DISCOVERY',
        displayName: 'Discovery Bank',
        defaultBranchCode: '679000',
      },
      {
        code: 'FINBOND_MUTUAL',
        displayName: 'FinBond Mutual Bank',
        defaultBranchCode: '589000',
      },
      {
        code: 'GRINDROD',
        displayName: 'Grindrod Bank',
        defaultBranchCode: '584000',
      },
      {
        code: 'HBZ',
        displayName: 'HBZ Bank',
        defaultBranchCode: '',
      },
      {
        code: 'HSBC',
        displayName: 'HSBC',
        defaultBranchCode: '587000',
      },
      {
        code: 'HABIB_OVERSEAS',
        displayName: 'Habib Overseas Bank',
        defaultBranchCode: '',
      },
      {
        code: 'INVESTEC',
        displayName: 'Investec Bank',
        defaultBranchCode: '580105',
      },
      {
        code: 'ITHALA',
        displayName: 'Ithala',
        defaultBranchCode: '',
      },
      {
        code: 'JPMORGAN_CHASE',
        displayName: 'JPMorgan Chase Bank',
        defaultBranchCode: '432000',
      },
      {
        code: 'LESOTHO_POST',
        displayName: 'Lesotho Post Bank',
        defaultBranchCode: '',
      },
      {
        code: 'OLYMPUS_MOBILE',
        displayName: 'Olympus Mobile',
        defaultBranchCode: '585001',
      },
      {
        code: 'PEOPLES_BANK',
        displayName: 'Peoples Bank Ltd Inc NBS',
        defaultBranchCode: '720026',
      },
      {
        code: 'POSTBANK',
        displayName: 'Postbank',
        defaultBranchCode: '460005',
      },
      {
        code: 'SA_RESERVE_BANK',
        displayName: 'S.A. Reserve Bank',
        defaultBranchCode: '',
      },
      {
        code: 'SASFIN',
        displayName: 'Sasfin Bank',
        defaultBranchCode: '683000',
      },
      {
        code: 'SOCIETE_GENERALE',
        displayName: 'Societe Generale',
        defaultBranchCode: '351005',
      },
      {
        code: 'STANDARD_CHARTERED',
        displayName: 'Standard Chartered Bank',
        defaultBranchCode: '730020',
      },
      {
        code: 'STATE_BANK_OF_INDIA',
        displayName: 'State Bank of India',
        defaultBranchCode: '801000',
      },
      {
        code: 'TYME_BANK',
        displayName: 'TymeBank',
        defaultBranchCode: '678910',
      },
      {
        code: 'UBank',
        displayName: 'UBank Limited',
        defaultBranchCode: '431010',
      },
      {
        code: 'UNIBANK',
        displayName: 'Unibank',
        defaultBranchCode: '790005',
      },
      {
        code: 'CAPITEC_BUSINESS',
        displayName: 'Capitec Business',
        defaultBranchCode: '450105',
      },
      {
        code: 'INTERNATIONAL',
        displayName: 'International',
        defaultBranchCode: '',
      },
    ];
    return supportedBanks;
  }

  getLoanDepositTypes() {
    let loanDepositTypes = [
      { text: 'ERC-20 Address', value: 'erc-20' },
      { text: 'Bank EFT', value: 'bank' },
      { text: 'Collateral', value: 'collateral' },
    ];
    return loanDepositTypes;
  }

  getSplitAmount() {
    let splitAmounts = [
      { text: '500 USDT', value: 500 },
      { text: '1000 USDT', value: 1000 },
      { text: '1500 USDT', value: 1500 },
      { text: '2000 USDT', value: 2000 },
      { text: '2500 USDT', value: 2500 },
      { text: '5000 USDT', value: 5000 },
    ];

    return splitAmounts;
  }

  getLoanTypes() {
    let loanTypes = [
      { text: 'All Loans', value: 'all' },
      { text: 'Income Fund Loans', value: 'income' },
      { text: 'Hedge Loan Loans', value: 'hedge' },
    ];

    return loanTypes;
  }

  getCoinTypes() {
    let coinTypes = [
      { text: 'All Coins', value: 'all' },
      { text: 'Bitcoin', value: 'bitcoin' },
      { text: 'Ethereum', value: 'ethereum' },
    ];
    return coinTypes;
  }

  getIncomeProductPayoutTypes() {
    let payoutTypes = [
      {
        text: 'Bank EFT',
        value: 'bank',
        description: 'Pay out your monthly return into your bank account.',
      },
      {
        text: 'Monthly Reinvest',
        value: 'deferred',
        description:
          'Reinvest your monthly return back into your income plan.',
      },
      {
        text: 'Wallet Transfer',
        value: 'wallet',
        description: 'Pay out your monthly return into your USDT wallet.',
      },
    ];

    return payoutTypes;
  }
}
