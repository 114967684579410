import { Component, TemplateRef, ViewChild, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoaderService } from '../services/loader.service';
import { RegisterService } from '../services/register.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-pre-sign-up',
  templateUrl: './pre-sign-up.component.html',
  styleUrl: './pre-sign-up.component.scss',
})
export class PreSignUpComponent implements OnInit {
  @ViewChild('updateUserModal') updateUserModal: TemplateRef<any>;
  @ViewChild('genericModal') genericModal: TemplateRef<any>;
  public userDetailsForm: UntypedFormGroup;
  public modalRef: BsModalRef;

  // Modal
  public isGenericError = false;
  public genericMessage = '';

  public country: any;

  public isSubmitted: boolean;
  public showSuccessMsg: boolean = false;
  public isIndividual: boolean;

  introducerId: any | null = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: BsModalService,
    private router: Router,
    private loaderService: LoaderService,
    private registerService: RegisterService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private dataService: DataService,
  ) {
    this.userDetailsForm = this.formBuilder.group({
      userType: ['', [Validators.required]],
      name: ['', [Validators.required]],
      surname: ['', [Validators.required]],
      country: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      advisorCode: [''],
    });
  }

  ngOnInit() {
    this.showSuccessMsg = false;
    this.country = this.dataService.getCountries();
    this.isIndividual = true;

    this.userDetailsForm
      .get('userType')
      ?.valueChanges.subscribe((value: string) => {
        this.isIndividual = value === 'individual';
      });
  }

  addNewUser() {
    this.isSubmitted = true;
    this.loaderService.updateLoader(true);
    this.isGenericError = false;
    if (this.userDetailsForm.invalid) {
      this.loaderService.updateLoader(false);
      return;
    }

    this.recaptchaV3Service.execute('preSignUp').subscribe(
      (token: string) => {
        let payload = {
          type: this.userDetailsForm.get('userType').value,
          name: this.userDetailsForm.get('name').value,
          surname: this.userDetailsForm.get('surname').value,
          country: this.userDetailsForm.get('country').value,
          email: this.userDetailsForm.get('email').value,
          advisorCode: this.userDetailsForm.get('advisorCode').value || '',
          acceptedAgreement: true,
          recaptcha: token,
        };

        this.registerService.preRegisterUser(payload).subscribe(
          (res) => {
            if (res.success === true) {
              this.loaderService.updateLoader(false);
              this.isGenericError = false;
              this.genericMessage = res.message;
              this.showSuccessMsg = true;
            } else {
              this.isGenericError = true;
              this.genericMessage = res.message;
            }
            this.loaderService.updateLoader(false);
          },
          (err) => {
            this.isGenericError = true;
            this.genericMessage =
              'Unable to submit application. Please try again!';
            this.loaderService.updateLoader(false);
          },
        );
      },
      (error) => {
        this.isGenericError = true;
        this.genericMessage = 'Unable to submit application. Please try again!';
        this.loaderService.updateLoader(false);
      },
    );
  }

  cancelPreSignUp() {
    this.loaderService.updateLoader(true);
    this.userDetailsForm.reset();
    this.router.navigate(['/za']);
    this.loaderService.updateLoader(false);
  }

  goToLogin() {
    this.loaderService.updateLoader(true);
    this.userDetailsForm.reset();
    this.router.navigate(['/login']);
    this.loaderService.updateLoader(false);
  }

  goToTerms() {
    this.loaderService.updateLoader(true);
    this.userDetailsForm.reset();
    this.router.navigate(['/terms-and-conditions']);
    this.loaderService.updateLoader(false);
  }

  goHome() {
    this.loaderService.updateLoader(true);
    this.userDetailsForm.reset();
    this.router.navigate(['/za']);
    this.loaderService.updateLoader(false);
  }

  get userFormControls() {
    return this.userDetailsForm.controls;
  }

  showGenericModal() {
    this.modalRef = this.modalService.show(this.genericModal);
  }

  hideModal() {
    this.modalRef.hide();

    if (this.isGenericError === false) {
      this.userDetailsForm.reset();
      this.router.navigate(['/home']);
    }
  }

  ngOnDestroy() {
    this.userDetailsForm.reset();
  }
}
