<h2 class="header">OUR TRUSTED PARTNERS</h2>
<div class="container">
  <div class="card-row row">
    <div class="col-md-4">
      <div class="card h-100 mb-4 border-0">
        <div class="card-body" data-animation="fade-down">
          <img
            src="../../assets/images/valr-logo.png"
            class="card-img-top ng-logo"
            alt="..."
          />
          <h2 class="card-title"></h2>
          <p class="card-text">
            Cryptocurrency exchange platform facilitating the buying, selling,
            and trading of digital assets. Provides a robust security measures
            for managing crypto transactions.
          </p>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card h-100 mb-4 border-0">
        <div class="card-body" data-animation="fade-down">
          <img
            src="../../assets/images/Fireblocks-logo1.png"
            class="fireblocks-logo card-img-top"
            alt="..."
          />
          <h2 class="card-title"></h2>
          <p class="card-text">
            Secure and scalable platform for managing digital assets, offering
            institutional-grade solutions for storing, transferring, and issuing
            cryptocurrencies.
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card h-100 mb-4 border-0">
        <div class="card-body" data-animation="fade-down">
          <img
            src="../../assets/images/Bitfinex.png"
            class="bitfinex-logo card-img-top"
            alt="..."
          />
          <h2 class="card-title"></h2>
          <p class="card-text">
            Bitfinex is a leading cryptocurrency exchange known for its advanced
            trading features, liquidity, and lending markets.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="card-row row">
    <div class="col-md-4">
      <div class="card h-100 mb-4 border-0">
        <div class="card-body" data-animation="fade-down">
          <img
            src="../../assets/images/complycube.png"
            class="card-img-top fireblocks-logo"
            alt="..."
          />
          <h2 class="card-title"></h2>
          <p class="card-text">
            ComplyCube is a comprehensive compliance platform designed to
            streamline identity verification (IDV), anti-money laundering (AML),
            and know your customer (KYC) processes.
          </p>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card h-100 mb-4 border-0">
        <div class="card-body" data-animation="fade-down">
          <img
            src="../../assets/images/masthead.png"
            class="uniswap-logo card-img-top"
            alt="..."
          />
          <h2 class="card-title"></h2>
          <p class="card-text">
            Masthead is a South African firm providing compliance, risk management, and practice management services to independent financial advisors and FSPs. Established in 2004, it helps advisors navigate regulations and grow their businesses.          </p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card h-100 mb-4 border-0">
        <div class="card-body" data-animation="fade-down">
          <img
            src="../../assets/images/1inch-logo.png"
            class="fireblocks-logo card-img-top"
            alt="..."
          />
          <h2 class="card-title"></h2>
          <p class="card-text">
            1inch, a leading decentralized exchange aggregator, provides a
            diverse range of DeFi services, including liquidity sourcing, token
            swaps, and yield farming. Known for its efficiency and
            user-friendliness.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
