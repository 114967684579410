<div>
  <div class="fill">
    <div class="login-form-holder">
      <div class="login-form">
        <!-- Login -->
        <div *ngIf="isShowLoginForm" class="slide-in-animation-top">
          <h3 class="login-header">Login</h3>
          <div class="gold-line"></div>
          <form
            [formGroup]="userForm"
            class="login-container"
            (ngSubmit)="submitLogin()"
          >
            <div
              class="form-group"
              [ngClass]="{
                'has-danger': isSubmitted && formControls.username.errors
              }"
            >
              <label class="form-control-label" for="username">Username</label>
              <input
                type="text"
                placeholder="Enter your username"
                formControlName="username"
                class="form-control"
                id="username"
                [ngClass]="{
                  'is-invalid': isSubmitted && formControls.username.errors
                }"
              />
              <div
                *ngIf="isSubmitted && formControls.username.errors"
                class="invalid-feedback"
              >
                <div *ngIf="formControls.username.errors.required">
                  Username is required!
                </div>
              </div>
            </div>

            <div
              class="form-group"
              [ngClass]="{
                'has-danger': isSubmitted && formControls.password.errors
              }"
            >
              <label class="form-control-label" for="password">Password</label>
              <input
                type="password"
                placeholder="Enter your password"
                formControlName="password"
                class="form-control"
                id="password"
                [ngClass]="{
                  'is-invalid': isSubmitted && formControls.password.errors
                }"
              />
              <div
                *ngIf="isSubmitted && formControls.password.errors"
                class="invalid-feedback"
              >
                <div *ngIf="formControls.password.errors.required">
                  Password is required!
                </div>
              </div>
            </div>
            <!-- <div class="gold-line"></div> -->
            <div class="clearfix">
              <p class="error-mgs">{{ errorMgs }}</p>
            </div>
            <div>
              <label for="login"></label>
              <input
                type="submit"
                value="Login"
                id="login"
                class="btn btn-login btn-login btn-lg"
              />
            </div>
            <!-- <div class="gold-line"></div> -->
            <div class="forgot-password">
              <label
                >Forgot your password?
                <a (click)="showPasswordReset()">Click here</a></label
              >
            </div>
            <!-- <div class="forgot-password">
              <label
                >Don't have an account?
                <a (click)="goToSignUp()">Sign Up Here</a></label
              >
            </div> -->
          </form>
        </div>

        <!-- Show the Recovery Form -->
        <div *ngIf="isShowRecoveryForm">
          <h3 class="login-header">Account Recovery</h3>
          <div class="gold-line"></div>
          <form
            [formGroup]="accountRecoveryForm"
            class="login-container"
            (ngSubmit)="submitRecovery()"
          >
            <div
              class="form-group"
              [ngClass]="{
                'has-danger': isSubmitted && recoveryformControls.code.errors
              }"
            >
              <label class="form-control-label" for="recovery"
                >Recovery Code</label
              >
              <input
                type="password"
                placeholder="Enter your code"
                formControlName="code"
                class="form-control"
                id="recovery"
                [ngClass]="{
                  'is-invalid': isSubmitted && recoveryformControls.code.errors
                }"
              />
              <div
                *ngIf="isSubmitted && recoveryformControls.code.errors"
                class="invalid-feedback"
              >
                <div *ngIf="recoveryformControls.code.errors.required">
                  Recovery code is required!
                </div>
              </div>
            </div>
            <div class="hint">
              Please note that you can only attempt recovery 3 times.
            </div>
            <!-- <div class="gold-line"></div> -->
            <div class="clearfix">
              <p class="error-mgs">{{ errorMgs }}</p>
            </div>
            <div>
              <input type="submit" value="Login" class="btn btn-login btn-lg" />
            </div>
            <!-- <div class="gold-line"></div> -->
            <div class="forgot-password">
              <label
                >Back to OTP? <a (click)="showLoginOtp()">Click here</a></label
              >
            </div>
          </form>
        </div>

        <!-- Show the Login OTP Form -->
        <div *ngIf="isShowLoginOTPForm">
          <h3 class="login-header">Login</h3>
          <div class="gold-line"></div>
          <form
            [formGroup]="userForm"
            class="login-container"
            (ngSubmit)="submitLoginOTP()"
          >
            <div class="form-group">
              <label *ngIf="loginType === 0" class="form-control-label"
                >One-Time Password</label
              >
              <label *ngIf="loginType === 1" class="form-control-label"
                >Two-Factor Authentication</label
              >
              <ng-otp-input
                *ngIf="showLoginOTP"
                [config]="otpConfig"
                (onInputChange)="onOtpChange($event, 'login')"
              >
              </ng-otp-input>
            </div>
            <div *ngIf="loginType === 1" class="hint">
              Please be aware that your 2FA expires after a certain time.
            </div>
            <div *ngIf="loginType === 0">
              time left:
              <countdown
                #cd
                [config]="{ leftTime: 180, format: 'mm:ss' }"
                (event)="expireOTP($event)"
              >
              </countdown>
            </div>
            <!-- <div class="gold-line"></div> -->
            <div class="clearfix">
              <p class="error-mgs">{{ errorMgs }}</p>
            </div>
            <div>
              <input
                [disabled]="otp?.length !== 6"
                type="submit"
                value="Login"
                class="btn btn-login btn-lg"
              />
            </div>
            <!-- <div class="gold-line"></div> -->
            <div *ngIf="loginType === 0" class="forgot-password">
              <label
                >Did not receive OTP?
                <a (click)="reSubmitLogin()">Click here to resend</a></label
              >
            </div>
            <div class="forgot-password mt-1">
              <label
                >Lost your phone/email?
                <a (click)="showRecoveryCode()">Click here</a></label
              >
            </div>
          </form>
        </div>
        <!-- Password Reset -->
        <div *ngIf="isShowForgotPasswordForm">
          <h3 class="login-header">Password Reset</h3>
          <div class="gold-line"></div>
          <form
            [formGroup]="passwordResetForm"
            class="login-container"
            (ngSubmit)="submitForgotPassword()"
          >
            <div
              class="form-group"
              [ngClass]="{
                'has-danger':
                  isSubmitted && passwordresetformControls.username.errors
              }"
            >
              <label class="form-control-label">Username</label>
              <input
                type="text"
                placeholder="Enter your username"
                formControlName="username"
                class="form-control"
                [ngClass]="{
                  'is-invalid':
                    isSubmitted && passwordresetformControls.username.errors
                }"
              />
              <div
                *ngIf="isSubmitted && passwordresetformControls.username.errors"
                class="invalid-feedback"
              >
                <div *ngIf="passwordresetformControls.username.errors.required">
                  Username is required!
                </div>
              </div>
            </div>
            <div
              class="form-group"
              [ngClass]="{
                'has-danger':
                  isSubmitted && passwordresetformControls.email.errors
              }"
            >
              <label class="form-control-label">Email</label>
              <input
                type="text"
                placeholder="Enter your email"
                formControlName="email"
                class="form-control"
                [ngClass]="{
                  'is-invalid':
                    isSubmitted && passwordresetformControls.email.errors
                }"
              />
              <div
                *ngIf="isSubmitted && passwordresetformControls.email.errors"
                class="invalid-feedback"
              >
                <div *ngIf="passwordresetformControls.email.errors.required">
                  Email is required!
                </div>
                <div
                  *ngIf="
                    passwordresetformControls.email.invalid &&
                    passwordresetformControls.email.value?.length > 0
                  "
                >
                  Email is invalid!
                </div>
              </div>
            </div>
            <!-- <div class="gold-line"></div> -->
            <div class="clearfix">
              <p class="error-mgs">{{ errorMgs }}</p>
            </div>
            <div>
              <input
                type="submit"
                value="Submit"
                class="btn btn-login btn-lg"
              />
            </div>
            <!-- <div class="gold-line"></div> -->
            <div class="forgot-password">
              <label
                >Want to login?
                <a (click)="showSubmitLogin()">Click here</a></label
              >
            </div>
          </form>
        </div>
        <div *ngIf="isShowForgotPasswordOTPForm">
          <h3 class="login-header">Password Reset</h3>
          <div class="gold-line"></div>
          <form
            [formGroup]="passwordResetForm"
            class="login-container"
            (ngSubmit)="submitForgotPasswordOTP()"
          >
            <div class="form-group">
              <label *ngIf="loginType === 0" class="form-control-label"
                >One-Time Password</label
              >
              <label *ngIf="loginType === 1" class="form-control-label"
                >Two-Factor Authentication</label
              >
              <ng-otp-input
                *ngIf="showPasswordOTP"
                [config]="otpConfig"
                (onInputChange)="onOtpChange($event, 'password-reset')"
              >
              </ng-otp-input>
            </div>
            <div *ngIf="loginType === 1" class="hint">
              Please be aware that your OTP expires after a certain time.
            </div>
            <div *ngIf="loginType === 0">
              time left:
              <countdown
                #cd
                [config]="{ leftTime: 60, format: 'mm:ss' }"
                (event)="expireOTP($event)"
              >
              </countdown>
            </div>
            <!-- <div class="gold-line"></div> -->
            <div class="clearfix">
              <p class="error-mgs">{{ errorMgs }}</p>
            </div>
            <div>
              <input
                [disabled]="otp?.length !== 6"
                type="submit"
                value="Submit"
                class="btn btn-login btn-lg"
              />
            </div>
            <!-- <div class="gold-line"></div> -->
            <div *ngIf="loginType === 0" class="forgot-password">
              <label
                >Did not receive OTP?
                <a (click)="reSubmitForgotPassword()"
                  >Click here to resend</a
                ></label
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Error Modal Popup -->
<ng-template #errUserModal>
  <div class="modal-header">
    <h4 class="modal-title">Error</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="hideModal()"
    ></button>
  </div>

  <div class="modal-body">
    {{ modalMessage }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="hideModal()">
      OK
    </button>
  </div>
</ng-template>

<!-- Success Modal Popup -->
<ng-template #sucUserModal>
  <div class="modal-header">
    <h4 class="modal-title">Success</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="hideModal()"
    ></button>
  </div>

  <div class="modal-body">
    {{ modalMessage }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="hideModal()">
      OK
    </button>
  </div>
</ng-template>
